export const ADVERTISING = {
    GET_ADVERTISING: 'GET_ADVERTISING',
    GET_ADVERTISING_SUCCESS: 'GET_ADVERTISING_SUCCESS',
    GET_ADVERTISING_FAIL: 'GET_ADVERTISING_FAIL',

    GET_ADVERTISER: 'GET_ADVERTISER',
    GET_ADVERTISER_SUCCESS: 'GET_ADVERTISER_SUCCESS',
    GET_ADVERTISER_FAIL: 'GET_ADVERTISER_FAIL',

    GET_ADVERTISER_CAMPAIGNS: 'GET_ADVERTISER_CAMPAIGNS',
    GET_ADVERTISER_CAMPAIGNS_SUCCESS: 'GET_ADVERTISER_CAMPAIGNS_SUCCESS',
    GET_ADVERTISER_CAMPAIGNS_FAIL: 'GET_ADVERTISER_CAMPAIGNS_FAIL',

    GET_ADVERTISER_STATISTIC: 'GET_ADVERTISER_STATISTIC',
    GET_ADVERTISER_STATISTIC_SUCCESS: 'GET_ADVERTISER_STATISTIC_SUCCESS',
    GET_ADVERTISER_STATISTIC_FAIL: 'GET_ADVERTISER_STATISTIC_FAIL',

    EDIT_ADVERTISER: 'EDIT_ADVERTISER',
    EDIT_CAMPAIGN: 'EDIT_CAMPAIGN',

    PUT_ADVERTISER: 'PUT_ADVERTISER',
    PUT_ADVERTISER_SUCCESS: 'PUT_ADVERTISER_SUCCESS',
    PUT_ADVERTISER_FAIL: 'PUT_ADVERTISER_FAIL',
    
    POST_ADVERTISER: 'POST_ADVERTISER',
    POST_ADVERTISER_SUCCESS: 'POST_ADVERTISER_SUCCESS',
    POST_ADVERTISER_FAIL: 'POST_ADVERTISER_FAIL',

    GET_CAMPAIGN: 'GET_CAMPAIGN',
    GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAIL: 'GET_CAMPAIGN_FAIL',

    PUT_CAMPAIGN: 'PUT_CAMPAIGN',
    PUT_CAMPAIGN_SUCCESS: 'PUT_CAMPAIGN_SUCCESS',
    PUT_CAMPAIGN_FAIL: 'PUT_CAMPAIGN_FAIL',

    DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
    DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
    DELETE_CAMPAIGN_FAIL: 'DELETE_CAMPAIGN_FAIL',

    ARCHIVE_CAMPAIGN: 'ARCHIVE_CAMPAIGN',
    ARCHIVE_CAMPAIGN_SUCCESS: 'ARCHIVE_CAMPAIGN_SUCCESS',
    ARCHIVE_CAMPAIGN_FAIL: 'ARCHIVE_CAMPAIGN_FAIL',
    
    POST_CAMPAIGN: 'POST_CAMPAIGN',
    POST_CAMPAIGN_SUCCESS: 'POST_CAMPAIGN_SUCCESS',
    POST_CAMPAIGN_FAIL: 'POST_CAMPAIGN_FAIL',

    GET_CAMPAIGN_STATISTIC: 'GET_CAMPAIGN_STATISTIC',
    GET_CAMPAIGN_STATISTIC_SUCCESS: 'GET_CAMPAIGN_STATISTIC_SUCCESS',
    GET_CAMPAIGN_STATISTIC_FAIL: 'GET_CAMPAIGN_STATISTIC_FAIL',

    GET_CAMPAIGN_TRACKS: 'GET_CAMPAIGN_TRACKS',
    GET_CAMPAIGN_TRACKS_SUCCESS: 'GET_CAMPAIGN_TRACKS_SUCCESS',
    GET_CAMPAIGN_TRACKS_FAIL: 'GET_CAMPAIGN_TRACKS_FAIL',

    UPLOAD_CAMPAIGN_TRACK: 'UPLOAD_CAMPAIGN_TRACK',
    UPLOAD_CAMPAIGN_TRACK_SUCCESS: 'UPLOAD_CAMPAIGN_TRACK_SUCCESS',
    UPLOAD_CAMPAIGN_TRACK_FAIL: 'UPLOAD_CAMPAIGN_TRACK_FAIL',
    
    UPDATE_CAMPAIGN_TRACK_ON_DB: 'UPDATE_CAMPAIGN_TRACK_ON_DB',
    UPDATE_CAMPAIGN_TRACK_ON_DB_SUCCESS: 'UPDATE_CAMPAIGN_TRACK_ON_DB_SUCCESS',
    UPDATE_CAMPAIGN_TRACK_ON_DB_FAIL: 'UPDATE_CAMPAIGN_TRACK_ON_DB_FAIL',

    UPDATE_CAMPAIGN_TRACK: 'UPDATE_CAMPAIGN_TRACK',

    DELETE_CAMPAIGN_TRACK: 'DELETE_CAMPAIGN_TRACK',
    DELETE_CAMPAIGN_TRACK_SUCCESS: 'DELETE_CAMPAIGN_TRACK_SUCCESS',
    DELETE_CAMPAIGN_TRACK_FAIL: 'DELETE_CAMPAIGN_TRACK_FAIL',

    DELETE_ADVERTISING: 'DELETE_ADVERTISING',
    DELETE_ADVERTISING_SUCCESS: 'DELETE_ADVERTISING_SUCCESS',
    DELETE_ADVERTISING_FAIL: 'DELETE_ADVERTISING_FAIL',

    ADVERTISING_SETTINGS: 'ADVERTISING_SETTINGS',
    ADVERTISING_SETTINGS_SUCCESS: 'ADVERTISING_SETTINGS_SUCCESS',
    ADVERTISING_SETTINGS_FAIL: 'ADVERTISING_SETTINGS_FAIL',

    ADVERTISING_SETTINGS_PUT: 'ADVERTISING_SETTINGS_PUT',
    ADVERTISING_SETTINGS_PUT_SUCCESS: 'ADVERTISING_SETTINGS_PUT_SUCCESS',
    ADVERTISING_SETTINGS_PUT_FAIL: 'ADVERTISING_SETTINGS_PUT_FAIL',
};
