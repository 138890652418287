import { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './CampaignAudio.module.scss';
import additionalStyles from '../../Audio/Audio.module.scss';
import inputStyles from '../Advertising.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMUI, DialogMUI, InputMUI, LoaderMUI, TooltipMUI } from '../../../shared'
import { ReactComponent as AddAudio } from '../../../assets/icons/add_campaign_audio.svg';
import { ReactComponent as Pending } from '../../../assets/icons/campaign_audio_pending_status.svg';
import { ReactComponent as SendFail } from '../../../assets/icons/send-failed.svg'
import { ReactComponent as Melody } from '../../../assets/icons/melody.svg';
import { ReactComponent as AudioListen } from '../../../assets/icons/campaign_audio_statistic_listen.svg';
import { ReactComponent as AudioTime } from '../../../assets/icons/campaign_audio_statistic_time.svg';
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_ico.svg'
import { ReactComponent as BackArrow } from '../../../assets/icons/header_back_arrow.svg';
import { classList, formatAlbumTime, formatFileSize, formatNumber } from '../../../helpers/functions';
import { ClickAwayListener } from '@material-ui/core';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DeleteModal } from '../Campaigns';
import { campaignAction, campaignArchive, getCampaignTracks, uploadCampaignTrack, updateCampaignTrack, deleteCampaignAudio, updateCampaignTrackOnDB } from '../storage/advertisingActions';
import { ageOptions } from '../../Audio/Audio';
dayjs.extend(utc)

const CampaignAudio = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { campaignTracks } = useSelector(({ advertising }) => advertising)
    const { advertiserId, campaignId } = useParams();
    const [inputFileLoading, setFileLoading] = useState(false)
    const [audioFiles, setAudioFiles] = useState([])
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)
    const [descriptionOpenStatus, setDescriptionOpenStatus] = useState([])
    const [initialDescHeights, setInitialDescHeights] = useState([])

    useEffect(() => {
        dispatch(getCampaignTracks(campaignId))
    }, [])

    useEffect(() => {
        if (campaignTracks.results.length > 0 && campaignTracks.results.some(el => el.hasOwnProperty('description') && !!el.description)) {
            let descArr = [...document.getElementsByClassName(styles.description)],
                tempDescStatus = [];
            descArr.forEach(el => {
                let inner = el.querySelector(`.${styles.descriptionInner}`),
                    innerWrap = el.querySelector(`.${styles.descriptionInnerWrapper}`);
                if (innerWrap.clientHeight > inner.clientHeight) {
                    tempDescStatus.push(+el.id)
                }
            })
            setInitialDescHeights([...tempDescStatus])
        }
    }, [campaignTracks.results, dialogStatus.status && dialogStatus.type === 'edit'])

    const toggleDescOpenStatus = id => {
        let tempSet = new Set(descriptionOpenStatus);

        tempSet[tempSet.has(id) ? 'delete' : 'add'](id)

        setDescriptionOpenStatus([...tempSet])
    }

    const contextMenuActions = (e, action, id) => {
        if (!!e) e.preventDefault();
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: campaignTracks.results.find(el => el.id === contextMenu) })
        contextMenuActions(undefined, 'close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(deleteCampaignAudio(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
            }
        })
    }

    const toggleClassOnHover = ({ target }) => {
        document.querySelector(`.${inputStyles.inputStylesBlock}`).classList.toggle(inputStyles.hover)
    }

    const dropToInput = ({ target }) => {
        setFileLoading(!target.files.length)
        toggleClassOnHover({ target });
    }

    const inputFileChange = ({ target }) => {
        if (target.files.length > 0) {
            let tempArr = [...Array(target.files.length).keys()]

            setTimeout(() => {
                setFileLoading(!target.files.length)
            }, 500)
            tempArr.forEach(async el => {
                let audioItem = target.files[el],
                    formData = new FormData(),
                    fileDuration = undefined,
                    audioTag = new Audio;

                audioTag.src = URL.createObjectURL(audioItem)
                audioTag.addEventListener('loadedmetadata', () => {
                    fileDuration = audioTag.duration;
                })

                formData.append('file', audioItem)
                await dispatch(uploadCampaignTrack(campaignId, formData)).then(({ type, payload: { data: { id } } }) => {
                    if (type.includes('_SUCCESS')) {
                        dispatch(updateCampaignTrack(id, { length: fileDuration }))
                    }
                })
            });
        }
    }

    return <label
        htmlFor={'hiddenFileInput'}
        id={'hiddenFileInputLabel'}
        className={classList(
            inputStyles.hiddenFileInputLabel,
            styles.campaignAudio,
            { [styles.loaded]: campaignTracks.results.length > 0 }
        )}
        onDragEnter={e => toggleClassOnHover(e)}
        onDragLeave={e => toggleClassOnHover(e)}
    >
        <div className={classList(
            inputStyles.inputStylesBlock,
            styles.inputStylesBlock,
            { [inputStyles.loading]: inputFileLoading },
            { [inputStyles.loaded]: campaignTracks.results.length > 0 }
        )}>
            {inputFileLoading && <LoaderMUI />}
            {campaignTracks.results.length < 1
                ? <div className={styles.noAudio}>
                    <Melody />
                    <div>
                        <p>No advertising audio yet</p>
                        <span><span>Upload audio</span> to this campaign<br />and it will show up here</span>
                    </div>
                </div>
                : <div className={styles.audio}>
                    <div className={styles.heading}>
                        <p className={styles.count}>Advertising audio<span>{campaignTracks.count}</span></p>
                        <ButtonMUI
                            variant='text'
                            className={styles.addAudio}
                            onClick={e => e.target.closest(`#hiddenFileInputLabel`).click()}
                        >
                            <AddAudio /> Add audio
                        </ButtonMUI>
                    </div>
                    <div className={styles.audioList}>
                        {campaignTracks.results.map((el, key) => <div
                            onClick={e => e.preventDefault()}
                            key={key}
                            className={styles.audioListItem}
                        >
                            <div className={styles.info}>
                                <p>
                                    {el.name}
                                    {el.status !== 'complete' &&
                                        <TooltipMUI arrow className={styles.tooltip} title={el.status === 'submitted' ? 'Audio processing in progress' : 'Error processing audio'}>
                                            {el.status === 'submitted'
                                                ? <Pending />
                                                : <SendFail />
                                            }
                                        </TooltipMUI>
                                    }
                                </p>
                                <span>{dayjs(el.length).format().slice(14, 19)}{', '}{formatFileSize(el.file_size)}</span>
                            </div>
                            <div className={styles.statistic}>
                                <div>
                                    <AudioListen />
                                    {el.hasOwnProperty('listen_count') ? formatNumber(el.listen_count, 0) : 0}
                                </div>
                                <div>
                                    <AudioTime />
                                    {el.hasOwnProperty('listen_time') ? formatAlbumTime(el.listen_time, true) : 0}
                                </div>
                                <div className={classList(inputStyles.moreActionsWrapper, styles.moreActionsWrapper)}>
                                    <ClickAwayListener onClickAway={e => !!contextMenu && contextMenuActions(e, 'close', el.id)}>
                                        <div className={classList(inputStyles.moreActions, styles.moreActions)}>
                                            <button
                                                className={classList(inputStyles.moreActions__btn, styles.moreActions__btn, { [inputStyles.active]: contextMenu === el.id })}
                                                onClick={e => contextMenuActions(e, 'open', el.id)}
                                            >
                                                <span />
                                                <span />
                                                <span />
                                            </button>
                                            {contextMenu === el.id && <div className={classList(inputStyles.moreActions__container, styles.moreActions__container)} onClick={e => e.preventDefault()}>
                                                <button
                                                    className={classList(inputStyles.actionButtons)}
                                                    onClick={() => openDialog('edit')}
                                                >
                                                    <EditIcon />Edit
                                                </button>
                                                <button
                                                    className={classList(inputStyles.actionButtons, inputStyles.deleteAction)}
                                                    onClick={() => openDialog('delete')}
                                                >
                                                    <DeleteAction />Delete
                                                </button>
                                            </div>}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            {el.hasOwnProperty('description') && !!el.description && (
                                <div
                                    id={el.id}
                                    className={classList(
                                        styles.description,
                                        { [styles.opened]: descriptionOpenStatus.includes(el.id) },
                                        { [styles.openable]: initialDescHeights.includes(el.id) }
                                    )}>
                                    <div className={styles.descriptionInner}>
                                        <div className={styles.descriptionInnerWrapper}>
                                            {el.description}
                                        </div>
                                    </div>
                                    <ButtonMUI
                                        variant='text'
                                        className={styles.descriptionButton}
                                        onClick={() => toggleDescOpenStatus(el.id)}
                                    >
                                        Show {descriptionOpenStatus.includes(el.id) ? 'less' : 'more'} <BackArrow />
                                    </ButtonMUI>
                                </div>
                            )}
                        </div>)}
                    </div>
                </div>
            }
        </div>
        <input
            //key={new Date().getTime()}
            type="file"
            id={'hiddenFileInput'}
            //accept=".jpg, .doc, .png, .pdf"
            onDrop={e => dropToInput(e)}
            onChange={e => inputFileChange(e)}
            multiple
        />
        <DeleteModal
            dialogStatus={{ ...dialogStatus, status: dialogStatus.status && dialogStatus.type === 'delete' }}
            setDialogStatus={setDialogStatus}
            btnLoad={btnLoad}
            submitDialog={submitDialog}
            type={'audio'}
        />
        <EditModal
            dialogStatus={{ ...dialogStatus, status: dialogStatus.status && dialogStatus.type === 'edit' }}
            setDialogStatus={setDialogStatus}
        />
    </label>
}

const EditModal = ({ dialogStatus, setDialogStatus }) => {
    let target = useMemo(() => ({ ...dialogStatus.target }), [dialogStatus.target]);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [btnLoad, setBtnLoad] = useState(false)

    const updateTarget = (key, value) => target[key] = value

    const submit = () => {
        setBtnLoad(true)
        dispatch(updateCampaignTrackOnDB(target)).then(res => {
            setBtnLoad(false)
            if (res.type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
            } else {
                setErrors(prev => ({ ...prev, ...res.error.response.data }))
            }
        })
    }

    return <DialogMUI
        open={dialogStatus.status}
        onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
        maxWidth={'xs'}
    >
        <>
            <h2 className={additionalStyles.dialogTitle}>Audio details</h2>
            <div className={classList(additionalStyles.dialogWrapper, styles.editModalWrap)}>
                <div className={classList(additionalStyles.desc, styles.desc)}>
                    <div className={classList(additionalStyles.inputLabel, styles.inputLabel)}>
                        <p className={additionalStyles.inputLabel__title}>Name *</p>
                        <div className={classList(additionalStyles.inputLabel__wrapper, styles.inputLabel__wrapper)}>
                            <InputMUI
                                type={'text'}
                                placeholder={'Enter the name'}
                                value={target?.name}
                                onChange={({ target: { value } }) => {
                                    updateTarget('name', value)
                                    setErrors(prev => ({ ...prev, name: '' }))
                                }}
                                minRows={1}
                                error={errors.name && errors.name.join('; ')}
                            />
                        </div>
                    </div>
                    <div className={classList(additionalStyles.inputLabel, styles.inputLabel)}>
                        <p className={additionalStyles.inputLabel__title}>Description</p>
                        <div className={classList(additionalStyles.inputLabel__wrapper, styles.inputLabel__wrapper)}>
                            <InputMUI
                                type={'text'}
                                placeholder={'A short description for the audio'}
                                value={target?.description}
                                onChange={({ target: { value } }) => {
                                    updateTarget('description', value)
                                    setErrors(prev => ({ ...prev, description: '' }))
                                }}
                                multiline
                                minRows={5}
                                error={errors.description && errors.description.join('; ')}
                            />
                        </div>
                    </div>
                </div>
                <div className={additionalStyles.separator} />
                <div className={additionalStyles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={additionalStyles.dialogButtons}
                        onClick={submit}
                        loading={btnLoad}
                        disabled={btnLoad || !dialogStatus.status}
                    >
                        Save
                    </ButtonMUI>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                        onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
                    >
                        Cancel
                    </ButtonMUI>
                </div>
            </div>
        </>
    </DialogMUI>
}

export default CampaignAudio