export const PAGES_CONTENT = {
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
    GET_COUNTRIES_FAIL: 'GET_COUNTRIES_FAIL',

    GET_COUNTRIES_INNER: 'GET_COUNTRIES_INNER',
    GET_COUNTRIES_INNER_SUCCESS: 'GET_COUNTRIES_INNER_SUCCESS',
    GET_COUNTRIES_INNER_FAIL: 'GET_COUNTRIES_INNER_FAIL',

    SET_COUNTRIES_INNER: 'SET_COUNTRIES_INNER',
    SET_COUNTRIES_INNER_SUCCESS: 'SET_COUNTRIES_INNER_SUCCESS',
    SET_COUNTRIES_INNER_FAIL: 'SET_COUNTRIES_INNER_FAIL',

    GET_GENRES: 'GET_GENRES',
    GET_GENRES_SUCCESS: 'GET_GENRES_SUCCESS',
    GET_GENRES_FAIL: 'GET_GENRES_FAIL',

    GET_GENRES_INNER: 'GET_GENRES_INNER',
    GET_GENRES_INNER_SUCCESS: 'GET_GENRES_INNER_SUCCESS',
    GET_GENRES_INNER_FAIL: 'GET_GENRES_INNER_FAIL',

    COPY_INNER_CONTENT: 'COPY_INNER_CONTENT',
    COPY_INNER_CONTENT_SUCCESS: 'COPY_INNER_CONTENT_SUCCESS',
    COPY_INNER_CONTENT_FAIL: 'COPY_INNER_CONTENT_FAIL',

    SET_GENRES_INNER: 'SET_GENRES_INNER',
    SET_GENRES_INNER_SUCCESS: 'SET_GENRES_INNER_SUCCESS',
    SET_GENRES_INNER_FAIL: 'SET_GENRES_INNER_FAIL',

    GET_AVAILABLE_TRACKS: 'GET_AVAILABLE_TRACKS',
    GET_AVAILABLE_TRACKS_SUCCESS: 'GET_AVAILABLE_TRACKS_SUCCESS',
    GET_AVAILABLE_TRACKS_FAIL: 'GET_AVAILABLE_TRACKS_FAIL',

    GET_AVAILABLE_PACKS: 'GET_AVAILABLE_PACKS',
    GET_AVAILABLE_PACKS_SUCCESS: 'GET_AVAILABLE_PACKS_SUCCESS',
    GET_AVAILABLE_PACKS_FAIL: 'GET_AVAILABLE_PACKS_FAIL',

    SET_COUNTRY_AUTOMATIC: 'SET_COUNTRY_AUTOMATIC',
    SET_COUNTRY_AUTOMATIC_SUCCESS: 'SET_COUNTRY_AUTOMATIC_SUCCESS',
    SET_COUNTRY_AUTOMATIC_FAIL: 'SET_COUNTRY_AUTOMATIC_FAIL',

    SET_GENRES_AUTOMATIC: 'SET_GENRES_AUTOMATIC',
    SET_GENRES_AUTOMATIC_SUCCESS: 'SET_GENRES_AUTOMATIC_SUCCESS',
    SET_GENRES_AUTOMATIC_FAIL: 'SET_GENRES_AUTOMATIC_FAIL',

    CREATE_GENRE: 'CREATE_GENRE',
    CREATE_GENRE_SUCCESS: 'CREATE_GENRE_SUCCESS',
    CREATE_GENRE_FAIL: 'CREATE_GENRE_FAIL',

    REORDER_TRACKS: 'REORDER_TRACKS',
    SET_TRACKS: 'SET_TRACKS',
};
