import styles from './Feed.module.scss';
import audioStyles from '../../Audio/Audio.module.scss'
import { useEffect, useState } from 'react';
import { classList, formatAlbumTime } from '../../../helpers/functions';
import { ReactComponent as RepostIcon } from '../../../assets/icons/repost_icon.svg'
import { ReactComponent as BackArrow } from '../../../assets/icons/header_back_arrow.svg';
import { ReactComponent as Location } from '../../../assets/icons/location_icon.svg';
import { ReactComponent as CommentsIcon } from '../../../assets/icons/post_comments.svg';
import { ReactComponent as RepostsIcon } from '../../../assets/icons/post_reposts.svg';
import { ReactComponent as LikesIcon } from '../../../assets/icons/post_likes.svg';
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import nonAvatar from '../../../assets/icons/profile.svg'
import noAudio from '../../../assets/icons/no_audio.jpg'

import { ButtonMUI } from '../../../shared';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { setPostModal, deletePostModal } from '../storage/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
dayjs.extend(utc)

const RenderPost = ({ el, idx = 0, isInner = false, isBordered = false }) => {
    const dispatch = useDispatch();
    const { postModalID, postInnerLoading } = useSelector(({ users }) => users)
    const [textOpenStatus, setTextOpenStatus] = useState([])
    const [initialTextHeights, setInitialTextHeights] = useState([])
    const [contextMenu, setContextMenu] = useState(false)

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    useEffect(() => {
        if (el.hasOwnProperty('text') || (el.hasOwnProperty('post') && el.post.hasOwnProperty('text'))) {
            let textArr = [...document.getElementsByClassName(styles.feedText)],
                tempTextStatus = [];
            textArr.forEach(el => {
                let inner = el.children[0],
                    innerWrap = inner.children[0];
                if (innerWrap.clientHeight > inner.clientHeight) {
                    tempTextStatus.push(+el.id)
                }
            })
            setInitialTextHeights([...tempTextStatus])
        }
    }, [el])

    const toggleTextOpenStatus = id => {
        let tempSet = new Set(textOpenStatus);

        tempSet[tempSet.has(id) ? 'delete' : 'add'](id)

        setTextOpenStatus([...tempSet])
    }

    const openInnerModal = (imageIndex = 0) => {
        dispatch(setPostModal(el.id, imageIndex))
    }

    const renderPlaylist = (item) => <div className={styles.feedTracks}>
        <div className={classList(audioStyles.nameWrapper, audioStyles.big)}>
            <img src={item.image || noAudio} alt={item.name} />
            <div className={audioStyles.info}>
                <p>{item.name}</p>
                <div className={audioStyles.albumInfo}>
                    {item.version === 1 && <div className={audioStyles.explicit}>e</div>}
                    <span>{item.artist.name}</span>
                    {' • '}
                    <span>{item.tracks_count} track{item.tracks_count > 1 && 's'},</span>
                    <span>{formatAlbumTime(item.length)}</span>
                </div>
            </div>
        </div>
    </div>

    const renderText = () => !!el.text && (
        <div
            id={el.id}
            className={classList(
                styles.feedText,
                { [styles.opened]: textOpenStatus.includes(el.id) },
                { [styles.openable]: initialTextHeights.includes(el.id) }
            )}
        >
            <div className={styles.feedTextInner}>
                <div className={styles.feedTextInnerWrapper}>
                    {el.text}
                </div>
            </div>
            <ButtonMUI
                variant='text'
                className={styles.feedTextButton}
                onClick={() => toggleTextOpenStatus(el.id)}
            >
                Show {textOpenStatus.includes(el.id) ? 'less' : 'more'} <BackArrow />
            </ButtonMUI>
        </div>
    )

    const renderUser = (isRepost = false) => <div className={styles.feedUser}>
        {!isRepost && <img src={el?.author?.avatar || nonAvatar} alt={el?.author?.username} />}
        <div>
            <p><b>{el?.author?.full_name && el?.author?.full_name.replaceAll(' ', '') ? el?.author?.full_name : el?.author?.username ? el?.author?.username : '-'}</b>{!isRepost && <span>{isRepost && 're'}posted a post</span>}</p>
            {!isRepost && <span>{dayjs(el?.publication_date).format('DD MMM YY HH:mm')}</span>}
        </div>
    </div>

    return <div key={idx} className={classList(styles.feedItem, { [styles.bordered]: isBordered })}>
        {!!el.post ? <>
            {/* idx.toString().includes('reposted')
                ? renderUser(true)
                : */ !!el.text
                    ? renderUser(/* true */idx.toString().includes('reposted'))
                    : <div className={styles.feedReposted}><RepostIcon /><p><b>{el.author.full_name}</b><span>reposted a post</span></p></div>
            }
            {renderText()}
            <RenderPost {...{ el: el.post, idx: `${idx}_reposted`, isBordered: !!el.text }} />
        </>
            : <>
                {renderUser()}
                {renderText()}
                {!isInner && !!el.images && el.images.length > 0 && (
                    <div className={classList(
                        styles.feedImages,
                        styles[`feedImages${el.images && el.images.length}`],
                        { [styles[`feedImagesMoreTwo`]]: el.images && el.images.length > 2 }
                    )}>
                        {el.images.map(({ image, id }, imageIndex) => (
                            <img
                                src={image}
                                alt={`image#${id}`}
                                key={imageIndex}
                                className={classList({ [styles.feedImagesTemplate]: el.images.length >= 3 })}
                                onClick={() => openInnerModal(imageIndex)}
                            />
                        ))}
                    </div>
                )}
                {!!el.tracks && el.tracks.length > 0 && (
                    <div className={styles.feedTracks}>
                        {el.tracks.map((track, trackIDX) => (
                            <div className={classList(audioStyles.nameWrapper, audioStyles.big)} key={trackIDX}>
                                <img src={track.image || noAudio} alt={track.name} />
                                <div className={audioStyles.info}>
                                    <p>{track.name}</p>
                                    <div className={audioStyles.albumInfo}>
                                        {track.version === 1 && <div className={audioStyles.explicit}>e</div>}
                                        <span>{track.artists.map(el => el.name).join(', ')}</span>
                                        {' • '}
                                        <span>{dayjs(track.length).format().slice(14, 19)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {!!el.album && renderPlaylist(el.album)}
                {!!el.playlist && renderPlaylist(el.playlist)}
                {!!el.location && (
                    <div className={styles.feedLocation}>
                        <Location /> {el.location.name}
                    </div>
                )}
            </>}
        {!idx.toString().includes('reposted') && (<>
            <div className={classList(styles.moreActionsWrapper, audioStyles.moreActionsWrapper)}>
                <ClickAwayListener onClickAway={() => contextMenuActions('close', el.id)}>
                    <div className={audioStyles.moreActions}>
                        <button
                            className={classList(audioStyles.moreActions__btn, { [audioStyles.active]: contextMenu === el.id })}
                            onClick={() => contextMenuActions('open', el.id)}
                        >
                            <span />
                            <span />
                            <span />
                        </button>
                        {contextMenu === el.id && <div className={audioStyles.moreActions__container}>
                            <button
                                className={classList(audioStyles.actionButtons, audioStyles.deleteAction)}
                                onClick={() => dispatch(deletePostModal(el.id))}
                            >
                                <DeleteAction />Delete
                            </button>
                        </div>}
                    </div>
                </ClickAwayListener>
            </div>
            <div className={styles.feedPostStatistic}>
                {isInner
                    ? <div className={styles.feedPostStatisticButton}>
                        <CommentsIcon /><span>{el.comments_count}</span>
                    </div>
                    : <ButtonMUI
                        variant='text'
                        className={classList(styles.feedPostStatisticButton, styles.blue)}
                        onClick={() => openInnerModal(0)}
                        loading={postModalID == el.id && postInnerLoading}
                    >
                        <CommentsIcon /><span>{el.comments_count}</span>
                    </ButtonMUI>
                }
                <div className={styles.feedPostStatisticButton}>
                    <RepostsIcon /><span>{el.reposts_count}</span>
                </div>
                <div className={styles.feedPostStatisticButton}>
                    <LikesIcon /><span>{el.favorites_count}</span>
                </div>
            </div>
        </>)}
    </div>
}

export default RenderPost