import { indexWalk } from '../../../helpers/functions';
import { USERS } from './usersActionTypes';

const INITIAL_STATE = {
    users: {
        count: 0,
        results: []
    },
    users_loading: true,
    userProfile: {},
    userStatistic: {},
    userFeed: { next: null, results: [] },
    userInnerLoading: true,
    postModalID: undefined,
    postModalImageIndex: undefined,
    postInner: {},
    postInnerLoading: true,
    postComments: { next: null, results: [] },
    deletePostModalID: undefined
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS.GET_USERS:
            return { ...state, users_loading: true };
        case USERS.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.data,
                users_loading: false
            };
        case USERS.VERIFY_USER_SUCCESS:
        case USERS.UNVERIFY_USER_SUCCESS:
        case USERS.BLOCK_USER_SUCCESS:
        case USERS.UNBLOCK_USER_SUCCESS:
        case USERS.DELETE_USER_SUCCESS:
            const { data, config: { userID, userAction } } = action.payload;
            state.users.results.splice(
                state.users.results.findIndex(({ id }) => id === userID),
                1,
                userAction === 'delete' ? undefined : {
                    ...state.users.results.find(({ id }) => id === userID),
                    ...data
                }
            )
            if (state.userProfile.id === userID && userAction !== 'delete') {
                state.userProfile = { ...state.userProfile, ...data }
            }
            return { ...state };
        case USERS.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload.data
            };
        case USERS.GET_USER_STATISTIC_SUCCESS:
            return {
                ...state,
                userStatistic: action.payload.data
            };
        case '@@router/LOCATION_CHANGE': // clear feed during leave inner user page
            if (state.userFeed?.results?.length > 0) {
                return {
                    ...state,
                    userProfile: {},
                    userStatistic: {},
                    userFeed: {
                        results: []
                    },
                    userInnerLoading: true
                }
            } else {
                return { ...state }
            }
        case USERS.GET_USER_FEED_SUCCESS:
            return {
                ...state,
                userFeed: {
                    next: action.payload.data.next,
                    results: action.payload.config.isPaginate
                        ? [
                            ...state.userFeed.results,
                            ...action.payload.data.results
                        ]
                        : [...action.payload.data.results]
                },
                userInnerLoading: false
            };
        case USERS.SET_POST_MODAL:
            return {
                ...state,
                postModalID: action.payload.id,
                postModalImageIndex: action.payload.imageIndex
            };
        case USERS.GET_POST:
            return {
                ...state,
                postInnerLoading: true
            };
        case USERS.GET_POST_SUCCESS:
        case USERS.GET_POST_FAIL:
            let tempPostState = { ...state, postInnerLoading: false };
            if (action.type === USERS.GET_POST_SUCCESS) tempPostState.postInner = action.payload.data
            return { ...tempPostState };
        case USERS.GET_POST_COMMENTS_SUCCESS:
            return {
                ...state,
                postComments: {
                    next: action.payload.data.next,
                    results: action.payload.config.isPaginate
                        ? [
                            ...state.postComments.results,
                            ...action.payload.data.results
                        ]
                        : [
                            ...action.payload.data.results
                        ]
                }
            };
        case USERS.DELETE_POST_MODAL:
            return {
                ...state,
                deletePostModalID: action.payload.id
            }
        case USERS.DELETE_POST_SUCCESS:
            return {
                ...state,
                postModalID: undefined,
                postModalImageIndex: undefined,
                postInner: {},
                postInnerLoading: true,
                postComments: { next: null, results: [] },
                deletePostModalID: undefined,
                userFeed: {
                    ...state.userFeed,
                    results: [
                        ...state.userFeed.results.filter(el => el.id !== action.payload.config.id)
                    ]
                }
            }
        case USERS.DELETE_POST_COMMENTS_SUCCESS:
            let tempArr = indexWalk(state.postComments.results, action.payload.config.indexes, 'children')
            return {
                ...state,
                postComments: {
                    ...state.postComments,
                    results: [...tempArr]
                }
            }
        default:
            return state;
    }
}
