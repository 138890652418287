import React, { useEffect, useRef, useState } from 'react';
import { ButtonMUI, InputMUI, SelectComponent, TabItem, Table, Tabs, DialogMUI } from '../../shared';
import styles from './Audio.module.scss';
import { classList, formatFileSize, objectToQueryString, prettyScroll, formatAlbumTime } from '../../helpers/functions';
import { ReactComponent as AudioSettings } from '../../assets/icons/audio_setting.svg';
import { ReactComponent as AudioUpload } from '../../assets/icons/audio_upload.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg';
import { ReactComponent as OpenLinkIcon } from '../../assets/icons/open_link.svg';
import { ReactComponent as Verified } from '../../assets/icons/verified.svg';
import { ReactComponent as PartiallyBlocked } from '../../assets/icons/partiallyBlocked.svg';
import { ReactComponent as Blocked } from '../../assets/icons/block.svg';
import { ReactComponent as Active } from '../../assets/icons/active.svg';
import { ReactComponent as BlockAction } from '../../assets/icons/block_action.svg';
import { ReactComponent as UnblockAction } from '../../assets/icons/unblock_action.svg';
import { ReactComponent as DeleteAction } from '../../assets/icons/delete_action.svg';
import profile from '../../assets/icons/profile.svg';
import AdditionalFilters from './AdditionalFilters';
import { getAudioContent, getEmotions, getGenre, processUploaded, uploadTrack } from './storage/audioActions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { ClickAwayListener } from '@material-ui/core';
import ActionDialog from './ActionDialog';
import { Link } from 'react-router-dom';
import { CLIENT_URL } from '../../config';

import noAlbumImg from '../../assets/images/nonAvatars/album.svg';
import noTrackImg from '../../assets/images/nonAvatars/track.svg';
import UploadingProcessDialog from './Upload/UploadingProcessDialog';
import axios from 'axios';

const Audio = () => {
  const dispatch = useDispatch();
  const { emotionsList, genreList, ...audio } = useSelector(({ audio }) => audio);
  const [isFirst, setIsFirst] = useState(true);
  const [tab, setTab] = useState('tracks');
  const [parameters, setParameters] = useState(initialParameters);
  const [contextMenu, setContextMenu] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null });

  const paramsSetter = (key, value) => {
    setParameters((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    setParameters(initialParameters);
    if (isFirst) setIsFirst(false);
    else if (parameters === initialParameters) dispatch(getAudioContent(tab, objectToQueryString(parameters)));
  }, [tab]);

  useEffect(() => {
    if (!dialogStatus.status) setTimeout(() => setDialogStatus((prev) => ({ ...prev, type: null, target: null })), 250);
  }, [dialogStatus.status]);

  useEffect(() => {
    dispatch(getEmotions());
    dispatch(getGenre());
  }, []);

  const contextMenuActions = (action, id) => {
    if (action === 'close') setContextMenu((prev) => (prev === id ? false : prev));
    else if ('open') setContextMenu((prev) => (prev === id ? false : id));
  };

  const openDialog = (type) => {
    setDialogStatus({ status: true, type: type, target: audio[tab]?.results.find((el) => el.id === contextMenu) });
    contextMenuActions('close', contextMenu);
  };

  const columns = [
    {
      name: 'Name',
      value: 'name',
      width: 23,
      renderKey: ['name', 'image', 'version', 'album_name', 'file_size', 'length', 'tracks_count', 'id'],
      render: ({ name, image, version, album_name, file_size, length, tracks_count, id }) => (
        <Link to={`/main/audio/${tab.slice(0, -1)}/${id}`} className={styles.nameWrapper}>
          <img src={tab === 'tracks' ? image || noTrackImg : image || noAlbumImg} alt={album_name} />
          <div className={styles.info}>
            <p>{name}</p>
            {renderAlbumInfo({ version, album_name, file_size, length, tracks_count }, tab)}
          </div>
        </Link>
      )
    },
    {
      name: 'Artist',
      value: 'artist',
      width: 24,
      renderKey: `artist${tab === 'tracks' ? 's' : ''}`,
      render: (artists) => renderArtists(artists)
    },
    {
      name: 'Release date',
      value: 'release_date',
      width: 12,
      renderKey: 'release_date',
      render: (date) => {
        return <div className={'cellText'}>{!!date ? dayjs(date).format('DD/MM/YYYY') : '-'}</div>;
      }
    },
    {
      name: 'Price',
      value: 'price',
      width: 10,
      renderKey: ['price', 'is_blocked', 'is_partially_blocked'],
      render: ({ price, is_blocked, is_partially_blocked }) => {
        return (
          <div className={'cellText'} style={is_blocked || is_partially_blocked ? { color: '#868B96' } : {}}>
            {!!price ? `$${price}` : 'Free'}
          </div>
        );
      }
    },
    {
      name: 'Status',
      value: null,
      width: 12,
      renderKey: ['is_blocked', 'is_partially_blocked', 'blocked_tracks_count', 'tracks_count'],
      render: renderStatus
    },
    {
      name: '',
      value: null,
      width: 5,
      renderKey: ['id', 'is_blocked', 'is_partially_blocked', 'artists', 'artist', 'slug'],
      render: ({ id, is_blocked, is_partially_blocked, artists, artist, slug }) => (
        <div className={styles.moreActionsWrapper}>
          {tab === 'tracks' ? (
            <a
              href={`${CLIENT_URL}/main/track/${artists?.find((el) => el.is_main)?.username?.replace('@', '')}/${slug}`}
              target='_blank'
            >
              <OpenLinkIcon />
            </a>
          ) : (
            <a href={`${CLIENT_URL}/main/albums/${artist?.username?.replace('@', '')}/${slug}`} target='_blank'>
              <OpenLinkIcon />
            </a>
          )}
          <ClickAwayListener onClickAway={() => contextMenuActions('close', id)}>
            <div className={styles.moreActions}>
              <button
                className={classList(styles.moreActions__btn, { [styles.active]: contextMenu === id })}
                onClick={() => contextMenuActions('open', id)}
              >
                <span />
                <span />
                <span />
              </button>
              {contextMenu === id && (
                <div className={styles.moreActions__container}>
                  {is_partially_blocked ? (
                    <>
                      <button className={classList(styles.actionButtons)} onClick={() => openDialog('unblock')}>
                        <UnblockAction />
                        Unblock all tracks
                      </button>
                      <button className={classList(styles.actionButtons)} onClick={() => openDialog('block')}>
                        <BlockAction />
                        Block all tracks
                      </button>
                    </>
                  ) : is_blocked ? (
                    <button className={classList(styles.actionButtons)} onClick={() => openDialog('unblock')}>
                      <UnblockAction />
                      Unblock
                    </button>
                  ) : (
                    <button className={classList(styles.actionButtons)} onClick={() => openDialog('block')}>
                      <BlockAction />
                      Block
                    </button>
                  )}
                  <button
                    className={classList(styles.actionButtons, styles.deleteAction)}
                    onClick={() => openDialog('delete')}
                  >
                    <DeleteAction />
                    Delete
                  </button>
                </div>
              )}
            </div>
          </ClickAwayListener>
        </div>
      )
    }
  ];

  const [uploadedCount, setUploadedCount] = useState(0);
  const [mustUploadedCount, setMustUploadedCount] = useState(0);
  const [filesList, setFilesList] = useState([]);
  const [errorsTrack, setErrorsTrack] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [isUploadIdx, setIsUploadIdx] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [controller, setController] = useState(null);

  const openUploadRef = useRef(openUpload);

  const closeUpload = () => {
    setOpenUpload(false);

    setIsComplete(false);
    setMustUploadedCount(0);
    setFilesList([]);
    setErrorsTrack([]);
    setUploadedCount(0);
    setIsUploadIdx(0);
    setFilesToUpload([]);
    document.getElementById('upload').value = '';
    controller.cancel('Request canceled');
  };

  const uploadTracks = async (files) => {
    setOpenUpload(true);
    setIsUploadIdx(0);
    setFilesToUpload(files);
  };

  useEffect(() => {
    openUploadRef.current = openUpload;
  }, [openUpload]);

  useEffect(() => {
    if (openUpload && filesToUpload.length > 0) {
      const executeUpload = async () => {
        setIsUploadIdx(0);
        let successfulUploads = 0;
        let errorsArray = [];
        const totalTracks = filesToUpload.length;

        for (let idx = 0; idx < filesToUpload.length; idx++) {
          const cancelTokenSource = axios.CancelToken.source();
          setController(cancelTokenSource);

          if (!openUploadRef.current) {
            break;
          }

          const track = filesToUpload[idx];
          const data = new FormData();
          data.append('file', track);
          setIsUploadIdx(idx);

          try {
            const res = await dispatch(uploadTrack(data, cancelTokenSource.token));
            if (res?.payload?.status === 200) {
              successfulUploads++;
              setUploadedCount(successfulUploads);
              if (idx + 1 === totalTracks) {
                const confirmRes = await dispatch(processUploaded());
                if (confirmRes?.payload?.status === 200) {
                  if (errorsArray.length === 0) {
                    console.log('all complete');
                  } else {
                    setErrorsTrack(errorsArray);
                  }
                  setIsComplete(true);
                } else {
                  console.log(confirmRes);
                }
              }
            } else {
              if (idx + 1 === totalTracks) {
                setErrorsTrack([...errorsArray, { track, res }]);
                setIsComplete(true);
              } else {
                errorsArray = [...errorsArray, { track, res }];
              }
              if (res.meta.previousAction.payload.request?.cancelToken?.reason?.message === 'Request canceled') {
                setIsComplete(false);
                setMustUploadedCount(0);
                setFilesList([]);
                setErrorsTrack([]);
                setUploadedCount(0);
                setIsUploadIdx(0);
                setFilesToUpload([]);
                document.getElementById('upload').value = '';
              }
            }
          } catch (error) {
            console.error('Error uploading track:', error);
            if (idx + 1 === totalTracks) {
              setErrorsTrack([...errorsArray, { track, error }]);
              setIsComplete(true);
            } else {
              errorsArray = [...errorsArray, { track, error }];
            }
          }
        }
      };

      executeUpload();
    }
  }, [openUpload, filesToUpload]);

  useEffect(() => {
    openUploadRef.current = openUpload;
  }, [openUpload]);

  const handleChangeUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const filesCount = selectedFiles.length;
    setMustUploadedCount(filesCount);
    setFilesList(selectedFiles);
    uploadTracks(selectedFiles);
  };

  return (
    <main className={`${styles.audio} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.audioInner} container`}>
        <h1>Audio</h1>
        <div className={styles.tabWrap}>
          <Tabs className={styles.tabs} defaultIndex={tab} onTabClick={setTab}>
            <TabItem label='Tracks' index='tracks' />
            <TabItem label='Albums' index='albums' />
          </Tabs>

          <div className={styles.rootMenu}>
            {/* <ButtonMUI
              variant='text'
            >
              <AudioSettings /> Settings
            </ButtonMUI> */}

            <input onChange={handleChangeUpload} type='file' id='upload' accept='audio/*' multiple />

            <ButtonMUI onClick={() => document.getElementById('upload').click()} size='small'>
              <AudioUpload /> Upload
            </ButtonMUI>
          </div>
        </div>
        <div className={`inner-wrap ${styles['inner-wrap']}`}>
          <div className={styles.filters}>
            <InputMUI
              className={styles.search}
              id='search'
              type='text'
              placeholder='Search by name or artist...'
              startAdornment={<SearchIcon />}
              size='small'
              value={parameters.search}
              onChange={({ target: { value } }) => paramsSetter('search', value)}
            />
            <SelectComponent
              options={Statuses[tab]}
              value={Statuses[tab].find((el) => el.value === parameters.status)}
              onChange={(e) => paramsSetter('status', e.value)}
              selectWithLabel={'Status'}
            />
            <AdditionalFilters
              genreOptions={genreList}
              emotionsOptions={emotionsList}
              ageOptions={ageOptions}
              parameters={parameters}
              setParameters={setParameters}
              initialParameters={initialParameters}
            />
          </div>
          <Table
            action={({ queryString }) => dispatch(getAudioContent(tab, queryString))}
            disableRedux
            params={parameters}
            setParams={setParameters}
            columns={columns}
            rows={audio[tab]?.results}
            rowsCount={audio[tab]?.count}
            loading={audio[`${tab}_loading`]}
            tab={tab}
          />
        </div>
      </div>
      <ActionDialog type={tab} dialogStatus={dialogStatus} setDialogStatus={setDialogStatus} />
      {openUpload && (
        <UploadingProcessDialog
          isUploadIdx={isUploadIdx}
          isComplete={isComplete}
          errorsTrack={errorsTrack}
          files={filesList}
          counts={{ mustUploadedCount, uploadedCount }}
          dialogIsOpen={openUpload}
          closeDialog={closeUpload}
        />
      )}
    </main>
  );
};

export const renderArtists = (artists, isInner, isLink = true) => {
  if (typeof artists !== 'object' || artists.length < 1) {
    // not object, not array, or empty one
    return '-';
  } else if (artists.length === 1 || (typeof artists === 'object' && !Array.isArray(artists))) {
    // array with one item, or object
    let artist = artists.length === 1 ? artists[0] : artists;
    return (
      <a
        style={{ pointerEvents: isLink ? "auto" : 'none' }}
        href={`/main/users/${artist.id}`}
        target='_blank'
        className={classList(styles.artistsWrapper, { [styles.inner]: isInner })}
      >
        <div className={styles.imagesWrapper}>
          <img src={artist.avatar || profile} alt={artist.name || artist.full_name || artist.username} />
        </div>
        <div className={styles.artistNameWrapper}>
          <p>
            {artist?.name?.replaceAll(' ', '')
              ? artist.name
              : artist?.full_name?.replaceAll(' ', '')
                ? artist.full_name
                : artist.username}
            {artist.is_verified && <Verified />}
          </p>
          {!isInner && <span>{artist.username}</span>}
        </div>
      </a>
    );
  } else if (artists.length > 1) {
    // array with 2 items
    return (
      <div className={classList(styles.artistsWrapper, styles.moreThenOne, { [styles.inner]: isInner })}>
        <div className={styles.imagesWrapper}>
          {artists.length <= 2 || isInner ? (
            artists.map(({ avatar, name, username, id }, idx) => (
              <a href={`/main/users/${id}`} target='_blank'>
                {' '}
                <img style={{ zIndex: idx + 10 }} key={idx} src={avatar || profile} alt={name} />
              </a>
            ))
          ) : (
            <>
              <a href={`/main/users/${artists[0].id}`} target='_blank'>
                <img src={artists[0].avatar || profile} alt={artists[0].name} />
              </a>
              <div className={styles.moreIndicator}>+{artists.length - 1}</div>
            </>
          )}
        </div>
        <div className={styles.artistNameWrapper}>
          {isInner ? (
            artists.map((el, idx) => (
              <p key={idx}>
                <a href={`/main/users/${el.id}`} target='_blank'>
                  {el?.name?.replaceAll(' ', '')
                    ? el.name
                    : el?.full_name?.replaceAll(' ', '')
                      ? el.full_name
                      : el.username}
                  {el.is_verified && <Verified />}
                  {idx !== artists.length - 1 && ', '}
                </a>
              </p>
            ))
          ) : (
            <>
              <p>
                <a href={`/main/users/${artists[0].id}`} target='_blank'>
                  {artists[0].name.replaceAll(' ', '') ? artists[0].name : artists[0].username}
                  {artists[0].is_verified && <Verified />}
                </a>
              </p>
              <span>...and {artists.length - 1} more</span>
            </>
          )}
        </div>
      </div>
    );
  }
};

export const renderAlbumInfo = (
  { version, album_name, tracks_count, length, file_size, price, creator },
  type,
  isAlbumTrack = false
) => (
  <div className={styles.albumInfo}>
    {version === 1 && <div className={styles.explicit}>e</div>}
    {((type === 'tracks' && album_name !== '' && !isAlbumTrack) || !!creator) && (
      <>
        <span>{album_name || creator?.full_name}</span>
        {' • '}
      </>
    )}
    {type === 'albums' && tracks_count !== 0 && (
      <>
        <span>
          {tracks_count} track{tracks_count > 1 && 's'}
        </span>
        {' • '}
      </>
    )}
    <span>{type === 'tracks' ? dayjs(length).format().slice(14, 19) : formatAlbumTime(length)}</span>
    {type === 'tracks' && file_size !== null && file_size !== undefined && (
      <>
        {', '}
        <span>{formatFileSize(file_size)}</span>
      </>
    )}
    {!!isAlbumTrack && !!price && (
      <>
        {' • '}
        <span className={styles.trackPrice}>${price}</span>
      </>
    )}
  </div>
);

export const renderStatus = ({ is_blocked, is_partially_blocked, blocked_tracks_count, tracks_count }) => (
  <div
    className={classList(
      styles.status,
      styles[is_partially_blocked ? 'partiallyBlocked' : is_blocked ? 'blocked' : 'active']
    )}
  >
    {is_partially_blocked ? (
      <>
        <PartiallyBlocked />
        {blocked_tracks_count}/{tracks_count} Blocked
      </>
    ) : is_blocked ? (
      <>
        <Blocked />
        Blocked
      </>
    ) : (
      <>
        <Active />
        Active
      </>
    )}
  </div>
);

const Statuses = {
  tracks: [
    {
      value: null,
      label: 'All tracks'
    },
    {
      value: 'is_active',
      label: 'Active'
    },
    {
      value: 'is_blocked',
      label: 'Blocked'
    }
  ],
  albums: [
    {
      value: null,
      label: 'All albums'
    },
    {
      value: 'is_active',
      label: 'Active'
    },
    {
      value: 'is_partially_blocked',
      label: 'Partially blocked'
    },
    {
      value: 'is_blocked',
      label: 'Blocked'
    }
  ]
};

export const ageOptions = [
  {
    label: 'Not specified',
    value: 0,
    id: 0
  },
  {
    label: '7 years and older (Older children)',
    value: 1,
    id: 1
  },
  {
    label: '13 years and older (Teenagers)',
    value: 2,
    id: 2
  },
  {
    label: '16 years and older (Young people)',
    value: 3,
    id: 3
  },
  {
    label: '18 years and older (Adults)',
    value: 4,
    id: 4
  }
];

const initialParameters = {
  page_size: 10,
  status: null,
  ordering: 'name',
  genres: [],
  emotions: [],
  age: [],
  version: null, //  1 || null
  from_date: null,
  to_date: null,
  price_min: '',
  price_max: '',
  search: ''
};

export default Audio;
