import { ADVERTISING } from './advertisingActionTypes';

export function getAdvertising({ queryString }) {
    return {
        type: ADVERTISING.GET_ADVERTISING,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/advertisers${!!queryString && queryString.length > 0 ? queryString : ''}`,
                method: 'GET',
            },
        },
    };
}

export function deleteAdvertising(id) {
    return {
        type: ADVERTISING.DELETE_ADVERTISING,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/advertisers/${id}`,
                id,
                method: 'DELETE',
            },
        },
    };
}

export function advertisingSettings(method, tracks_count) {
    let returnableObj = {}
    if (method === 'SET') {
        returnableObj = {
            type: ADVERTISING.ADVERTISING_SETTINGS_SUCCESS,
            payload: {
                data: { tracks_count: tracks_count }
            }
        }
    } else {
        returnableObj = {
            type: ADVERTISING.ADVERTISING_SETTINGS,
            payload: {
                client: 'default',
                request: {
                    url: `admin/advertisement/settings`,
                    method: method,
                },
            },
        }
        if (method === 'PUT') {
            returnableObj.type = ADVERTISING.ADVERTISING_SETTINGS_PUT
            returnableObj.payload.request.data = { tracks_count: tracks_count }
        }
    }
    return returnableObj
}

export function getAdvertiser(id) {
    return {
        type: ADVERTISING.GET_ADVERTISER,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/advertisers/${id}`,
                id,
                method: 'GET',
            },
        },
    };
}

export function getAdvertiserCampaigns(id) {
    return {
        type: ADVERTISING.GET_ADVERTISER_CAMPAIGNS,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/advertisers/${id}/campaigns`,
                id,
                method: 'GET',
            },
        },
    };
}

export function getAdvertiserStatistic(id, isCampaign = false) {
    return {
        type: ADVERTISING[`GET_${isCampaign ? 'CAMPAIGN' : 'ADVERTISER'}_STATISTIC`],
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/${isCampaign ? 'campaigns' : 'advertisers'}/${id}/statistic`,
                method: 'GET',
            },
        },
    };
}

export function editAdvertiserAction(data) {
    return {
        type: ADVERTISING.EDIT_ADVERTISER,
        payload: {
            data
        },
    };
}

export function advertiserAction(data, id = undefined) {
    return {
        type: ADVERTISING[`${!!id ? 'PUT' : 'POST'}_ADVERTISER`],
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/advertisers${!!id ? `/${id}` : ''}`,
                method: !!id ? 'PUT' : 'POST',
                data
            },
        },
    };
}

export function editCampaignAction(data) {
    return {
        type: ADVERTISING.EDIT_CAMPAIGN,
        payload: {
            data
        },
    };
}

export function campaignAction(method, id, data = undefined, isRestore = false) {
    return {
        type: ADVERTISING[`${method.toUpperCase()}_CAMPAIGN`],
        payload: {
            client: 'default',
            request: {
                url: `/admin/advertisement/${method.toUpperCase() === 'POST' ? `advertisers/${id}/new-campaign` : `campaigns/${id}`}${isRestore ? `/restore` : ``}`,
                method,
                data,
                id
            },
        },
    };
}

export function campaignArchive(id) {
    return {
        type: ADVERTISING.ARCHIVE_CAMPAIGN,
        payload: {
            client: 'default',
            request: {
                url: `/admin/advertisement/campaigns/${id}/archive`,
                method: 'PUT',
                id
            },
        },
    };
}

export function getCampaignTracks(campaignID) {
    return {
        type: ADVERTISING.GET_CAMPAIGN_TRACKS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/advertisement/campaigns/${campaignID}/tracks?page_size=1000`,
                method: 'GET'
            },
        },
    };
}

export function uploadCampaignTrack(campaignID, data) {
    return {
        type: ADVERTISING.UPLOAD_CAMPAIGN_TRACK,
        payload: {
            client: 'default',
            request: {
                url: `/admin/advertisement/campaigns/${campaignID}/track`,
                method: 'POST',
                data
            },
        },
    };
}

export function updateCampaignTrack(id, data) {
    return {
        type: ADVERTISING.UPDATE_CAMPAIGN_TRACK,
        payload: {
            data: { id, ...data }
        },
    };
}

export function updateCampaignTrackOnDB(data) {
    return {
        type: ADVERTISING.UPDATE_CAMPAIGN_TRACK_ON_DB,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/track/${data.id}`,
                method: 'PUT',
                data: {
                    name: data.name,
                    description: data.description
                },
                localData: data
            },
        },
    };
}

export function deleteCampaignAudio(trackID) {
    return {
        type: ADVERTISING.DELETE_CAMPAIGN_TRACK,
        payload: {
            client: 'default',
            request: {
                url: `admin/advertisement/track/${trackID}`,
                method: 'DELETE',
                id: trackID
            },
        },
    };
}