import { useEffect, useState } from "react"
import { classList } from "../../../helpers/functions"
import { ButtonMUI, DialogMUI } from "../../../shared"
import styles from '../../Audio/Audio.module.scss'
import { useDispatch, useSelector } from "react-redux"
import { deletePost, deletePostModal } from "../storage/usersActions"

const DeleteDialog = () => {
    const dispatch = useDispatch();
    const { deletePostModalID, postModalID } = useSelector(({ users }) => users)
    const [btnLoad, setBtnLoad] = useState(false)

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(deletePost(deletePostModalID)).then(() => {
            if (!postModalID) setBtnLoad(false)
        })
    }

    return <DialogMUI
        open={!!deletePostModalID}
        onClose={() => dispatch(deletePostModal())}
        maxWidth={'xs'}
    >
        <>
            <h2 className={styles.dialogTitle}>Delete post</h2>
            <div className={classList(styles.dialogWrapper, styles.delete)}>
                <p className={styles.desc}>
                    Are you sure you want to delete this post? This action cannot be undone.
                </p>
                <div className={styles.separator} />
                <div className={styles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={styles.dialogButtons}
                        onClick={submitDialog}
                        loading={btnLoad}
                        disabled={btnLoad}
                    >
                        delete
                    </ButtonMUI>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={classList(styles.dialogButtons, styles.cancel)}
                        onClick={() => dispatch(deletePostModal())}
                    >
                        cancel
                    </ButtonMUI>
                </div>
            </div>
        </>
    </DialogMUI>
}

export default DeleteDialog