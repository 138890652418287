import React from 'react';
import { NavLink } from 'react-router-dom';
import { mainPath } from '../../routes/paths';
import styles from './NavSidebar.module.scss';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg'

const NavSidebar = () => {
  return (
    <aside className={styles.root}>
      <Logo className={styles.logo} />
      <div className={styles.navList}>
        {Object.values(mainPath).filter(({ isNavItem }) => !!isNavItem).map(({ name, path, icon, iconActive }, idx) =>
          <NavLink
            className={styles.navItem}
            activeClassName={styles.active}
            key={idx}
            to={path}
          >
            {icon}
            {iconActive}
            <span>{name}</span>
          </NavLink>
        )}
      </div>
    </aside>
  );
};

export default NavSidebar;
