import { useEffect, useState } from 'react';
import { classList, objectToQueryString, prettyScroll } from '../../helpers/functions';
import { ButtonMUI, DialogMUI, InputMUI, SelectComponent, TabItem, Table, Tabs } from '../../shared';
import styles from './PagesContent.module.scss';
import adverStyles from '../Advertising/Advertising.module.scss'
import parentStyles from '../Audio/Audio.module.scss';
import AdditionalFilters from './AdditionalFilters';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { ReactComponent as SelectIcon } from '../../assets/icons/select.svg'
import { ReactComponent as ManualChart } from '../../assets/icons/manual_chart.svg'
import { ReactComponent as AutoChart } from '../../assets/icons/auto_chart.svg'
import { ReactComponent as AutoBtn } from '../../assets/icons/auto_btn.svg'
import { ReactComponent as Globus } from '../../assets/icons/globus.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/add-genre.svg'
import { getContent, setCountryAuto, setGenresAuto } from './storage/pagesContentActions';
import { useDispatch, useSelector } from 'react-redux';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';
import { CreateGenreModal, SetAutoModal } from './Modals';

const PagesContent = () => {
    const dispatch = useDispatch()
    const [isFirst, setIsFirst] = useState(true)
    const [parameters, setParameters] = useState(initialParameters)
    const [tab, setTab] = useState('countries')
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)
    const [openNewGenre, setOpenNewGenre] = useState(false)
    const content = useSelector(({ pagesContent }) => pagesContent)

    const paramsSetter = (key, value) => {
        setParameters((prev) => ({ ...prev, [key]: value === 'null' ? null : value }))
    }

    useEffect(() => {
        let tempParams = { ...initialParameters };
        if (tab === 'genres') tempParams.non_music = null
        setParameters(tempParams)
        if (isFirst) setIsFirst(false)
        else if (parameters === initialParameters) dispatch(getContent(tab, objectToQueryString(parameters)))
    }, [tab])

    useEffect(() => {
        if (!dialogStatus.status) setTimeout(() => setDialogStatus(prev => ({ ...prev, type: null, target: null })), 250)
    }, [dialogStatus.status])

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: content[tab]?.results.find(el => el.name === contextMenu) })
        contextMenuActions('close', contextMenu)
    }

    const autoIndicator = (status) => (
        <div className={classList(styles.autoIndicator, styles[status])}>
            {status ? <AutoChart /> : <ManualChart />}
            {status ? 'Automatic' : 'Manual'}
        </div>
    )

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const columns = [
        {
            name: 'Name',
            value: 'name',
            width: 25,
            renderKey: ['name', 'code', 'id'],
            render: ({ name, code, id }) => <Link to={`/main/pages-content/${tab}/${tab === 'countries' ? code : id}/weekly`} className={styles.nameWrapper}>
                {tab === 'genres' ? null : code === '00' ? <Globus /> : <span className={`fi fi-${code.toLowerCase()} fis`} />}
                <p>{name}</p>
            </Link>
        },
        {
            name: 'Top for Week',
            value: null,
            width: 20,
            renderKey: 'weekly_chart_auto',
            render: autoIndicator
        },
        {
            name: 'Top for Month',
            value: null,
            width: 20,
            renderKey: 'monthly_chart_auto',
            render: autoIndicator
        },
        {
            name: 'Top for Year',
            value: null,
            width: 20,
            renderKey: 'yearly_chart_auto',
            render: autoIndicator
        },
        {
            name: '',
            value: null,
            width: 15,
            renderKey: ['code', 'name', 'weekly_chart_auto', 'monthly_chart_auto', 'yearly_chart_auto'],
            render: ({ code, name, weekly_chart_auto, monthly_chart_auto, yearly_chart_auto }) => (
                [weekly_chart_auto, monthly_chart_auto, yearly_chart_auto].includes(false) && <div className={adverStyles.moreActionsWrapper}>
                    <ClickAwayListener onClickAway={() => contextMenuActions('close', name)}>
                        <div className={adverStyles.moreActions}>
                            <button className={classList(adverStyles.moreActions__btn, { [adverStyles.active]: contextMenu === name })} onClick={() => contextMenuActions('open', name)}>
                                <span />
                                <span />
                                <span />
                            </button>
                            {contextMenu === name && <div className={adverStyles.moreActions__container}>
                                <button
                                    className={classList(adverStyles.actionButtons)}
                                    onClick={() => openDialog(name)/* dispatch(setCountryAuto(code)).then(res => res.type.includes('_SUCCESS') && contextMenuActions('close', name)) */}
                                >
                                    <AutoBtn />Set ‘Automatic’
                                </button>
                            </div>}
                        </div>
                    </ClickAwayListener>
                </div>)
        },
    ]

    if (tab === 'genres') {
        columns[0].width = 25;
        columns[1].width = 15;
        columns[2].width = 15;
        columns[3].width = 15;
        columns[4].width = 5;
        columns.splice(1, 0, {
            name: 'Category',
            value: null,
            width: 25,
            renderKey: 'non_music',
            render: (non_music) => <div className='cellText'>
                {non_music ? 'Non-music' : 'Music'}
            </div>
        });
    }

    const submitAuto = () => {
        setBtnLoad(true)
        const thenStatement = res => {
            setBtnLoad(false)
            res.type.includes('_SUCCESS') && setDialogStatus(prev => ({ ...prev, status: false }))
        }

        dispatch(tab === 'countries'
            ? setCountryAuto(
                dialogStatus.type === 'all'
                    ? dialogStatus.type
                    : dialogStatus.target.code
            )
            : setGenresAuto(
                dialogStatus.type === 0
                    ? dialogStatus.type
                    : dialogStatus.target.id
            )
        ).then(thenStatement)
    }

    return <main className={`${styles.content} page-wrap`} ref={prettyScroll}>
        <div className={`${styles.contentInner} container`}>
            <h1>Popular music by...</h1>
            <div className={styles.tabWrap}>
                <Tabs className={styles.tabs} defaultIndex={tab} onTabClick={setTab}>
                    <TabItem label='Country' index='countries' />
                    <TabItem label='Genre' index='genres' />
                </Tabs>
            </div>
            <div className={`inner-wrap ${styles['inner-wrap']}`}>
                <div className={styles.filters}>
                    <InputMUI
                        className={styles.search}
                        id='search'
                        type='text'
                        placeholder={`Search by ${tab === 'genres' ? 'genre' : 'country'}...`}
                        startAdornment={<SearchIcon />}
                        size='small'
                        name={'country_search'}
                        value={parameters.search}
                        onChange={({ target: { value } }) => paramsSetter('search', value)}
                    />
                    <AdditionalFilters
                        tab={tab}
                        parameters={parameters}
                        setParameters={setParameters}
                        paramsSetter={paramsSetter}
                        initialParameters={initialParameters}
                    />
                    <ButtonMUI
                        variant='text'
                        className={styles.allAuto}
                        onClick={() => openDialog(tab === 'countries' ? 'all' : 0)}
                    >
                        <SelectIcon /> Set ‘Automatic’ for all
                    </ButtonMUI>
                    {tab === 'genres' && <ButtonMUI
                        size='small'
                        className={classList(parentStyles.actionButtons, styles.addNewButton)}
                        onClick={() => setOpenNewGenre(true)}
                    >
                        <PlusIcon />New genre
                    </ButtonMUI>}
                </div>
                <Table
                    action={({ queryString }) => dispatch(getContent(tab, queryString))}
                    disableRedux
                    params={parameters}
                    setParams={setParameters}
                    columns={columns}
                    rows={content[tab]?.results}
                    rowsCount={content[tab]?.count}
                    loading={content[`${tab}_loading`]}
                />
            </div>
        </div>
        <SetAutoModal
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            btnLoad={btnLoad}
            submitDialog={submitAuto}
            tab={tab}
        />
        <CreateGenreModal
            open={openNewGenre}
            onCLose={() => setOpenNewGenre(false)}
        />
    </main>
}

const initialParameters = {
    page_size: 10,
    ordering: 'name',
    search: '',
    weekly_chart_auto: null,
    monthly_chart_auto: null,
    yearly_chart_auto: null,
}

export default PagesContent