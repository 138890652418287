import { SUPPORT } from './supportActionTypes';

const INITIAL_STATE = {
    claims: {
        count: 0,
        results: []
    },
    claims_loading: true,
    feedbacks: {
        count: 0,
        results: []
    },
    feedbacks_loading: true,
    playlistTacks: {},
    playlistTacks_loading: true,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SUPPORT.GET_CLAIMS:
            return { ...state, claims_loading: true };
        case SUPPORT.GET_CLAIMS_SUCCESS:
            return {
                ...state,
                claims: action.payload.data,
                claims_loading: false
            };
        case SUPPORT.GET_FEEDBACKS:
            return { ...state, feedbacks_loading: true };
        case SUPPORT.GET_FEEDBACKS_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload.data,
                feedbacks_loading: false
            };
        case SUPPORT.GET_PLAYLIST_TRACKS_SUCCESS:
            return {
                ...state,
                playlistTacks: !!action.payload.config.page
                    ? {
                        ...action.payload.data,
                        results: [
                            ...state.playlistTacks.results,
                            ...action.payload.data.results
                        ]
                    }
                    : action.payload.data,
                playlistTacks_loading: false
            };
        case SUPPORT.CLOSE_PLAYLIST_MODAL:
            return {
                ...state,
                playlistTacks_loading: true
            };
        case SUPPORT.SET_CLAIM_STATUS_SUCCESS:
            state.claims.results.splice(
                state.claims.results.findIndex(el => el.id === action.payload.config.id),
                1,
                { ...state.claims.results.find(el => el.id === action.payload.config.id), status: action.payload.config.newStatus }
            )

            return { ...state }
        default:
            return state;
    }
}
