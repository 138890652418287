import { indexWalk } from '../../../helpers/functions';
import { AUDIO } from './audioActionTypes';

const INITIAL_STATE = {
    tracks: {
        count: 0,
        results: []
    },
    tracks_loading: true,
    albums: {
        count: 0,
        results: []
    },
    albums_loading: true,
    emotionsList: [],
    genreList: [],
    //tracks
    trackInfo: {},
    trackStatistic: {},
    trackComments: { next: null, results: [] },
    //albums
    albumInfo: {},
    albumStatistic: {},
    albumComments: { next: null, results: [] },
    albumTracks: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUDIO.GET_TRACKS:
            return { ...state, tracks_loading: true };
        case AUDIO.GET_TRACKS_SUCCESS:
            return {
                ...state,
                tracks: action.payload.data,
                tracks_loading: false
            };
        case AUDIO.GET_ALBUMS:
            return { ...state, albums_loading: true };
        case AUDIO.GET_ALBUMS_SUCCESS:
            return {
                ...state,
                albums: action.payload.data,
                albums_loading: false
            };
        case AUDIO.TRACKS_BLOCK_SUCCESS:
            if (action.payload.config.isInner) {
                if (action.payload.config.isAlbumTrack) {
                    state.albumTracks.find(el => el.id === action.payload.config.id).is_blocked = action.payload.data.is_blocked
                    if (state.albumTracks.some(el => el.is_blocked)) {
                        if (state.albumTracks.every(el => el.is_blocked)) {
                            state.albumInfo.is_partially_blocked = false
                            state.albumInfo.is_blocked = true
                        } else {
                            state.albumInfo.is_partially_blocked = true
                            state.albumInfo.is_blocked = false
                        }
                    } else {
                        state.albumInfo.is_partially_blocked = false
                        state.albumInfo.is_blocked = false
                    }
                } else {
                    state.trackInfo.is_blocked = action.payload.data.is_blocked
                    state.trackInfo.blocked_text = action.payload.data.text
                }
            } else {
                state.tracks.results.find(el => el.id === action.payload.config.id).is_blocked = action.payload.data.is_blocked
            }
            return { ...state }
        case AUDIO.ALBUMS_BLOCK_SUCCESS:
            if (action.payload.config.isInner) {
                state.albumInfo.is_blocked = action.payload.data.is_blocked
                state.albumInfo.is_partially_blocked = false
                state.albumInfo.blocked_text = action.payload.data.text
                state.albumTracks = [...state.albumTracks.map(el => ({ ...el, is_blocked: action.payload.data.is_blocked }))]
            } else {
                state.albums.results.find(el => el.id === action.payload.config.id).is_blocked = action.payload.data.is_blocked
                state.albums.results.find(el => el.id === action.payload.config.id).is_partially_blocked = false
            }
            return { ...state }
        case AUDIO.TRACKS_DELETE_SUCCESS:
            if (action.payload.config.isInner && action.payload.config.isAlbumTrack) {
                return {
                    ...state,
                    albumTracks: [...state.albumTracks.filter(el => el.id !== action.payload.config.id)]
                }
            }
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    results: [...state.tracks.results.filter(el => el.id !== action.payload.config.id)]
                }
            }
        case AUDIO.ALBUMS_DELETE_SUCCESS:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    results: [...state.albums.results.filter(el => el.id !== action.payload.config.id)]
                }
            }
        case AUDIO.GET_EMOTIONS_SUCCESS:
            return { ...state, emotionsList: action.payload.data.length > 0 ? action.payload.data.map(({ id, name, emoji }) => ({ label: name, id, value: id, emoji })) : [] };
        case AUDIO.GET_GENRE_SUCCESS:
            return { ...state, genreList: action.payload.data.length > 0 ? action.payload.data.map(({ id, name }) => ({ label: name, id, value: id })) : [] };
        //track inner
        case AUDIO.GET_TRACK_INFO_SUCCESS:
            return { ...state, trackInfo: action.payload.data }
        case AUDIO.GET_TRACK_STATISTIC_SUCCESS:
            return { ...state, trackStatistic: action.payload.data }
        case AUDIO.GET_TRACK_COMMENTS_SUCCESS:
            return {
                ...state,
                trackComments: {
                    next: action.payload.data.next,
                    results: action.payload.config.isPaginate
                        ? [
                            ...state.trackComments.results,
                            ...action.payload.data.results
                        ]
                        : [
                            ...action.payload.data.results
                        ]
                }
            };
        //album inner
        case AUDIO.GET_ALBUM_INFO_SUCCESS:
            return { ...state, albumInfo: action.payload.data }
        case AUDIO.GET_ALBUM_STATISTIC_SUCCESS:
            return { ...state, albumStatistic: action.payload.data }
        case AUDIO.GET_ALBUM_COMMENTS_SUCCESS:
            return {
                ...state,
                albumComments: {
                    next: action.payload.data.next,
                    results: action.payload.config.isPaginate
                        ? [
                            ...state.albumComments.results,
                            ...action.payload.data.results
                        ]
                        : [
                            ...action.payload.data.results
                        ]
                }
            };
        case AUDIO.COMMENT_DELETE_SUCCESS:
            if (action.payload.config.type === 'track') {
                let tempArr = indexWalk(state.trackComments.results, action.payload.config.indexes, 'children')
                return {
                    ...state,
                    trackStatistic: {
                        ...state.trackStatistic,
                        comments_count: state.trackStatistic.comments_count - 1
                    },
                    trackComments: {
                        ...state.trackComments,
                        results: [...tempArr]
                    }
                }
            } else {
                let tempArr = indexWalk(state.albumComments.results, action.payload.config.indexes, 'children')
                return {
                    ...state,
                    albumStatistic: {
                        ...state.albumStatistic,
                        comments_count: state.albumStatistic.comments_count - 1
                    },
                    albumComments: {
                        ...state.albumComments,
                        results: [...tempArr]
                    }
                }
            }
        case AUDIO.GET_ALBUM_TRACKS_SUCCESS:
            return { ...state, albumTracks: action.payload.data }

        default:
            return state;
    }
}
