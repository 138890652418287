import { APP } from './appActionTypes';

export function closeErrorSnack() {
  return {
    type: APP.ERROR_SNACK_CLOSE,
  };
}

export const customBack = (url) => ({
  type: APP.CUSTOM_BACK,
  payload: {
    data: url
  }
})