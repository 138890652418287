import { ReactComponent as DashboardActive } from './icons/dashboard_active.svg'
import { ReactComponent as Dashboard } from './icons/dashboard.svg'
import { ReactComponent as UsersActive } from './icons/users_active.svg'
import { ReactComponent as Users } from './icons/users.svg'
import { ReactComponent as AudioActive } from './icons/audio_active.svg'
import { ReactComponent as Audio } from './icons/audio.svg'
import { ReactComponent as FinancesActive } from './icons/finances_active.svg'
import { ReactComponent as Finances } from './icons/finances.svg'
import { ReactComponent as Advertising } from './icons/advertising.svg'
import { ReactComponent as AdvertisingActive } from './icons/advertising_active.svg'
import { ReactComponent as SupportActive } from './icons/support_active.svg'
import { ReactComponent as Support } from './icons/support.svg'
import { ReactComponent as PagesContent } from './icons/pages_content.svg'
import { ReactComponent as PagesContentActive } from './icons/pages_content_active.svg'

import DashboardComponent from '../features/Dashboard'
import AudioComponent from '../features/Audio'
import UsersComponent from '../features/Users'
import FinancesComponent from '../features/Finances'
import AdvertisingComponent from '../features/Advertising'
import SupportComponent from '../features/Support'
import Track from '../features/Audio/Track'
import Album from '../features/Audio/Album'
import AdvertiserEdit from '../features/Advertising/AdvertiserEdit'
import AdvertisingInner from '../features/Advertising/AdvertisingInner'
import CampaignEdit from '../features/Advertising/CampaignEdit'
import CampaignInner from '../features/Advertising/CampaignInner'
import UserInner from '../features/Users/UserInner'
import PagesContentComponent from '../features/PagesContent'
import PagesContentInnerComponent from '../features/PagesContent/PagesContentInner'
import PaymentHistory from '../features/Finances/PaymentHistory'
import PaymentHistoryInner from '../features/Finances/PaymentHistoryInner'

export const rootAuthPath = '/auth';
export const rootMainPath = '/main';

export const authPath = {
  signIn: { path: `${rootAuthPath}/sign-in`, name: 'Sign in' },
  signUp: { path: `${rootAuthPath}/sign-up`, name: 'Sign up' },
  passRecovery: { path: `${rootAuthPath}/password-recovery`, name: 'Password recovery' },
  passConfirm: { path: `${rootAuthPath}/password-confirm`, name: 'Reset password' }
};

let paths = {
  dashboard: { path: `${rootMainPath}/dashboard`, name: 'Dashboard', isNavItem: true, comingSoon: true, icon: <Dashboard />, iconActive: <DashboardActive />, component: DashboardComponent },
  users: { path: `${rootMainPath}/users`, name: 'Users', isNavItem: true, comingSoon: false, icon: <Users />, iconActive: <UsersActive />, component: UsersComponent },
  audio: { path: `${rootMainPath}/audio`, name: 'Audio', isNavItem: true, comingSoon: false, icon: <Audio />, iconActive: <AudioActive />, component: AudioComponent },
  pagesContent: { path: `${rootMainPath}/pages-content`, name: 'Pages content', isNavItem: true, comingSoon: false, icon: <PagesContent />, iconActive: <PagesContentActive />, component: PagesContentComponent },
  finances: { path: `${rootMainPath}/finances`, name: 'Finances', isNavItem: true, comingSoon: false, icon: <Finances />, iconActive: <FinancesActive />, component: FinancesComponent },
  advertising: { path: `${rootMainPath}/advertising`, name: 'Advertising', isNavItem: true, comingSoon: false, icon: <Advertising />, iconActive: <AdvertisingActive />, component: AdvertisingComponent },
  support: { path: `${rootMainPath}/support`, name: 'Support', isNavItem: true, comingSoon: false, icon: <Support />, iconActive: <SupportActive />, component: SupportComponent },
};
paths.audioInner = { path: `${rootMainPath}/audio/track/:trackId`, component: Track, parent: paths.audio }
paths.albumInner = { path: `${rootMainPath}/audio/album/:albumId`, component: Album, parent: paths.audio }

paths.advertiserEdit = { path: `${rootMainPath}/advertising/:advertiserId/edit`, component: AdvertiserEdit, parent: paths.advertising }
paths.advertiserNew = { path: `${rootMainPath}/advertising/new`, component: AdvertiserEdit, parent: paths.advertising }
paths.advertiserInner = { path: `${rootMainPath}/advertising/:advertiserId`, component: AdvertisingInner, parent: paths.advertising, name: 'Advertiser', exact: true }
paths.advertiserInnerEdit = { path: `${rootMainPath}/advertising/:advertiserId/inner-edit`, component: AdvertiserEdit, parent: paths.advertiserInner, exact: true }
paths.advertiserCampaignNew = { path: `${rootMainPath}/advertising/:advertiserId/campaign/new`, component: CampaignEdit, parent: paths.advertiserInner, exact: true }
paths.advertiserCampaignEdit = { path: `${rootMainPath}/advertising/:advertiserId/campaign/:campaignId/edit`, component: CampaignEdit, parent: paths.advertiserInner, exact: true }
paths.advertiserCampaignRestore = { path: `${rootMainPath}/advertising/:advertiserId/campaign/:campaignId/restore`, component: CampaignEdit, parent: paths.advertiserInner, exact: true }
paths.advertiserCampaignInner = { path: `${rootMainPath}/advertising/:advertiserId/campaign/:campaignId`, component: CampaignInner, parent: paths.advertiserInner, exact: true }

paths.userInner = { path: `${rootMainPath}/users/:userId`, component: UserInner, parent: paths.users }

paths.pagesContentInner = { path: `${paths.pagesContent.path}/:pageType/:pageID/:pagePeriod`, component: PagesContentInnerComponent, parent: paths.pagesContent }

paths.financesPaymentHistory = { path: `${paths.finances.path}/history`, name: 'Payment history', component: PaymentHistory, parent: paths.finances, exact: true }
paths.financesPaymentHistoryInner = { path: `${paths.financesPaymentHistory.path}/:historyID`, component: PaymentHistoryInner, parent: paths.financesPaymentHistory, exact: true }

export const mainPath = paths