import { useEffect, useState } from "react";
import { ButtonMUI, CheckboxMUI, CustomMultiSelect, DatePickerMUI, DialogMUI, InputMUI, SelectComponent } from "../../shared";
import styles from './Audio.module.scss'
import { ReactComponent as MoreFilterIcon } from '../../assets/icons/more_filters_icon.svg'
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const AdditionalFilters = ({ genreOptions, emotionsOptions, ageOptions, parameters, setParameters, initialParameters }) => {
    const [open, setOpen] = useState(initialOpenState)
    const [visible, setVisible] = useState(initialOpenState)
    const [tempParams, setTempParams] = useState(parameters)

    useEffect(() => {
        setTempParams(parameters)
    }, [parameters, open])

    const paramsSetter = (key, value) => {
        setTempParams((prev) => ({ ...prev, [key]: value }))
    }

    const applyFilters = () => {
        setParameters(tempParams)
        setVisible(false)
    }

    return <>
        <ButtonMUI
            variant='text'
            className={styles.moreFiltersButton}
            onClick={() => setOpen(true)}
        >
            <MoreFilterIcon /> More filters
        </ButtonMUI>
        <DialogMUI
            isSideBar
            open={open}
            setOpen={setOpen}
            visible={visible}
            setVisible={setVisible}
            title={'Filters'}
        >
            <div className={styles.dialog_wrapper}>
                <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Price</p>
                    <div className={styles.inputLabel__wrapper}>
                        <InputMUI
                            className={styles.search}
                            id='price_min'
                            type='text'
                            placeholder='0.00'
                            startAdornment={<span className={[styles.adornment__start, styles.adornment].join(' ')}>Min</span>}
                            endAdornment={<span className={[styles.adornment__end, styles.adornment].join(' ')}>$</span>}
                            value={tempParams.price_min}
                            onChange={({ target: { value } }) => paramsSetter('price_min', value)}
                        />
                        <InputMUI
                            className={styles.search}
                            id='price_max'
                            type='text'
                            placeholder='0.00'
                            startAdornment={<span className={[styles.adornment__start, styles.adornment].join(' ')}>Max</span>}
                            endAdornment={<span className={[styles.adornment__end, styles.adornment].join(' ')}>$</span>}
                            value={tempParams.price_max}
                            onChange={({ target: { value } }) => paramsSetter('price_max', value)}
                        />
                    </div>
                </div>
                <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Release date</p>
                    <div className={styles.inputLabel__wrapper}>
                        <DatePickerMUI
                            InputProps={{ startAdornment: <span className={[styles.adornment__start, styles.adornment].join(' ')}>From</span> }}
                            value={tempParams.from_date}
                            onChange={(e, formatted) => {
                                if (!!e && !isNaN(new Date(e.$d))) {
                                    paramsSetter('from_date', dayjs(e.$d).format('YYYY-MM-DD'))
                                } else {
                                    if (tempParams.from_date !== '') paramsSetter('from_date', '')
                                }
                            }}
                            placeholder={'dd/mm/yyyy'}
                        />
                        <DatePickerMUI
                            InputProps={{ startAdornment: <span className={[styles.adornment__start, styles.adornment].join(' ')}>To</span> }}
                            value={tempParams.to_date}
                            onChange={(e, formatted) => {
                                if (!!e && !isNaN(new Date(e.$d))) {
                                    paramsSetter('to_date', dayjs(e.$d).format('YYYY-MM-DD'))
                                } else {
                                    if (tempParams.to_date !== '') paramsSetter('to_date', '')
                                }
                            }}
                            placeholder={'dd/mm/yyyy'}
                        />
                    </div>
                </div>
                <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Genre</p>
                    <div className={styles.inputLabel__wrapper}>
                        <CustomMultiSelect
                            values={tempParams.genres.map(el => genreOptions.find(elem => elem.id === el))}
                            options={genreOptions}
                            tagSelect
                            placeholderSearch='Search...'
                            placeholder='Select genre(s)'
                            setter={e => paramsSetter('genres', e.map(el => el.id))}
                            withSearch
                            isList
                        />
                    </div>
                </div>
                <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Emotions</p>
                    <div className={styles.inputLabel__wrapper}>
                        <CustomMultiSelect
                            values={tempParams.emotions.map(el => emotionsOptions.find(elem => elem.id === el))}
                            options={emotionsOptions}
                            tagSelect
                            placeholderSearch='Search...'
                            placeholder='Select emotion(s)'
                            setter={e => paramsSetter('emotions', e.map(el => el.id))}
                            withSearch
                        />
                    </div>
                </div>
                <label className={[styles.inputLabel, styles.inline, styles.click].join(' ')}>
                    <CheckboxMUI
                        checked={tempParams.version === 1}
                        onChange={() => paramsSetter('version', tempParams.version === 1 ? null : 1)}
                    />
                    <p className={styles.inputLabel__title}>Explicit content</p>
                </label>
                <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Minimum age</p>
                    <div className={styles.inputLabel__wrapper}>
                        <CustomMultiSelect
                            values={tempParams.age.map(el => ageOptions.find(elem => elem.id === el))}
                            options={ageOptions}
                            tagSelect
                            placeholder='Select option(s)'
                            setter={e => paramsSetter('age', e.map(el => el.id))}
                            isList
                        />
                    </div>
                </div>
                <div className={styles.separator} />
                <div className={styles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        className={styles.applyFilters}
                        size="small"
                        onClick={applyFilters}
                    >
                        Apply filters
                    </ButtonMUI>
                    <ButtonMUI
                        variant='text'
                        className={styles.clearFilters}
                        onClick={() => setTempParams(initialParameters)}
                    >
                        <ResetIcon /> Clear all
                    </ButtonMUI>
                </div>
            </div>
        </DialogMUI>
    </>
}

const initialOpenState = false;

export default AdditionalFilters;