export const AUDIO = {
    GET_TRACKS: 'GET_TRACKS',
    GET_TRACKS_SUCCESS: 'GET_TRACKS_SUCCESS',
    GET_TRACKS_FAIL: 'GET_TRACKS_FAIL',

    GET_ALBUMS: 'GET_ALBUMS',
    GET_ALBUMS_SUCCESS: 'GET_ALBUMS_SUCCESS',
    GET_ALBUMS_FAIL: 'GET_ALBUMS_FAIL',

    TRACKS_BLOCK: 'TRACKS_BLOCK',
    TRACKS_BLOCK_SUCCESS: 'TRACKS_BLOCK_SUCCESS',
    TRACKS_BLOCK_FAIL: 'TRACKS_BLOCK_FAIL',

    ALBUMS_BLOCK: 'ALBUMS_BLOCK',
    ALBUMS_BLOCK_SUCCESS: 'ALBUMS_BLOCK_SUCCESS',
    ALBUMS_BLOCK_FAIL: 'ALBUMS_BLOCK_FAIL',

    TRACKS_DELETE: 'TRACKS_DELETE',
    TRACKS_DELETE_SUCCESS: 'TRACKS_DELETE_SUCCESS',
    TRACKS_DELETE_FAIL: 'TRACKS_DELETE_FAIL',

    ALBUMS_DELETE: 'ALBUMS_DELETE',
    ALBUMS_DELETE_SUCCESS: 'ALBUMS_DELETE_SUCCESS',
    ALBUMS_DELETE_FAIL: 'ALBUMS_DELETE_FAIL',

    COMMENT_DELETE: 'COMMENT_DELETE',
    COMMENT_DELETE_SUCCESS: 'COMMENT_DELETE_SUCCESS',
    COMMENT_DELETE_FAIL: 'COMMENT_DELETE_FAIL',

    GET_EMOTIONS: 'GET_EMOTIONS',
    GET_EMOTIONS_SUCCESS: 'GET_EMOTIONS_SUCCESS',
    GET_EMOTIONS_FAIL: 'GET_EMOTIONS_FAIL',

    GET_GENRE: 'GET_GENRE',
    GET_GENRE_SUCCESS: 'GET_GENRE_SUCCESS',
    GET_GENRE_FAIL: 'GET_GENRE_FAIL',

    GET_TRACK_INFO: 'GET_TRACK_INFO',
    GET_TRACK_INFO_SUCCESS: 'GET_TRACK_INFO_SUCCESS',
    GET_TRACK_INFO_FAIL: 'GET_TRACK_INFO_FAIL',

    GET_ALBUM_INFO: 'GET_ALBUM_INFO',
    GET_ALBUM_INFO_SUCCESS: 'GET_ALBUM_INFO_SUCCESS',
    GET_ALBUM_INFO_FAIL: 'GET_ALBUM_INFO_FAIL',

    GET_TRACK_STATISTIC: 'GET_TRACK_STATISTIC',
    GET_TRACK_STATISTIC_SUCCESS: 'GET_TRACK_STATISTIC_SUCCESS',
    GET_TRACK_STATISTIC_FAIL: 'GET_TRACK_STATISTIC_FAIL',

    GET_ALBUM_STATISTIC: 'GET_ALBUM_STATISTIC',
    GET_ALBUM_STATISTIC_SUCCESS: 'GET_ALBUM_STATISTIC_SUCCESS',
    GET_ALBUM_STATISTIC_FAIL: 'GET_ALBUM_STATISTIC_FAIL',

    GET_TRACK_COMMENTS: 'GET_TRACK_COMMENTS',
    GET_TRACK_COMMENTS_SUCCESS: 'GET_TRACK_COMMENTS_SUCCESS',
    GET_TRACK_COMMENTS_FAIL: 'GET_TRACK_COMMENTS_FAIL',

    GET_ALBUM_COMMENTS: 'GET_ALBUM_COMMENTS',
    GET_ALBUM_COMMENTS_SUCCESS: 'GET_ALBUM_COMMENTS_SUCCESS',
    GET_ALBUM_COMMENTS_FAIL: 'GET_ALBUM_COMMENTS_FAIL',

    GET_ALBUM_TRACKS: 'GET_ALBUM_TRACKS',
    GET_ALBUM_TRACKS_SUCCESS: 'GET_ALBUM_TRACKS_SUCCESS',
    GET_ALBUM_TRACKS_FAIL: 'GET_ALBUM_TRACKS_FAIL',

    UPLOAD_TRACK: 'UPLOAD_TRACK',
    UPLOAD_TRACK_SUCCESS: 'UPLOAD_TRACK_SUCCESS',
    UPLOAD_TRACK_FAIL: 'UPLOAD_TRACK_FAIL',

    PROCESS_UPLOADED: 'PROCESS_UPLOADED',
    PROCESS_UPLOADED_SUCCESS: 'PROCESS_UPLOADED_SUCCESS',
    PROCESS_UPLOADED_FAIL: 'PROCESS_UPLOADED_FAIL',
};
