import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import styles from './PagesContent.module.scss'
import additionalStyles from '../Advertising/Advertising.module.scss';
import parentStyles from '../Audio/Audio.module.scss';
import { classList, prettyScroll } from '../../helpers/functions';
import { ButtonMUI, SwitchMUI, TabItem, Tabs } from '../../shared';
import { getContentInner, setContentInner } from './storage/pagesContentActions';
import { ReactComponent as Globus } from '../../assets/icons/globus.svg'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import TrackList from './TrackList';
import { StopEditingModal } from './Modals';

const PagesContentInner = () => {
    const { pageType, pageID, pagePeriod } = useParams()
    const isCountriesPageType = pageType === 'countries';
    const { innerContent, initialInnerContent } = useSelector(({ pagesContent }) => pagesContent)
    const dispatch = useDispatch()
    const history = useHistory()
    const [localIsAuto, setLocalIsAuto] = useState(null)
    const [localIsAutoInitial, setLocalIsAutoInitial] = useState(null)
    const [selectedTracks, setSelectedTracks] = useState([])
    const [btnLoad, setBtnLoad] = useState(false)

    const [confirmLeavePage, setConfirmLeavePage] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [nextPathName, setNextPathName] = useState(false);

    const exit = () => {
        setConfirmLeavePage(true);
    };

    const onCloseExitModal = () => {
        setShowExitModal(false);
    };

    const onLeavePage = ({ pathname }) => {
        if (!confirmLeavePage && `!${[localIsAutoInitial, ...initialInnerContent].join(', ').replaceAll(' ', '')}!` !== `!${[localIsAuto, ...innerContent.tracks.map(el => el.id)].join(', ').replaceAll(' ', '')}!`) {
            setNextPathName(pathname);
            setShowExitModal(true);
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (confirmLeavePage) {
            history.push(nextPathName)
        }
    }, [confirmLeavePage])

    const setPeriod = period => {
        history.push(`${history.location.pathname.split('/').slice(0, history.location.pathname.split('/').length - 1).join('/')}/${period}`)
    }

    const save = () => {
        setBtnLoad(true)
        dispatch(setContentInner(pageType, pageID, pagePeriod, localIsAuto, localIsAuto ? undefined : innerContent.tracks.map(el => el.id), innerContent.name))
            .then(res => {
                setLocalIsAutoInitial(localIsAuto)
                setBtnLoad(false)
            })
    }

    useEffect(() => {
        dispatch(getContentInner(pageType, pageID, pagePeriod)).then(res => {
            if (res.type.includes('_SUCCESS')) {
                setLocalIsAuto(res.payload.data.auto)
                setLocalIsAutoInitial(res.payload.data.auto)
                if (!res.payload.data.auto_list) setSelectedTracks(res.payload.data.tracks)
            }
        })
        setConfirmLeavePage(false)
        setShowExitModal(false)
        setSelectedTracks([])
    }, [pageType, pageID, pagePeriod])

    return <main className={classList(additionalStyles.advertising, additionalStyles.advertisingEdit, 'page-wrap')} ref={prettyScroll}>
        <Prompt when={!confirmLeavePage} message={onLeavePage} />
        <StopEditingModal open={showExitModal} onClose={onCloseExitModal} exit={exit} />
        <div className={classList(additionalStyles.advertisingInner, 'container')}>
            <div className='row just-sb al-c'>
                <h1 className={styles.nameWrapper}>
                    Top songs: {
                        isCountriesPageType && (
                            <div>
                                {pageID === '00'
                                    ? <Globus />
                                    : <span className={`fi fi-${pageID.toLowerCase()} fis`} />
                                }
                            </div>)
                    }
                    <span className='capitalize'>{innerContent.name}</span>
                </h1>
                <ButtonMUI
                    size='small'
                    className={classList(parentStyles.actionButtons, styles.saveBtn)}
                    onClick={save}
                    loading={btnLoad}
                >
                    Save
                </ButtonMUI>
            </div>
            <div className={additionalStyles.sepatrator} />
            <div className={styles.tabWrap}>
                <Tabs className={styles.tabs} defaultIndex={pagePeriod} onTabClick={setPeriod} withoutInitialChangeIndex={pagePeriod}>
                    <TabItem label='Week' index='weekly' />
                    <TabItem label='Month' index='monthly' />
                    <TabItem label='Year' index='yearly' />
                </Tabs>
            </div>
            <div className={classList('inner-wrap', styles['inner-wrap'], styles.autoSwitcherWrapper)}>
                <div className={styles.autoSwitcherInfo}>
                    <p>Automatic mode</p>
                    <span>By default, the top songs ranking is formed automatically based on user listening.
                        Disable this mode to manually add and order up to 50 tracks to form this ranking.</span>
                </div>
                <SwitchMUI
                    checked={localIsAuto || false}
                    onChange={e => {
                        dispatch(getContentInner(pageType, pageID, pagePeriod, e.target.checked))
                            .then(res => {
                                if (res.type.includes('_SUCCESS')) {
                                    setLocalIsAuto(res.payload.data.auto_list)
                                }
                            })
                    }}
                />
            </div>
            <TrackList
                selectedTracks={selectedTracks}
                setSelectedTracks={setSelectedTracks}
            />
        </div>
    </main>
}

export default PagesContentInner