import { AUDIO } from './audioActionTypes';

export function getAudioContent(type, url) {
    return {
        type: AUDIO[`GET_${type.toUpperCase()}`],
        payload: {
            client: 'default',
            request: {
                url: `admin/music/${type}${!!url && url.length > 0 ? url : ''}`,
                method: 'GET',
            },
        },
    };
}

export function blockAudioAction(type, id, data, isInner, isAlbumTrack) {
    return {
        type: AUDIO[`${type.toUpperCase()}_BLOCK`],
        payload: {
            client: 'default',
            request: {
                url: `admin/music/${type}/${id}/blocked`,
                id,
                isInner,
                isAlbumTrack,
                method: 'PUT',
                data
            },
        },
    };
}

export function deleteAudioAction(type, id, isInner, isAlbumTrack) {
    return {
        type: AUDIO[`${type.toUpperCase()}_DELETE`],
        payload: {
            client: 'default',
            request: {
                url: `admin/music/${type}/${id}`,
                id,
                isInner,
                isAlbumTrack,
                method: 'DELETE'
            },
        },
    };
}

export function deleteCommentAction(id, indexes, type) {
    return {
        type: AUDIO.COMMENT_DELETE,
        payload: {
            client: 'default',
            request: {
                url: `admin/newsfeed/${type}s/comments/${id}`,
                indexes,
                type,
                method: 'DELETE'
            },
        },
    };
}

export function getEmotions(search = '') {
    return {
        type: AUDIO.GET_EMOTIONS,
        client: 'default',
        payload: {
            request: {
                url: `admin/reactions/emotions${search ? `?search=${search}` : ''}`,
                method: 'get'
            }
        }
    };
}

export function getGenre(query) {
    return {
        type: AUDIO.GET_GENRE,
        client: 'default',
        payload: {
            request: {
                url: `admin/music/genres${query ? query : ''}`,
                method: 'get'
            }
        }
    };
}

export function getAudioInfo(type, id) {
    return {
        type: AUDIO[`GET_${type.toUpperCase()}_INFO`],
        client: 'default',
        payload: {
            request: {
                url: `admin/music/${type}s/${id}`,
                method: 'get'
            }
        }
    };
}

export function getAudioStatistic(type, id) {
    return {
        type: AUDIO[`GET_${type.toUpperCase()}_STATISTIC`],
        client: 'default',
        payload: {
            request: {
                url: `admin/music/${type}s/${id}/statistic`,
                method: 'get'
            }
        }
    };
}

export function getAudioComments(type, id, cursor) {
    return {
        type: AUDIO[`GET_${type.toUpperCase()}_COMMENTS`],
        client: 'default',
        payload: {
            request: {
                url: `admin/newsfeed/${type}s/${id}/comments${!!cursor ? `?cursor=${cursor}` : ''}`,
                isPaginate: !!cursor,
                method: 'get'
            }
        }
    };
}

export function getAlbumTracks(id) {
    return {
        type: AUDIO.GET_ALBUM_TRACKS,
        client: 'default',
        payload: {
            request: {
                url: `admin/music/album/${id}/tracks`,
                method: 'get'
            }
        }
    };
}

export function uploadTrack(data, cancelToken) {
    return {
        type: AUDIO.UPLOAD_TRACK,
        client: 'default',
        payload: {
            request: {
                url: `admin/music/upload-track`,
                method: 'post',
                data,
                cancelToken
            }
        }
    };
}


export function processUploaded() {
    return {
        type: AUDIO.PROCESS_UPLOADED,
        client: 'default',
        payload: {
            request: {
                url: `admin/music/process-uploaded-tracks`,
                method: 'post',
            }
        }
    };
}