import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styles from './Advertising.module.scss'
import additionalStyles from '../Audio/Audio.module.scss';
import { classList, prettyScroll } from '../../helpers/functions';
import { ButtonMUI, DatePickerMUI, InputMUI, LoaderMUI, SelectComponent, SimpleErrorMessage } from '../../shared';
import { getAdvertiser, campaignAction, editCampaignAction } from './storage/advertisingActions';
import { CircularProgress } from '@material-ui/core';
import { customBack } from '../../app/appActions';

import { ReactComponent as InputFile } from '../../assets/icons/inputFile.svg';
import { ReactComponent as FileIco } from '../../assets/icons/file.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_uploaded_image.svg';
import { ageOptions } from '../Audio/Audio';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const CampaignEdit = () => {
    const { advertiserId, campaignId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const pageType = history.location.pathname.split('/')[history.location.pathname.split('/').length - 1]
    const { editCampaign, editCampaign_loading } = useSelector(({ advertising }) => advertising)
    const [btnLoad, setBtnLoad] = useState(false)
    const [errors, setErrors] = useState({})
    const [inputFileLoading, setFileLoading] = useState(false);

    useEffect(() => {
        dispatch(customBack(`/main/advertising/${advertiserId}`))
        if (pageType !== 'new') dispatch(campaignAction('GET', campaignId))
    }, [])

    const submitForm = () => {
        setBtnLoad(true)
        let formData = new FormData();
        Object.entries(editCampaign).forEach(([key, value]) => formData.append(key, value));
        if (typeof formData.get('image') === 'string') formData.delete('image')
        dispatch(campaignAction(`${pageType === 'new' ? 'POST' : 'PUT'}`, pageType === 'new' ? advertiserId : campaignId, formData, pageType === 'restore')).then(({ type, error }) => {
            if (type.includes('_SUCCESS')) history.push(`/main/advertising/${advertiserId}`)
            else {
                setErrors(prev => ({ ...prev, ...error.response.data }))
                setBtnLoad(false)
            }
        })
    }

    const toggleClassOnHover = ({ target }) => {
        target.previousElementSibling.classList.toggle(styles.hover)
    }

    const dropToInput = ({ target }) => {
        setFileLoading(!target.files[0])
        toggleClassOnHover({ target });
    }

    const inputFileChange = ({ target }) => {
        if (target.files.length > 0) {
            setTimeout(() => {
                setErrors(prev => ({ ...prev, image: '' }))
                setFileLoading(!target.files[0])
            }, 500)
            dispatch(editCampaignAction({ image: target.files[0] }))
        }
    }

    const renderFileInput = () => {
        return <label
            htmlFor={'hiddenFileInput'}
            id={'hiddenFileInputLabel'}
            className={styles.hiddenFileInputLabel}
        >
            <div className={[
                styles.inputStylesBlock,
                inputFileLoading ? styles.loading : '',
                editCampaign.image ? styles.loaded : ''
            ].join(' ')}>
                {inputFileLoading
                    ? <LoaderMUI />
                    : editCampaign.image === null
                        ? <>
                            <InputFile />
                            <p>Upload image</p>
                        </>
                        : <>
                            <img id="uploaded_image" src={(() => typeof editCampaign.image === 'string' ? editCampaign.image : URL.createObjectURL(editCampaign.image))()} alt="uploaded image" />
                            <button onClick={(e) => {
                                e.preventDefault();
                                dispatch(editCampaignAction({ image: null }))
                            }} className={`good-hover`}><DeleteIcon /></button>
                        </>
                }
            </div>
            <input
                key={editCampaign.image}
                type="file"
                id={'hiddenFileInput'}
                accept=".jpg, .doc, .png, .pdf"
                onChange={e => inputFileChange(e)}
                onDrop={e => dropToInput(e)}
                onDragEnter={e => toggleClassOnHover(e)}
                onDragLeave={e => toggleClassOnHover(e)}
            />
        </label>
    }

    const fieldsConfig = [
        {
            name: 'Campaign cover',
            desc: 'This is optional. File types supported: JPG, PNG. 480 x 480 recommended.',
            inputs: [
                {
                    key: 'image',
                    width: 'full',
                    placeholder: 'Enter the bank details',
                    type: 'file',
                    render: () => renderFileInput()
                }
            ]
        },
        {
            name: 'Campaign details',
            inputs: [
                {
                    name: 'Name',
                    key: 'name',
                    width: 'full',
                    placeholder: 'Enter the campaign name',
                },
                {
                    name: 'Description',
                    key: 'description',
                    width: 'full',
                    placeholder: 'Enter the campaign details',
                    type: 'textarea'
                },
                {
                    name: 'Agreement date',
                    key: 'agreement_date',
                    width: 'third',
                    render: () => <DatePickerMUI
                        value={editCampaign.agreement_date}
                        onChange={e => dispatch(editCampaignAction({ agreement_date: dayjs(e.$d).format('YYYY-MM-DD') }))}
                        placeholder={'dd/mm/yyyy'}
                    />
                },
                {
                    name: 'Start date',
                    key: 'start_date',
                    width: 'third',
                    render: () => <DatePickerMUI
                        value={editCampaign.start_date}
                        onChange={e => dispatch(editCampaignAction({ start_date: dayjs(e.$d).format('YYYY-MM-DD') }))}
                        placeholder={'dd/mm/yyyy'}
                    />
                },
                {
                    name: 'End date',
                    key: 'end_date',
                    width: 'third',
                    render: () => <DatePickerMUI
                        value={editCampaign.end_date}
                        onChange={e => dispatch(editCampaignAction({ end_date: dayjs(e.$d).format('YYYY-MM-DD') }))}
                        placeholder={'dd/mm/yyyy'}
                    />
                },
                {
                    name: 'Budget',
                    key: 'budget',
                    width: 'third',
                    render: () => <InputMUI
                        className={additionalStyles.search}
                        type='text'
                        placeholder='0.00'
                        endAdornment={<span className={classList(additionalStyles.adornment__end, additionalStyles.adornment, additionalStyles.adornment__wide)}>USD</span>}
                        value={editCampaign.budget}
                        onChange={({ target: { value } }) => dispatch(editCampaignAction({ budget: value }))}
                    />
                },
                { key: 'separator', width: 'third' },
                {
                    name: 'Audience age',
                    key: 'age',
                    width: 'half',
                    render: () => <SelectComponent
                        options={ageOptions}
                        value={ageOptions.find(el => el.value === editCampaign.age)}
                        placeholder={'Select option(s)'}
                        onChange={e => dispatch(editCampaignAction({ age: e.value }))}
                        size={'big'}
                        fullWidth
                    />
                }
            ]
        }
    ]

    return <main className={classList(styles.advertising, styles.advertisingEdit, 'page-wrap')} ref={prettyScroll}>
        <div className={classList(styles.advertisingInner, 'container')}>
            <h1><span className='capitalize'>{pageType}</span> campaign</h1>
            <div className={styles.sepatrator} />
            {fieldsConfig.map((el, index) => <div key={index} className={classList('inner-wrap', styles['inner-wrap'], { [styles.loading]: pageType !== 'new' && editCampaign_loading })}>
                {(!!el.name || !!el.desc) &&
                    <div>{!!el.name && <p className={styles.inputLabel__blockTitle}>{el.name}</p>}
                        {!!el.desc && <p className={styles.inputLabel__blockDesc}>{el.desc}</p>}
                    </div>
                }
                {pageType !== 'new' && editCampaign_loading
                    ? <CircularProgress />
                    : el.inputs.map(({ name, key, width, placeholder, type, render }, idx) => <div key={idx} className={classList(additionalStyles.inputLabel, styles.inputLabel, styles[width])}>
                        {key !== 'separator' && <>
                            {!!name && <p className={additionalStyles.inputLabel__title}>{name}</p>}
                            <div className={classList(additionalStyles.inputLabel__wrapper, styles.inputLabel__wrapper)}>
                                {!!render
                                    ? <>
                                        {render()}
                                        {errors[key] && <SimpleErrorMessage messages={errors[key]} />}
                                    </>
                                    : <InputMUI
                                        id={key}
                                        type={!!type ? type : 'text'}
                                        placeholder={placeholder}
                                        value={editCampaign[key]}
                                        onChange={({ target: { value } }) => {
                                            setErrors(prev => ({ ...prev, [key]: '' }))
                                            dispatch(editCampaignAction({ [key]: value }))
                                        }}
                                        multiline={type === 'textarea'}
                                        minRows={type === 'textarea' ? 5 : 1}
                                        error={errors[key] && errors[key].join('; ')}//{editCampaign[key].length < 1 && `"${name}" may not be blank`}
                                    />}
                            </div>
                        </>}
                    </div>)
                }
            </div>
            )}
            <div className={styles.sepatrator} />
            <ButtonMUI
                onClick={submitForm}
                loading={btnLoad}
                disabled={btnLoad || Object.values(errors).some(el => !!el)}//|| Object.values(editCampaign).some(el => el.length < 1)
            >
                {pageType === 'new'
                    ? 'Create'
                    : pageType === 'edit'
                        ? 'Save changes'
                        : pageType === 'restore'
                            ? 'Restore'
                            : 'Done' //backdoor
                }
            </ButtonMUI>
        </div>
    </main>
}

export default CampaignEdit