import styles from './StatisticSidebar.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Plays } from '../../../assets/icons/plays.svg'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { classList } from '../../../helpers/functions';
import { getAdvertiserStatistic } from '../storage/advertisingActions';
dayjs.extend(utc)

const StatisticSidebar = ({ advertiserId, isCampaign = false }) => {
    const dispatch = useDispatch();
    const { advertiserStatistic } = useSelector(({ advertising }) => advertising)

    useEffect(() => {
        dispatch(getAdvertiserStatistic(advertiserId, isCampaign))
    }, [advertiserId])

    const renderFields = () => {
        let arr = [
            {
                title: 'Plays',
                icon: <Plays />,
                value: advertiserStatistic.listen_count,
            },
            {
                title: 'Listening time',
                icon: <Clock />,
                value: advertiserStatistic.listen_time,
            }
        ]

        return arr.map(({ title, icon, value }, idx) => <div key={idx} className={styles.cell}>
            <div>{icon}<span>{title}</span></div>
            <div><p>{value}</p></div>
        </div>)
    }

    return <div className={styles.sidebar}>
        {renderFields()}
    </div>
}

export default StatisticSidebar