import { useEffect, useState } from "react";
import { ButtonMUI, CheckboxMUI, CustomMultiSelect, DatePickerMUI, DialogMUI, InputMUI, SelectComponent } from "../../shared";
import styles from '../Audio/Audio.module.scss'
import localStyles from './PagesContent.module.scss'
import { ReactComponent as MoreFilterIcon } from '../../assets/icons/more_filters_icon.svg'
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg'
import { RadioMUI } from "../../shared";
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";

const AdditionalFilters = ({ tab, parameters, setParameters, initialParameters }) => {
    const [open, setOpen] = useState(initialOpenState)
    const [visible, setVisible] = useState(initialOpenState)
    const [tempParams, setTempParams] = useState(parameters)

    useEffect(() => {
        setTempParams(parameters)
    }, [parameters, open])

    const paramsSetter = (key, value) => {
        setTempParams((prev) => ({ ...prev, [key]: value }))
    }

    const applyFilters = () => {
        setParameters(tempParams)
        setVisible(false)
    }

    return <>
        <ButtonMUI
            variant='text'
            className={styles.moreFiltersButton}
            onClick={() => setOpen(true)}
        >
            <MoreFilterIcon /> More filters
        </ButtonMUI>
        <DialogMUI
            isSideBar
            open={open}
            setOpen={setOpen}
            visible={visible}
            setVisible={setVisible}
            title={'Filters'}
        >
            <div className={styles.dialog_wrapper}>
                {tab === 'genres' && <div className={styles.inputLabel}>
                    <p className={styles.inputLabel__title}>Category</p>
                    <div className={styles.inputLabel__wrapper}>
                        <RadioGroup
                            aria-label={`non_music`}
                            name={`non_music`}
                            value={`${tempParams[`non_music`]}`}
                            onChange={e => paramsSetter(`non_music`, e.target.value)}
                            className={localStyles.radioGroup}
                        >
                            <FormControlLabel value={'null'} control={<RadioMUI />} label="Both categories" />
                            <FormControlLabel value={'false'} control={<RadioMUI />} label="Music" />
                            <FormControlLabel value={'true'} control={<RadioMUI />} label="Non-music" />
                        </RadioGroup>
                    </div>
                </div>}
                {['Week', 'Month', 'Year'].map((el, idx) => (
                    <div className={styles.inputLabel} key={idx}>
                        <p className={styles.inputLabel__title}>Top for {el}</p>
                        <div className={styles.inputLabel__wrapper}>
                            <RadioGroup
                                aria-label={`${el.toLowerCase()}ly_chart_auto`}
                                name={`${el.toLowerCase()}ly_chart_auto`}
                                value={`${tempParams[`${el.toLowerCase()}ly_chart_auto`]}`}
                                onChange={e => paramsSetter(`${el.toLowerCase()}ly_chart_auto`, e.target.value)}
                                className={localStyles.radioGroup}
                            >
                                <FormControlLabel value={'null'} control={<RadioMUI />} label="Both modes" />
                                <FormControlLabel value={'true'} control={<RadioMUI />} label="Automatic" />
                                <FormControlLabel value={'false'} control={<RadioMUI />} label="Manual" />
                            </RadioGroup>
                        </div>
                    </div>
                ))}
                <div className={styles.separator} />
                <div className={styles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        className={styles.applyFilters}
                        size="small"
                        onClick={applyFilters}
                    >
                        Apply filters
                    </ButtonMUI>
                    <ButtonMUI
                        variant='text'
                        className={styles.clearFilters}
                        onClick={() => setTempParams(initialParameters)}
                    >
                        <ResetIcon /> Clear all
                    </ButtonMUI>
                </div>
            </div>
        </DialogMUI>
    </>
}

const initialOpenState = false;

export default AdditionalFilters;