import React from 'react';
import Header from '../../layout/Header';
import NavSidebar from '../../layout/NavSidebar';
import MainRoutes from '../../routes/MainRoutes';
import styles from './MainContainer.module.scss';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';

const MainContainer = () => {
  init({ data })
  return (
    <div className={styles.root}>
      <NavSidebar />
      <div className={styles.container}>
        <Header />
        <MainRoutes />
      </div>
    </div>
  );
};

export default MainContainer;
