import React from 'react';
import { Switch, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import './SwitchMUI.scss';

const SwitchMUI = withStyles((theme) => ({
  root: {
    width: 44,
    height: 24,
    padding: 0,
    margin: theme.spacing(0),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#296EFF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
    margin: 1,
  },
  track: {
    borderRadius: 24 / 2,
    border: `2px solid #E4E6EA`,
    backgroundColor: '#E4E6EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({
  classes,
  className = '',
  checked,
  checkedIcon = <span className='MuiSwitch-thumb switch-mui__thumb' />,
  color = 'default',
  disabled = false,
  disableRipple = false,
  defaultChecked = false,
  edge = false,
  icon = <span className='MuiSwitch-thumb switch-mui__thumb' />,
  id,
  inputProps,
  inputRef,
  onChange,
  required = false,
  size = 'medium',
  value,
  ...props
}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={onChange}
      checked={checked}
      {...props}
    />
  );
});

SwitchMUI.propTypes = {
  checked: PropTypes.bool,
  checkedIcon: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  defaultChecked: PropTypes.bool,
  disableRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  edge: PropTypes.oneOf(['end', 'start', false]),
  icon: PropTypes.node,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  value: PropTypes.any,
};

export default SwitchMUI;