import { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './UserHeading.module.scss';
import parentStyles from '../../Audio/Audio.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMUI } from '../../../shared'
import { ReactComponent as OpenLinkIcon } from '../../../assets/icons/open_link.svg'
import { ReactComponent as AgreementIcon } from '../../../assets/icons/agreement_date_icon.svg'
import { ReactComponent as UserLinks } from '../../../assets/icons/user_links.svg'
import { ReactComponent as UserLocation } from '../../../assets/icons/user_location.svg'
import { ReactComponent as UserMembering } from '../../../assets/icons/user_membering.svg'
import { ReactComponent as UserVerified } from '../../../assets/icons/user_verified.svg'
import { ReactComponent as UserVerifiedIcon } from '../../../assets/icons/user_inner_verify.svg'
import { ReactComponent as BigBlock } from '../../../assets/icons/big_block.svg'
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import { ReactComponent as Unverify } from '../../../assets/icons/unverify.svg'
import { ReactComponent as Verify } from '../../../assets/icons/verify.svg'
import nonAvatar from '../../../assets/icons/profile.svg'
import { ReactComponent as BackArrow } from '../../../assets/icons/header_back_arrow.svg';
import { classList, formatNumber } from '../../../helpers/functions';
import { ClickAwayListener } from '@material-ui/core';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
import { ageOptions } from '../../Audio/Audio';
import ActionDialog from '../ActionDialog';

const UserHeading = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId } = useParams();
    const target = useSelector(({ users }) => users.userProfile)
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)
    const [descriptionOpenStatus, setDescriptionOpenStatus] = useState(false)
    const [initialDescHeights, setInitialDescHeights] = useState(false)

    useEffect(() => {
        if (!!target && target.hasOwnProperty('text') && !!target.text) {
            let descWrapper = document.querySelector(`.${styles.description}`),
                inner = descWrapper.children[0],
                innerWrap = inner.children[0];
            if (innerWrap.clientHeight > inner.clientHeight) {
                setInitialDescHeights(true)
            }
        }
    }, [target.text])

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: target })
        contextMenuActions('close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(dialogStatus.type.toLowerCase() === 'delete' ? campaignAction('DELETE', dialogStatus.target.id) : campaignArchive(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
                history.push(`/main/advertising/${advertiserId}`)
            }
        })
    }

    const links = !!target.links && <p className={styles.links}>{target.links.map(({ url }, idx) => <Fragment key={idx}>
        <a target='_blank' href={url}>{url}</a>
        {target.links.length - 1 !== idx && <span>•</span>}
    </Fragment>)}</p>

    return <div className={styles.heading}>
        <div
            className={classList(styles.imageWrapper)}
        >
            <img src={target.avatar || nonAvatar} alt={target.full_name} />
        </div>
        <div className={styles.shortInfo}>
            <div>
                <h1>{!!target.full_name && !!target.full_name.replaceAll(' ', '') ? target.full_name : '-'}{target.is_verified && <UserVerifiedIcon />}</h1>
                <div className={styles.artistBlock}>
                    {[`@${target.username}`, target.email].filter(el => !!el).join(' • ')}
                </div>
            </div>
            <div className={styles.lowerBlock}>
                <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
                    <a href={`https://tunetome.4-com.pro/main/artist-profile/${target.username}/${target.id}`} target='_blank'><OpenLinkIcon /></a>
                    {target.is_blocked
                        ? <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons)}
                            onClick={() => openDialog('unblock')}
                        >
                            Unblock
                        </ButtonMUI>
                        : <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons, styles.block)}
                            onClick={() => openDialog('block')}
                        >
                            Block
                        </ButtonMUI>
                    }
                    <ClickAwayListener onClickAway={() => contextMenuActions('close', target.id)}>
                        <div className={parentStyles.moreActions}>
                            <button className={classList(parentStyles.moreActions__btn, { [parentStyles.active]: contextMenu === target.id })} onClick={() => contextMenuActions('open', target.id)}>
                                <span />
                                <span />
                                <span />
                            </button>
                            {contextMenu === target.id && <div className={parentStyles.moreActions__container}>
                                {target.is_verified
                                    ? <button
                                        className={classList(parentStyles.actionButtons)}
                                        onClick={() => openDialog('unverify')}
                                    >
                                        <Unverify />Unverify
                                    </button>
                                    : <button
                                        className={classList(parentStyles.actionButtons)}
                                        onClick={() => openDialog('verify')}
                                    >
                                        <Verify />Verify
                                    </button>
                                }
                                <button
                                    className={classList(parentStyles.actionButtons, parentStyles.deleteAction)}
                                    onClick={() => openDialog('delete')}
                                >
                                    <DeleteAction />Delete
                                </button>
                            </div>}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </div>
        <div className={styles.followers}>
            <span>
                <b>
                    {target.followers_count}
                </b>
                followers
            </span>
            •
            <span>
                <b>
                    {target.following_count}
                </b>
                following
            </span>
        </div>
        {target.hasOwnProperty('text') && !!target.text && (
            <div
                id={target.id}
                className={classList(
                    styles.description,
                    { [styles.opened]: descriptionOpenStatus },
                    { [styles.openable]: initialDescHeights }
                )}>
                <div className={styles.descriptionInner}>
                    <div className={styles.descriptionInnerWrapper}>
                        {target.text}
                    </div>
                </div>
                <ButtonMUI
                    variant='text'
                    className={styles.descriptionButton}
                    onClick={() => setDescriptionOpenStatus(prev => !prev)}
                >
                    Show {descriptionOpenStatus ? 'less' : 'more'} <BackArrow />
                </ButtonMUI>
            </div>
        )}
        <div className={styles.smallStatistic}>
            <div>
                <UserLocation />
                <p>{!!target.location
                    ? target.location.name
                    : '-'}</p>
            </div>
            <div>
                <UserMembering />
                <span>Member since:</span>
                <p>{!!target.registered_date ? dayjs(target.registered_date).format('DD MMMM YYYY') : '-'}</p>
            </div>
            <div>
                <AgreementIcon />
                <span>Birthday:</span>
                <p>{!!target.birth_date ? dayjs(target.birth_date).format('DD MMMM YYYY') : '-'}</p>
            </div>
            <div>
                <UserVerified />
                <span>Verified:</span>
                <p>{!!target.verified_date ? dayjs(target.verified_date).format('DD MMMM YYYY') : '-'}</p>
            </div>
            {target.links && target.links.length > 0 && (
                <div>
                    <UserLinks />
                    {links}
                </div>)
            }
        </div>
        {target.is_blocked && target.blocked_text && <div className={styles.blockReason}>
            <BigBlock />
            <div>
                <p>This user has been blocked for the following reason:</p>
                <span>{target.blocked_text}</span>
            </div>
        </div>}
        <ActionDialog
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
        />
    </div>
}

export default UserHeading