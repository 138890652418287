export const USERS = {
    GET_USERS: 'GET_USERS',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAIL: 'GET_USERS_FAIL',

    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAIL: 'GET_USER_PROFILE_FAIL',

    GET_USER_STATISTIC: 'GET_USER_STATISTIC',
    GET_USER_STATISTIC_SUCCESS: 'GET_USER_STATISTIC_SUCCESS',
    GET_USER_STATISTIC_FAIL: 'GET_USER_STATISTIC_FAIL',

    GET_USER_FEED: 'GET_USER_FEED',
    GET_USER_FEED_SUCCESS: 'GET_USER_FEED_SUCCESS',
    GET_USER_FEED_FAIL: 'GET_USER_FEED_FAIL',

    VERIFY_USER: 'VERIFY_USER',
    VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
    VERIFY_USER_FAIL: 'VERIFY_USER_FAIL',

    UNVERIFY_USER: 'UNVERIFY_USER',
    UNVERIFY_USER_SUCCESS: 'UNVERIFY_USER_SUCCESS',
    UNVERIFY_USER_FAIL: 'UNVERIFY_USER_FAIL',

    BLOCK_USER: 'BLOCK_USER',
    BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
    BLOCK_USER_FAIL: 'BLOCK_USER_FAIL',

    UNBLOCK_USER: 'UNBLOCK_USER',
    UNBLOCK_USER_SUCCESS: 'UNBLOCK_USER_SUCCESS',
    UNBLOCK_USER_FAIL: 'UNBLOCK_USER_FAIL',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAIL: 'DELETE_USER_FAIL',

    GET_POST: 'GET_POST',
    GET_POST_SUCCESS: 'GET_POST_SUCCESS',
    GET_POST_FAIL: 'GET_POST_FAIL',

    DELETE_POST: 'DELETE_POST',
    DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
    DELETE_POST_FAIL: 'DELETE_POST_FAIL',

    GET_POST_COMMENTS: 'GET_POST_COMMENTS',
    GET_POST_COMMENTS_SUCCESS: 'GET_POST_COMMENTS_SUCCESS',
    GET_POST_COMMENTS_FAIL: 'GET_POST_COMMENTS_FAIL',
    
    DELETE_POST_COMMENTS: 'DELETE_POST_COMMENTS',
    DELETE_POST_COMMENTS_SUCCESS: 'DELETE_POST_COMMENTS_SUCCESS',
    DELETE_POST_COMMENTS_FAIL: 'DELETE_POST_COMMENTS_FAIL',

    SET_POST_MODAL: 'SET_POST_MODAL',
    DELETE_POST_MODAL: 'DELETE_POST_MODAL',
}
