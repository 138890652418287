import React, { useEffect, useState } from 'react';
import { ButtonMUI, InputMUI, SelectComponent, TabItem, Table, Tabs, DialogMUI } from '../../shared';
import templateStyles from '../Audio/Audio.module.scss';
import styles from './Users.module.scss';
import { classList, prettyScroll, formatNumber } from '../../helpers/functions';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { ReactComponent as OpenLinkIcon } from '../../assets/icons/open_link.svg'
import { ReactComponent as Verified } from '../../assets/icons/verified.svg'
import { ReactComponent as Unverify } from '../../assets/icons/unverify.svg'
import { ReactComponent as Verify } from '../../assets/icons/verify.svg'
import { ReactComponent as BlockAction } from '../../assets/icons/block_action.svg'
import { ReactComponent as UnblockAction } from '../../assets/icons/unblock_action.svg'
import { ReactComponent as DeleteAction } from '../../assets/icons/delete_action.svg'
import nonAvatar from '../../assets/icons/profile.svg'
import profile from '../../assets/icons/profile.svg'
import { getUsers } from './storage/usersActions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
import { ClickAwayListener } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { renderStatus } from '../Audio/Audio';
import ActionDialog from './ActionDialog';

const Users = () => {
  const dispatch = useDispatch()
  const { users_loading, users } = useSelector(({ users }) => users)
  const [parameters, setParameters] = useState(initialParameters)
  const [contextMenu, setContextMenu] = useState(false)
  const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })

  const paramsSetter = (key, value) => {
    setParameters((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (!dialogStatus.status) setTimeout(() => setDialogStatus(prev => ({ ...prev, type: null, target: null })), 250)
  }, [dialogStatus.status])

  const contextMenuActions = (action, id) => {
    if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
    else if ('open') setContextMenu(prev => prev === id ? false : id)
  }

  const openDialog = type => {
    setDialogStatus({ status: true, type: type, target: users.results.find(el => el.id === contextMenu) })
    contextMenuActions('close', contextMenu)
  }

  const columns = [
    {
      name: 'Name',
      value: 'full_name',
      width: 25,
      renderKey: ['full_name', 'avatar', 'is_verified', 'id'],
      render: ({ full_name, avatar, is_verified, id }) => (
        <Link
          to={`/main/users/${id}`}
          className={classList(templateStyles.nameWrapper, styles.nameWrapper)}
        >
          <img src={avatar || nonAvatar} alt={full_name} />
          <div className={classList(templateStyles.info, styles.info)}>
            <p><span>{!!full_name && !!full_name.replaceAll(' ', '') ? full_name : '-'}</span>{is_verified && <Verified />}</p>
            {/* <span>smth</span> */}
          </div>
        </Link>
      )
    },
    {
      name: 'Username',
      value: 'username',
      width: 14,
      renderKey: `username`,
      render: username => <div className={classList('cellText', styles.username)}>{username || '-'}</div>
    },
    {
      name: 'Email',
      value: 'email',
      width: 14,
      renderKey: 'email',
      render: email => <div className={'cellText'}>{email || '-'}</div>
    },
    {
      name: 'Balance',
      value: 'balance',
      width: 14,
      renderKey: 'balance',
      render: balance => <div className={'cellText'}>${formatNumber(balance)}</div>
    },
    {
      name: 'Status',
      value: null,
      width: 14,
      renderKey: ['is_blocked', 'is_partially_blocked', 'blocked_tracks_count', 'tracks_count'],
      render: renderStatus
    },
    {
      name: '',
      value: null,
      width: 5,
      renderKey: ['id', 'is_blocked', 'is_verified', 'username'],
      render: ({ id, is_blocked, is_verified, username }) => <div className={templateStyles.moreActionsWrapper}>
        <a href={`https://tunetome.4-com.pro/main/artist-profile/${username}/${id}`} target='_blank'><OpenLinkIcon /></a>
        <ClickAwayListener onClickAway={() => contextMenuActions('close', id)}>
          <div className={templateStyles.moreActions}>
            <button className={classList(templateStyles.moreActions__btn, { [templateStyles.active]: contextMenu === id })} onClick={() => contextMenuActions('open', id)}>
              <span />
              <span />
              <span />
            </button>
            {contextMenu === id && <div className={templateStyles.moreActions__container}>
              {is_blocked
                ? <button
                  className={classList(templateStyles.actionButtons)}
                  onClick={() => openDialog('unblock')}
                >
                  <UnblockAction />Unblock
                </button>
                : <button
                  className={classList(templateStyles.actionButtons)}
                  onClick={() => openDialog('block')}
                >
                  <BlockAction />Block
                </button>
              }
              {is_verified
                ? <button
                  className={classList(templateStyles.actionButtons)}
                  onClick={() => openDialog('unverify')}
                >
                  <Unverify />Unverify
                </button>
                : <button
                  className={classList(templateStyles.actionButtons)}
                  onClick={() => openDialog('verify')}
                >
                  <Verify />Verify
                </button>
              }
              <button
                className={classList(templateStyles.actionButtons, templateStyles.deleteAction)}
                onClick={() => openDialog('delete')}
              >
                <DeleteAction />Delete
              </button>
            </div>}
          </div>
        </ClickAwayListener>
      </div>
    }
  ]

  return (
    <main className={classList(templateStyles.audio, styles.users, `page-wrap`)} ref={prettyScroll}>
      <div className={classList(templateStyles.audioInner, styles.usersInner, `container`)}>
        <h1>Users</h1>
        <div className={`inner-wrap ${templateStyles['inner-wrap']}`}>
          <div className={templateStyles.filters}>
            <InputMUI
              className={templateStyles.search}
              id='search'
              type='text'
              placeholder='Search by name, ID, username or email...'
              startAdornment={<SearchIcon />}
              size='small'
              value={parameters.search}
              onChange={({ target: { value } }) => paramsSetter('search', value)}
            />
            <SelectComponent
              options={Statuses}
              value={parameters.is_blocked || Statuses[0]}
              onChange={e => paramsSetter('is_blocked', e)}
              selectWithLabel={'Status'}
            />
          </div>
          <Table
            action={({ queryString }) => dispatch(getUsers(queryString))}
            disableRedux
            params={parameters}
            setParams={setParameters}
            columns={columns}
            rows={users?.results}
            rowsCount={users?.count}
            loading={users_loading}
          />
        </div>
      </div>
      <ActionDialog
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
      />
    </main>
  );
};


const Statuses = [
  {
    value: null,
    label: 'All users'
  },
  {
    value: false,
    label: 'Active'
  },
  {
    value: true,
    label: 'Blocked'
  },
]

const initialParameters = {
  page_size: 10,
  ordering: null, // full_name | -full_name | username | -username | email | -email | balance | -balance
  is_blocked: null, // true | false
  search: '',
}

export default Users;
