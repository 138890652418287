export const APP = {
  LOADING: 'LOADING',
  BUTTON_LOADING: 'BUTTON_LOADING',

  ERROR_SNACK_OPEN: 'ERROR_SNACK_OPEN',
  ERROR_SNACK_CLOSE: 'ERROR_SNACK_CLOSE',

  SUCCESS_SNACK_OPEN: 'SUCCESS_SNACK_OPEN',
  SUCCESS_SNACK_CLOSE: 'SUCCESS_SNACK_CLOSE',

  CUSTOM_BACK: 'CUSTOM_BACK'
};
