import React, { useState, useRef, useEffect } from 'react';
import Value from './Value';
import MenuList from './MenuList';

import { ClickAwayListener } from '@material-ui/core';

import './CustomMultiSelect.scss';
import Reference from 'yup/lib/Reference';
import { classList } from '../../helpers/functions';

const CustomMultiSelect = ({
  label,
  values = [],
  options = [],
  helperText,
  maxSected = 10,
  placeholder,
  className = '',
  placeholderSearch = '',
  disable = false,
  typeSelect = 'multi',
  tagSelect = false,
  maxText = '',
  setter,
  withSearch = false,
  getList,
  addBlock = false,
  nextList = '',
  reference = null,
  error,
  isList = false
}) => {
  const listRef = useRef(null);
  const shadowRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleScroll = () => {
    if (listRef.current.scrollTop + listRef.current.clientHeight >= listRef.current.scrollHeight) {
      shadowRef.current.style.display = 'none';
    } else {
      shadowRef.current.style.display = 'block';
    }
  };

  /*  useEffect(() => {
     const hiddenElement = shadowRef.current;
 
     open && (hiddenElement.style.display = 'none');
     if (options.length > 6) {
       open && (hiddenElement.style.display = 'block');
     }
 
     open && options.length > 0 && listRef.current.addEventListener('scroll', handleScroll);
   }, [open, options]); */

  const openDropdown = () => {
    open && listRef.current.removeEventListener('scroll', handleScroll);
    setOpen(true);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  const selectItem = (icon, id, label, emoji, e) => {
    e.stopPropagation();

    const objValue = { icon, id, label, emoji };

    const addItem = () => {
      setter([...values, objValue]);
    };

    const deleteItem = () => {
      setter([...values.filter((el) => el.id !== objValue.id)]);
    };

    if (!values.find((el) => el.id === id)) {
      values.length < maxSected && addItem();
    } else {
      deleteItem();
    }
  };

  const selectWithoutId = (icon, label) => {
    const objValue = { icon, label };
    setter([...values, objValue]);

    const addItem = () => {
      setter([...values, objValue]);
    };

    const deleteItem = () => {
      setter([...values.filter((el) => el.label !== objValue.label)]);
    };

    if (!values.find((el) => el.label === label)) {
      addItem();
    } else {
      deleteItem();
    }
  };

  const selectSingleItem = (obj) => {
    setter(obj);
    closeDropdown();
  };

  const valueProps = {
    values,
    openDropdown,
    setOpen,
    open,
    placeholder,
    typeSelect,
    selectItem,
    disable,
    tagSelect,
    selectWithoutId,
    setter,
    isList
  };
  const listProps = {
    options,
    values,
    listRef,
    shadowRef,
    handleScroll,
    typeSelect,
    maxSected,
    selectSingleItem,
    selectItem,
    placeholderSearch,
    tagSelect,
    maxText,
    withSearch,
    getList,
    addBlock,
    selectWithoutId,
    nextList,
    setter,
    isList
  };

  return (
    <div
      className={classList('custom-multiselect', { [className]: !!className, disable: !!disable })}
      ref={reference}
    >
      {!!label && <p className='custom-multiselect__label'>{label}</p>}
      <ClickAwayListener onClickAway={closeDropdown}>
        <div className='custom-multiselect__wrapper'>
          <Value {...valueProps} />
          {helperText && <p className='custom-multiselect__halperText'>{helperText}</p>}
          {open && <MenuList {...listProps} />}
        </div>
      </ClickAwayListener>
      {error?.message && <span className='field-error'>{error.message}</span>}
    </div>
  );
};

export default CustomMultiSelect;
