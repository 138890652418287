import { takeEvery } from 'redux-saga/effects';
import { openErrorSnack, openSuccessSnack } from '../../../app/rootSaga';

export function* handleAdvertiserSnacks() {
  yield takeEvery('POST_ADVERTISER_SUCCESS', () => openSuccessSnack('Advertiser created successfully'));
  yield takeEvery('POST_ADVERTISER_FAIL', openErrorSnack);

  yield takeEvery('PUT_ADVERTISER_SUCCESS', () => openSuccessSnack('Advertiser edited successfully'));
  yield takeEvery('PUT_ADVERTISER_FAIL', openErrorSnack);

  yield takeEvery('ADVERTISING_SETTINGS_PUT_SUCCESS', () => openSuccessSnack('Settings was updated'));
  yield takeEvery('ADVERTISING_SETTINGS_PUT_FAIL', openErrorSnack);
}
