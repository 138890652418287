import { SUPPORT } from './supportActionTypes';

export function getSupportContent(type, url) {
    return {
        type: SUPPORT[`GET_${type.toUpperCase()}`],
        payload: {
            client: 'default',
            request: {
                url: `admin/${type}/list${!!url && url.length > 0 ? url : ''}`,
                method: 'GET',
            },
        },
    };
}

export function getPlaylistTracks(id, pageSize, page) {
    return {
        type: SUPPORT.GET_PLAYLIST_TRACKS,
        payload: {
            client: 'default',
            request: {
                url: `admin/music/claim/playlist/${id}/tracks?page_size=${pageSize}${!!page ? `&page=${page}` : ''}`,
                method: 'GET',
                page: !!page,
            },
        },
    };
}

export const setClaimStatus = (id, newStatus) => ({
    type: SUPPORT.SET_CLAIM_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `admin/claims/${id}`,
            method: 'PUT',
            data: {
                status: newStatus
            },
            id,
            newStatus
        },
    },
})

export const closePlaylistModal = () => ({
    type: SUPPORT.CLOSE_PLAYLIST_MODAL
})