import { useParams, Link, useHistory } from 'react-router-dom';
import additionalStyles from '../Audio/Audio.module.scss';
import styles from './Advertising.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { classList, prettyScroll } from '../../helpers/functions';
import StatisticSidebar from './StatisticSidebar';
import DetailsMaper from '../Audio/DetailsMaper';
import { campaignAction, campaignArchive, getAdvertiserCampaigns } from './storage/advertisingActions';
import { ClickAwayListener } from '@material-ui/core';
import { ReactComponent as DeleteAction } from '../../assets/icons/delete_action.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit_ico.svg'
import { ReactComponent as Plus } from '../../assets/icons/adver_plus.svg'
import { ReactComponent as SendFail } from '../../assets/icons/send-failed.svg'
import { ReactComponent as ActiveCampaign } from '../../assets/icons/active_campaign.svg'
import { ReactComponent as ArchivedCampaign } from '../../assets/icons/archived_campaign.svg'
import { ReactComponent as ArchiveActionButton } from '../../assets/icons/archive_action.svg'
import { ReactComponent as RestoreActionButton } from '../../assets/icons/restore_action.svg'
import nonAvatar from '../../assets/images/adver_non_avatar.svg'
import { ButtonMUI, DialogMUI, TooltipMUI } from '../../shared';

const Campaigns = () => {
    const dispatch = useDispatch();
    const { advertiserId } = useParams();
    const history = useHistory();
    const { editAdvertiser, advertiserCampaigns } = useSelector(({ advertising }) => advertising)
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        dispatch(getAdvertiserCampaigns(advertiserId))
    }, [advertiserId])

    const contextMenuActions = (e, action, id) => {
        if (!!e) e.preventDefault();
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if (action === 'open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: advertiserCampaigns.results.find(el => el.id === contextMenu) })
        contextMenuActions(undefined, 'close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(dialogStatus.type.toLowerCase() === 'delete' ? campaignAction('DELETE', dialogStatus.target.id) : campaignArchive(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
            }
        })
    }

    return advertiserCampaigns.results
        ? <div className={styles.campaigns}>
            <DetailsMaper
                title={<>Campaigns<span className={styles.campaigns__count}>{advertiserCampaigns.count}</span></>}
                type='campaigns'
                classes={styles.campaigns__mapper}
            >
                <ButtonMUI
                    variant='text'
                    href={`/main/advertising/${advertiserId}/campaign/new`}
                >
                    <Plus /> Add campaign
                </ButtonMUI>
                {advertiserCampaigns.results.map(el => <Link to={`/main/advertising/${advertiserId}/campaign/${el.id}`} key={el.id} className={styles.campaigns__item}>
                    <div className={styles.campaigns__info}>
                        <div className={styles.campaigns__name}>
                            <img src={el.image || nonAvatar} alt="campaign_avatar" />
                            <div>
                                <p>
                                    {el.name}
                                    {!!el.has_broken_tracks && <TooltipMUI arrow className={styles.tooltip} title={'Error processing audio'}>
                                        <SendFail />
                                    </TooltipMUI>}
                                </p>
                                <span>{dayjs(el.start_date).format('DD/MM/YYYY')} - {dayjs(el.end_date).format('DD/MM/YYYY')}</span>
                            </div>
                        </div>
                        <div className={styles.campaigns__actions}>
                            <div className={classList(styles.campaigns__status, styles[el.is_archived])}>
                                {el.is_archived
                                    ? <><ArchivedCampaign />Archived</>
                                    : <><ActiveCampaign />Active</>
                                }
                            </div>
                            <div className={styles.moreActionsWrapper}>
                                <ClickAwayListener onClickAway={e => contextMenuActions(e, 'close', el.id)}>
                                    <div className={styles.moreActions}>
                                        <button
                                            className={classList(styles.moreActions__btn, { [styles.active]: contextMenu === el.id })}
                                            onClick={e => contextMenuActions(e, 'open', el.id)}
                                        >
                                            <span />
                                            <span />
                                            <span />
                                        </button>
                                        {contextMenu === el.id && <div className={styles.moreActions__container} onClick={e => e.preventDefault()}>
                                            {el.is_archived
                                                ? <button
                                                    className={classList(styles.actionButtons)}
                                                    onClick={() => history.push(`/main/advertising/${advertiserId}/campaign/${el.id}/restore`)}
                                                >
                                                    <RestoreActionButton />Restore
                                                </button>
                                                : <button
                                                    className={classList(styles.actionButtons)}
                                                    onClick={() => openDialog('archive')}
                                                >
                                                    <ArchiveActionButton />Archive
                                                </button>
                                            }
                                            <button
                                                className={classList(styles.actionButtons)}
                                                onClick={() => history.push(`/main/advertising/${advertiserId}/campaign/${el.id}/edit`)}
                                            >
                                                <EditIcon />Edit
                                            </button>
                                            <button
                                                className={classList(styles.actionButtons, styles.deleteAction)}
                                                onClick={() => openDialog('delete')}
                                            >
                                                <DeleteAction />Delete
                                            </button>
                                        </div>}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                    </div>
                    <div className={styles.campaigns__desc}>{el.description}</div>
                </Link>)}
            </DetailsMaper>
            <DeleteModal
                dialogStatus={dialogStatus}
                setDialogStatus={setDialogStatus}
                btnLoad={btnLoad}
                submitDialog={submitDialog}
            />
        </div>
        : null
}

export const DeleteModal = ({ dialogStatus, setDialogStatus, btnLoad, submitDialog, type = 'advertiser' }) => <DialogMUI
    open={dialogStatus.status}
    onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
    maxWidth={'xs'}
>
    <>
        <h2 className={additionalStyles.dialogTitle}><span className='capitalize'>{dialogStatus.type}</span> {type}</h2>
        <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>
                {dialogStatus.type === 'archive'
                    ? <>Are you sure you want to archive {' '}<span>{dialogStatus?.target?.name}</span>? Audio from this campaign will no longer be played. You can restore it at any time.</>
                    : <>Are you sure you want to delete{' '}<span>{dialogStatus?.target?.name}</span>? This action cannot be undone.</>
                }
            </p>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={additionalStyles.dialogButtons}
                    onClick={submitDialog}
                    loading={btnLoad}
                    disabled={btnLoad || !dialogStatus.status}
                >
                    {dialogStatus.type || ''}
                </ButtonMUI>
                <ButtonMUI
                    variant='contained'
                    size="small"
                    className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                    onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
                >
                    Cancel
                </ButtonMUI>
            </div>
        </div>
    </>
</DialogMUI>

export default Campaigns