import { useParams } from 'react-router-dom';
import templateStyles from '../Audio/Album/Album.module.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prettyScroll } from '../../helpers/functions';
import StatisticSidebar from './StatisticSidebar';
import { getUserFeed, getUserProfile } from './storage/usersActions';
import UserHeading from './UserHeading';
import Feed from './Feed';
import styles from './Users.module.scss'
import { CircularProgress } from '@material-ui/core';

const UserInner = () => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const cursor = useSelector(({ users }) => (!!users.userFeed.next ? users.userFeed.next.split('?cursor=')[1] : users.userFeed.next))
    const { userInnerLoading } = useSelector(({ users }) => users)

    useEffect(() => {
        dispatch(getUserProfile(userId))
    }, [userId])

    const feedEnd = () => {
        if (!!cursor) dispatch(getUserFeed(userId, cursor))
    }

    return <main className={`${templateStyles.album} container`}>
        <div className={`${templateStyles.albumInner} page-wrap`} ref={e => prettyScroll(e, null, feedEnd)}>
            {userInnerLoading && <div className={styles.loading}><CircularProgress size={100} thickness={8} /></div>}
            <UserHeading />
            <Feed />
        </div>
        <StatisticSidebar userId={userId} />
    </main>
}

export default UserInner