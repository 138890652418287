import React from 'react';
import { ReactComponent as Cross } from '../../assets/icons/cross_withoutBg.svg';
import { ReactComponent as Arrow } from '../SelectComponent/icons/chevron-down.svg';

import './CustomMultiSelect.scss';

const Value = ({ values, selectWithoutId, setOpen, open, placeholder, typeSelect, selectItem, disable, tagSelect, isList }) => {
  return (
    <div
      className={`custom-multiselect__btn${tagSelect ? ' tags-value' : ''}`}
      onClick={() => (!disable ? setOpen((prev) => !prev) : null)}
    >
      {typeSelect === 'multi' ? (
        <div className={`custom-multiselect__values-wrapper${isList ? ' list-view' : ''}`}>
          {values.length > 0 ? (
            isList
              ? values.map(({ label }) => (label)).join(', ')
              : values.map(({ icon, label, id, emoji }, idx) => (
                <div onClick={(e) => e.stopPropagation()} className='custom-multiselect__value' key={idx}>
                  {emoji ? (
                    <span><em-emoji set='apple' native={emoji} /></span>
                  ) : icon && !tagSelect ? (
                    <img
                      className={`custom-multiselect__label-icon${values.length === 2 ? ' short-array' : ' long-array'}`}
                      src={icon}
                      alt='coin'
                    />
                  ) : (
                    ''
                  )}
                  {label && <span>{label}</span>}
                  <button
                    type='button'
                    onClick={(e) => (id ? selectItem(icon, id, label, emoji, e) : selectWithoutId(icon, label))}
                  >
                    <Cross />
                  </button>
                </div>
              ))
          ) : (
            <span className='custom-multiselect__placeholder'>{placeholder}</span>
          )}
        </div>
      ) : values?.label ? (
        <span className='custom-multiselect__single-value'>{values.label}</span>
      ) : (
        <span className='custom-multiselect__placeholder'>{placeholder}</span>
      )}
      <button
        onClick={(e) => (!disable ? (e.stopPropagation(), setOpen((prev) => !prev)) : '')}
        className={`custom-multiselect__arrow${open ? ' active' : ''}`}
        type='button'
      >
        <Arrow />
      </button>
    </div>
  );
};

export default Value;
