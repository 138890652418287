import { useParams } from 'react-router-dom';
import { classList, prettyScroll } from '../../../helpers/functions';

import styles from './Album.module.scss';
import parentStyles from '../Audio.module.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAudioInfo, getAlbumTracks } from '../storage/audioActions'
import StatisticSidebar from '../StatisticSidebar'
import Heading from '../Heading'
import DetailsMaper from '../DetailsMaper';
import dayjs from 'dayjs';
import { ageOptions, renderAlbumInfo, renderStatus } from '../Audio';
import ActionDialog from '../ActionDialog';
import { ClickAwayListener } from '@material-ui/core';

import { ReactComponent as BlockAction } from '../../../assets/icons/block_action.svg'
import { ReactComponent as UnblockAction } from '../../../assets/icons/unblock_action.svg'
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'

const type = 'album'

const Album = () => {
    const dispatch = useDispatch();
    const { albumId } = useParams();
    const target = useSelector(({ audio }) => audio[`${type}Info`])
    const { albumTracks } = useSelector(({ audio }) => audio)

    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = (action, id) => {
        setDialogStatus({ status: true, type: action, target: { ...albumTracks.find(el => el.id === id), artist: target.artist } })
        contextMenuActions('close', contextMenu)
    }

    useEffect(() => {
        dispatch(getAudioInfo(type, albumId))
        dispatch(getAlbumTracks(albumId))
    }, [albumId])

    return <main className={`${styles.album} container`}>
        <div className={`${styles.albumInner} page-wrap`} ref={prettyScroll}>
            <Heading type={type} trackId={albumId} />
            <DetailsMaper title={'Track list'} arr={createTracks(albumTracks, contextMenu, contextMenuActions, openDialog)} type='tracks' />
            <DetailsMaper title={'Album details'} arr={createDetails(target)} />
        </div>
        <StatisticSidebar type={type} trackId={albumId} />
        <ActionDialog
            type={'tracks'}
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            isInner
            isAlbumTrack
        />
    </main>
}

const createTracks = (tracks, contextMenu, contextMenuActions, openDialog) => (tracks.length < 1 ? [] : tracks.map((el, idx) => <div className={styles.trackItem} key={idx}>
    <div className={styles.trackItem__info}>
        <span>{idx + 1}</span>
        <div className={styles.trackItem__name}>
            <p>{el.name}</p>
            {renderAlbumInfo(el, `tracks`, true)}
        </div>
    </div>
    {el.artists.length > 1 && (
        <div className={styles.trackItem__feat}>
            <span>feat.</span>
            <p>{el.artists.map(({ name }) => name).join(', ')}</p>
        </div>
    )}
    <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
        {renderStatus({ is_blocked: el.is_blocked })}
        <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
            <ClickAwayListener onClickAway={() => contextMenuActions('close', el.id)}>
                <div className={parentStyles.moreActions}>
                    <button className={classList(parentStyles.moreActions__btn, { [parentStyles.active]: contextMenu === el.id })} onClick={() => contextMenuActions('open', el.id)}>
                        <span />
                        <span />
                        <span />
                    </button>
                    {contextMenu === el.id && <div className={parentStyles.moreActions__container}>
                        {el.is_blocked
                            ? <button
                                className={classList(parentStyles.actionButtons)}
                                onClick={() => openDialog('unblock', el.id)}
                            >
                                <UnblockAction />Unblock
                            </button>
                            : <button
                                className={classList(parentStyles.actionButtons)}
                                onClick={() => openDialog('block', el.id)}
                            >
                                <BlockAction />Block
                            </button>
                        }
                        <button
                            className={classList(parentStyles.actionButtons, parentStyles.deleteAction)}
                            onClick={() => openDialog('delete', el.id)}
                        >
                            <DeleteAction />Delete
                        </button>
                    </div>}
                </div>
            </ClickAwayListener>
        </div>
    </div>
</div>))

const createDetails = target => (JSON.stringify(target) === '{}' ? [] : [
    [{
        title: 'Main genre',
        value: target.genre.name,
        size: 'sm',
    },
    {
        title: 'Custom genres',
        value: target.custom_genres.length > 0 && target.custom_genres.map(el => el.name).join(', '),
        size: 'lg',
    }],
    {
        title: 'Release date',
        value: dayjs(target.release_date).format('DD/MM/YYYY'),
        size: 'sm',
    },
    {
        title: 'Location',
        value: target.location && target.location.address,
        size: 'xl',
    },
    {
        title: 'Emotions',
        value: target.emotions.length > 0 && target.emotions.map((el, idx) => <p><em-emoji set='apple' native={el.emoji} />{el.name}{idx + 1 < target.emotions.length && ','}</p>),
        size: 'xl',
    },
    {
        title: 'Minimum age',
        value: ageOptions.find(el => el.id === target.age).label,
        size: 'md',
    }
])

export default Album