import { useEffect, useState } from "react"
import { classList, prettyScroll } from "../../helpers/functions"
import { ButtonMUI, DialogMUI } from "../../shared"
import styles from '../Audio/Audio.module.scss'
import { InputMUI } from "../../shared"
import { useDispatch } from "react-redux"
import { userActions } from "./storage/usersActions"
import { useHistory } from "react-router-dom"

const ActionDialog = ({ dialogStatus, setDialogStatus }) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [blockReason, setBlockReason] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const fullName = !!dialogStatus.target?.full_name && !!dialogStatus.target?.full_name.replaceAll(' ', '') ? dialogStatus.target?.full_name : `@${dialogStatus.target?.username}`

    useEffect(() => {
        setBlockReason('')
    }, [dialogStatus.status])

    const renderDialog = () => {
        switch (dialogStatus.type) {
            case 'delete':
                return <>
                    <p className={styles.desc}>
                        Are you sure you want to delete{' '}
                        <span>{fullName}</span>?{' '}
                        This action cannot be undone.
                    </p>
                </>
            case 'block':
                return <>
                    <p className={styles.desc}>
                        Specify a reason and confirm that you want to block{' '}
                        <span>{fullName}</span>.{' '}
                        This user will no longer be able to use the features of the service.
                    </p>
                    <div className={styles.inputLabel}>
                        <p className={styles.inputLabel__title}>Reason</p>
                        <div className={styles.inputLabel__wrapper}>
                            <InputMUI
                                className={styles.blockReason}
                                id='block_reason'
                                type='text'
                                placeholder={'Describe a problem'}
                                value={blockReason}
                                onChange={({ target: { value } }) => setBlockReason(value)}
                                multiline
                                minRows={5}
                                maxRows={10}
                                inputRef={prettyScroll}
                                error={blockReason.length > 1000}
                                helperText={blockReason.length > 1000 ? 'Reason must be less than 1000 characters.' : undefined}
                            />
                        </div>
                    </div>
                </>
            case 'unblock':
                return <>
                    <p className={styles.desc}>
                        Confirm that you want to unblock {' '}
                        <span>{fullName}</span>.{' '}
                        This user will be able to use all the features of the service again.
                    </p>
                </>
            case 'verify':
                return <>
                    <p className={styles.desc}>
                        Confirm that you want to verify {' '}
                        <span>{fullName}</span>.{' '}
                        This user will receive a "Verified" checkmark visible to others.
                    </p>
                </>
            case 'unverify':
                return <>
                    <p className={styles.desc}>
                        Confirm that you want to unverify {' '}
                        <span>{fullName}</span>.{' '}
                        This user will no longer have a "Verified" checkmark.
                    </p>
                </>
            default: return null
        }
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(userActions(dialogStatus.type, dialogStatus.target?.id, blockReason)).then(res => {
            setBtnLoad(false)
            if (res.type.includes('_SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
                if (dialogStatus.type === 'delete') history.push('/main/users')
            }
        })
    }

    return <DialogMUI
        open={dialogStatus.status}
        onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
        maxWidth={'xs'}
    >
        <>
            <h2 className={styles.dialogTitle}><span>{dialogStatus.type}</span> user</h2>
            <div className={classList(styles.dialogWrapper, { [styles[dialogStatus.type]]: !!dialogStatus.type })}>
                {renderDialog()}
                <div className={styles.separator} />
                <div className={styles.button_wrapper}>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={styles.dialogButtons}
                        onClick={submitDialog}
                        loading={btnLoad}
                        disabled={btnLoad || (dialogStatus.type === 'block' ? blockReason.length < 1 || blockReason.length > 1000 : false) || !dialogStatus.status}
                    >
                        <>{dialogStatus.type}</>
                    </ButtonMUI>
                    <ButtonMUI
                        variant='contained'
                        size="small"
                        className={classList(styles.dialogButtons, styles.cancel)}
                        onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
                    >
                        cancel
                    </ButtonMUI>
                </div>
            </div>
        </>
    </DialogMUI>
}

export default ActionDialog