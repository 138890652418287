import { PAGES_CONTENT } from './pagesContentActionTypes';

const INITIAL_STATE = {
    countries: {},
    countries_loading: true,
    genres: {},
    genres_loading: true,
    innerContent: {},
    initialInnerContent: '',
    availableTracks: {},
    availablePacks: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        //get countries
        case PAGES_CONTENT.GET_COUNTRIES:
            return {
                ...state,
                countries_loading: true
            };
        case PAGES_CONTENT.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload.data,
                countries_loading: false
            };
        //get genres
        case PAGES_CONTENT.GET_GENRES:
            return {
                ...state,
                genres_loading: true
            };
        case PAGES_CONTENT.GET_GENRES_SUCCESS:
            return {
                ...state,
                genres: action.payload.data,
                genres_loading: false
            };
        //set country automatic
        case PAGES_CONTENT.SET_COUNTRY_AUTOMATIC_SUCCESS:
            let countriesRes = [...state.countries.results];
            if (action.meta.previousAction.payload.code === 'all') {
                countriesRes = countriesRes.map(el => ({
                    ...el,
                    weekly_chart_auto: true,
                    monthly_chart_auto: true,
                    yearly_chart_auto: true
                }))
            } else {
                if (countriesRes.some(el => el.code === action.meta.previousAction.payload.code)) {
                    countriesRes.splice(countriesRes.findIndex(el => el.code === action.meta.previousAction.payload.code), 1, {
                        ...countriesRes.find(el => el.code === action.meta.previousAction.payload.code),
                        weekly_chart_auto: true,
                        monthly_chart_auto: true,
                        yearly_chart_auto: true
                    })
                }
            }
            return {
                ...state,
                countries: {
                    ...state.countries,
                    results: [...countriesRes]
                }
            }
        //set genres automatic
        case PAGES_CONTENT.SET_GENRES_AUTOMATIC_SUCCESS:
            let genresRes = [...state.genres.results];
            if (action.meta.previousAction.payload.id === 0) {
                genresRes = genresRes.map(el => ({
                    ...el,
                    weekly_chart_auto: true,
                    monthly_chart_auto: true,
                    yearly_chart_auto: true
                }))
            } else {
                if (genresRes.some(el => el.id === action.meta.previousAction.payload.id)) {
                    genresRes.splice(genresRes.findIndex(el => el.id === action.meta.previousAction.payload.id), 1, {
                        ...genresRes.find(el => el.id === action.meta.previousAction.payload.id),
                        weekly_chart_auto: true,
                        monthly_chart_auto: true,
                        yearly_chart_auto: true
                    })
                }
            }
            return {
                ...state,
                genres: {
                    ...state.genres,
                    results: [...genresRes]
                }
            }
        //inner countries
        case PAGES_CONTENT.GET_COUNTRIES_INNER_SUCCESS:
            return {
                ...state,
                innerContent: action.payload.data,
                initialInnerContent: action.payload.data.tracks.map(el => el.id)
            }
        case PAGES_CONTENT.SET_COUNTRIES_INNER_SUCCESS:
            return {
                ...state,
                initialInnerContent: state.innerContent.tracks.map(el => el.id)
            }
        //inner genres
        case PAGES_CONTENT.GET_GENRES_INNER_SUCCESS:
            return {
                ...state,
                innerContent: action.payload.data,
                initialInnerContent: action.payload.data.tracks.map(el => el.id)
            }
        case PAGES_CONTENT.SET_GENRES_INNER_SUCCESS:
            return {
                ...state,
                initialInnerContent: state.innerContent.tracks.map(el => el.id)
            }
        //get available tracks
        case PAGES_CONTENT.GET_AVAILABLE_TRACKS_SUCCESS:
            if (action.meta.previousAction.payload.isPaginate) {
                return {
                    ...state,
                    availableTracks: {
                        ...state.availableTracks,
                        next: action.payload.data.next,
                        results: [
                            ...state.availableTracks.results,
                            ...action.payload.data.results
                        ]
                    }
                }
            } else {
                return {
                    ...state,
                    availableTracks: action.payload.data
                }
            }
        //reorder tracks
        case PAGES_CONTENT.REORDER_TRACKS:
            const { from, to } = action.payload.data

            if (from && to) {
                let tempArr = [...state.innerContent.tracks];

                if (to === 'delete') tempArr.splice(from - 1, 1)
                else tempArr.splice(to - 1, 0, tempArr.splice(from - 1, 1)[0])

                return {
                    ...state,
                    innerContent: {
                        ...state.innerContent,
                        tracks: tempArr
                    }
                }
            }

            return {
                ...state
            }
        //set tracks
        case PAGES_CONTENT.SET_TRACKS:
            return {
                ...state,
                innerContent: {
                    ...state.innerContent,
                    tracks: action.payload.data
                }
            }
        //get availabale packs
        case PAGES_CONTENT.GET_AVAILABLE_PACKS_SUCCESS:
            return {
                ...state,
                availablePacks: action.payload.data
            }
        //copy content
        case PAGES_CONTENT.COPY_INNER_CONTENT_SUCCESS:
            return {
                ...state,
                innerContent: {
                    ...state.innerContent,
                    tracks: action.payload.data.tracks
                }
            }
        default:
            return state;
    }
}
