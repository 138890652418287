import React, { useEffect, useMemo, useState } from 'react';
import { ButtonMUI, DialogMUI, InputMUI, Pagination, Table } from '../../shared';
import styles from './Finances.module.scss';
import additionalStyles from '../Audio/Audio.module.scss';
import { classList, formatNumber } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { calculationAction, getTotal, setAmount } from './storage/financesActions';
import { ReactComponent as Clock } from '../../assets/icons/dollarFinances.svg'
import { ReactComponent as PremIcon } from '../../assets/icons/premiumListen.svg'
import { ReactComponent as RegularIcon } from '../../assets/icons/regularListen.svg'
import { ReactComponent as PendingClock } from '../../assets/icons/pendingClock.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { ReactComponent as SelectIcon } from '../../assets/icons/selectIcon.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg'
import { ReactComponent as AcceptIcon } from '../../assets/icons/financeAcceptButton.svg'
import { ReactComponent as ResetIcon } from '../../assets/icons/resetIcon.svg'
import { Link } from 'react-router-dom';
import { renderArtists } from '../Audio/Audio';
import { mainPath } from '../../routes/paths';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const FinancesTable = ({ action, parameters, setParameters, initialParameters, type, additionalAction }) => {
  const dispatch = useDispatch()
  const { indicators: { can_calculate, calculating, is_paid }, total, financesTable, tableLoading, totalLoading } = useSelector(({ finances }) => finances)
  const prePaymentStatement = useMemo(() => !can_calculate && !calculating && !is_paid, [can_calculate, calculating, is_paid]);
  const [tableCounter, setTableCounter] = useState(0)
  const [editableID, setEditableID] = useState(null)
  const [modalStatus, setModalStatus] = useState({ open: false, type: null })

  const reloadTable = () => setTableCounter(prev => (prev + 1))

  useEffect(() => {
    if (additionalAction) additionalAction()
  }, [tableCounter])

  const columns = [
    {
      name: type === 'historyList' ? 'Date' : 'Name',
      value: type === 'historyList' ? 'created_date' : 'name',
      width: 20,
      renderKey: ['artist', 'created_date', 'id'],
      render: ({ artist, created_date, id }) => (
        type === 'historyList'
          ? <Link
            className={classList('textCell', 'bold')}
            style={{ color: '#13141A' }}
            to={`${mainPath.financesPaymentHistory.path}/${id}`}
          >
            {created_date ? dayjs(created_date).format('DD MMM, YYYY') : '-'}
          </Link>
          : artist && <Link to={`/main/users/${artist?.id}`} className={additionalStyles.nameWrapper}>{
            renderArtists({
              ...artist,
              name: artist.name
                ? artist.name
                : artist.full_name
                  ? artist.full_name
                  : artist.username
                    ? `@${artist.username}`
                    : artist.email,
              username: artist.username ? `@${artist.username}` : artist.email
            })
          }
          </Link>
      )
    },
    ...(total?.plans
      ? total.plans.map(({ name, ordering }, idx) => ({
        name: `“${name}” plan`,
        value: ordering,
        width: (65 / total.plans.length).toFixed(0),
        renderKey: `level_${ordering}_listenings`,
        isLast: idx === total.plans.length - 1,
        render: listenAmount => {
          let isLast = idx === total.plans.length - 1;

          return <div className={styles.listen}>
            {isLast ? <PremIcon /> : <RegularIcon />}
            <p>{listenAmount} <span>listenings</span></p>
          </div>
        }
      }))
      : []),
    {
      name: 'Amount',
      value: 'amount',
      width: 15,
      renderKey: ['amount', 'artist', 'id'],
      render: ({ amount, artist, id }) => <div className={styles.amount}>
        {editableID === (id || artist.id)
          ? <>
            <InputMUI
              size='small'
              type={'number'}
              endAdornment={<span className={styles.endAdornment}>$</span>}
              className={styles.amountInput}
              defaultValue={amount}
            />
            <AcceptIcon
              className={styles.amountAccept}
              onClick={(e) => {
                let input = e.target.closest(`svg`).previousElementSibling.getElementsByTagName('input')[0]
                dispatch(setAmount((id || artist.id), input.value))
                  .then(res => {
                    if (res.type.includes('_SUCCESS')) {
                      setEditableID(null)
                    }
                  })
              }}
            />
          </>
          : <div
            className={classList(styles.amountInner, { [styles.clickable]: prePaymentStatement && type === 'general' })}
            onClick={() => prePaymentStatement && type === 'general' && setEditableID((id || artist.id))}
          >
            ${formatNumber(amount)}
            {prePaymentStatement && type === 'general' && <EditIcon />}
          </div>
        }
      </div>
    }
  ]

  return (<>
    {type !== 'historyList' && <div className={styles.filters}>
      <InputMUI
        className={styles.search}
        id='search'
        type='text'
        placeholder='Search by name or username...'
        startAdornment={<SearchIcon />}
        size='small'
        name={`advertising_search`}
        value={parameters.search}
        onChange={({ target: { value } }) => setParameters(prev => ({ ...prev, search: value }))}
      />
      {type === 'general' && (<>
        {prePaymentStatement
          ? <div className={styles.paymentState}>
            <div className={styles.availableTime}>
              <div>
                <span>Payout ratio</span>
                <p>{total?.ratio ? total?.ratio : '-'}</p>
              </div>
            </div>
            <ButtonMUI
              size='small'
              color='primary'
              children={'Reset calculation'}
              onClick={() => setModalStatus({ open: true, type: 'calculation' })}
            />
            <ButtonMUI
              size='small'
              className={styles.calculateBtn}
              children={<><SelectIcon />Make a payment</>}
              onClick={() => setModalStatus({ open: true, type: 'payment' })}
            />
          </div>
          : <>
            <p className={styles.lastPayment}>Last payment: {total?.created_date ? dayjs(total?.created_date).format('DD MMM, YYYY') : '-'}</p>
            <ButtonMUI
              variant='text'
              href={mainPath.financesPaymentHistory.path}
            >
              <PendingClock /> Payment history
            </ButtonMUI>
          </>}
      </>)}
    </div>}
    <Table
      action={action}
      params={parameters}
      setParams={setParameters}
      columns={columns}
      rows={financesTable?.results}
      noRowsComponent={
        <div className={classList(styles.paymentBlock, styles.isCalculating)}>
          <div className={styles.calculating}>
            <Clock />
            <div className={styles.paymentBlockInfo}>
              <p className={styles.smallHeading}>No payments {type !== 'historyInner' ? 'yet' : `for ${!!parameters?.search?.length ? 'entered search query' : 'selected period'}`}</p>
              {type !== 'historyInner' && <span>Make your first payment and<br />
                its details will show up here</span>}
            </div>
          </div>
        </div>
      }
      afterHeadComponent={type === 'historyList' ? null : ({ TDclass }) => <tfoot className={styles.totalWrapper}><tr className={classList(styles.totalRow)}>
        {columns.map((el, idx) => {
          if (el.value === 'name') return <td className={classList(TDclass, styles.totalData)} key={idx}><p>Total</p></td>
          if (el.name.includes('plan')) return (
            <td className={classList(TDclass, styles.totalData)} key={idx}>
              <div className={styles.totalPlan}>
                <p>{total[`level_${el.value}_amount`] ? `$${formatNumber(total[`level_${el.value}_amount`])}` : '-'}</p>
                <div>
                  {el.isLast ? <PremIcon /> : <RegularIcon />}
                  <span>{total[`level_${el.value}_listenings`] ? `${formatNumber(total[`level_${el.value}_listenings`])}` : '-'}</span>
                </div>
              </div>
            </td>
          )
          if (el.value === 'amount') return (
            <td className={classList(TDclass, styles.totalData)} key={idx}>
              <div className={styles.totalPlan}>
                <p>
                  {total?.amount ? `$${formatNumber(total.amount)}` : '-'}
                  {total?.amount !== total?.init_amount && prePaymentStatement && type === 'general' && (
                    <ResetIcon
                      style={{ margin: '0 0 -2px 4px', cursor: 'pointer' }}
                      onClick={() => setModalStatus({ open: true, type: 'changes' })}
                    />
                  )}
                </p>
                {total?.amount !== total?.init_amount && (
                  <div>
                    <span>Initial: {total?.init_amount ? `$${formatNumber(total.init_amount)}` : '-'}</span>
                  </div>
                )}
              </div>
            </td>
          )
          return null
        })}
      </tr></tfoot>}
      rowsCount={financesTable?.count}
      loading={tableLoading || totalLoading}
      className={classList(
        styles.tableRoot,
        {
          [styles.noMargin]: financesTable?.results || type === 'historyList',
          [styles.inProgress]: calculating
        }
      )}
      classes={{
        data: styles.data,
        row: styles.row,
        head: classList({ [styles.head]: type !== 'historyList' })
      }}
      reloadDependency={tableCounter}
    />
    <Modals
      {...modalStatus}
      reloadTable={reloadTable}
      onClose={() => setModalStatus(prev => ({ ...prev, open: false }))}
    />
  </>);
};

const Modals = ({ open, onClose, type, reloadTable }) => {
  const dispatch = useDispatch()
  const { total } = useSelector(({ finances }) => finances);
  const [btnLoad, setBtnLoad] = useState(false)

  const renderModalByType = () => {
    switch (type) {
      case 'payment':
        return (<>
          <h1>Make a payment</h1>
          <span>Confirm that you want to make a <b>${formatNumber(total.amount)}</b> payment. Funds will be credited to users' balances.</span>
        </>)
      case 'changes':
        return (<>
          <h1>Reset changes</h1>
          <span>Confirm that you want to reset all edited amounts and revert to the initially calculated data.</span>
        </>)
      case 'calculation':
        return (<>
          <h1>Reset calculation</h1>
          <span>Are you sure you want to reset the calculation? You will need to recalculate payouts to make a payment.</span>
        </>)
      default:
        return null;
    }
  }

  const submit = () => {
    setBtnLoad(true)
    dispatch(calculationAction(type))
      .then(res => {
        setBtnLoad(false)
        if (res.type.includes('_SUCCESS')) {
          if (!!reloadTable && type !== 'changes') reloadTable()
          onClose()
        }
      })
  }

  return (
    <DialogMUI
      open={open && !!type}
      onClose={onClose}
    >
      <div className={styles.paymentBlockModal}>
        {renderModalByType()}
        <hr />
        <div className={styles.buttons}>
          <ButtonMUI
            size='small'
            className={styles.calculateBtn}
            children={type === 'calculation' ? 'Yes, reset' : 'Confirm'}
            onClick={submit}
            loading={btnLoad}
          />
          <ButtonMUI
            size='small'
            color='primary'
            children={type === 'calculation' ? 'No, cancel' : 'Cancel'}
            onClick={onClose}
          />
        </div>
      </div>
    </DialogMUI>
  )
}

export default FinancesTable;
