export const FINANCES = {
    GET_FINANCES_INDICATORS: 'GET_FINANCES_INDICATORS',
    GET_FINANCES_INDICATORS_SUCCESS: 'GET_FINANCES_INDICATORS_SUCCESS',
    GET_FINANCES_INDICATORS_FAIL: 'GET_FINANCES_INDICATORS_FAIL',

    GET_TOTAL: 'GET_TOTAL',
    GET_TOTAL_SUCCESS: 'GET_TOTAL_SUCCESS',
    GET_TOTAL_FAIL: 'GET_TOTAL_FAIL',

    GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY',
    GET_PAYMENT_HISTORY_SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
    GET_PAYMENT_HISTORY_FAIL: 'GET_PAYMENT_HISTORY_FAIL',

    GET_PAYMENT_HISTORY_INNER: 'GET_PAYMENT_HISTORY_INNER',
    GET_PAYMENT_HISTORY_INNER_SUCCESS: 'GET_PAYMENT_HISTORY_INNER_SUCCESS',
    GET_PAYMENT_HISTORY_INNER_FAIL: 'GET_PAYMENT_HISTORY_INNER_FAIL',
    
    GET_PAYMENT_HISTORY_INNER_TOTAL: 'GET_PAYMENT_HISTORY_INNER_TOTAL',
    GET_PAYMENT_HISTORY_INNER_TOTAL_SUCCESS: 'GET_PAYMENT_HISTORY_INNER_TOTAL_SUCCESS',
    GET_PAYMENT_HISTORY_INNER_TOTAL_FAIL: 'GET_PAYMENT_HISTORY_INNER_TOTAL_FAIL',

    GET_FINANCES_TABLE: 'GET_FINANCES_TABLE',
    GET_FINANCES_TABLE_SUCCESS: 'GET_FINANCES_TABLE_SUCCESS',
    GET_FINANCES_TABLE_FAIL: 'GET_FINANCES_TABLE_FAIL',

    SET_CALCULATING: 'SET_CALCULATING',
    SET_CALCULATING_SUCCESS: 'SET_CALCULATING_SUCCESS',
    SET_CALCULATING_FAIL: 'SET_CALCULATING_FAIL',

    SET_AMOUNT: 'SET_AMOUNT',
    SET_AMOUNT_SUCCESS: 'SET_AMOUNT_SUCCESS',
    SET_AMOUNT_FAIL: 'SET_AMOUNT_FAIL',

    RESET_CALCULATION: 'RESET_CALCULATION',
    RESET_CALCULATION_SUCCESS: 'RESET_CALCULATION_SUCCESS',
    RESET_CALCULATION_FAIL: 'RESET_CALCULATION_FAIL',

    RESET_CHANGES: 'RESET_CHANGES',
    RESET_CHANGES_SUCCESS: 'RESET_CHANGES_SUCCESS',
    RESET_CHANGES_FAIL: 'RESET_CHANGES_FAIL',

    MAKE_PAYMENT: 'MAKE_PAYMENT',
    MAKE_PAYMENT_SUCCESS: 'MAKE_PAYMENT_SUCCESS',
    MAKE_PAYMENT_FAIL: 'MAKE_PAYMENT_FAIL',
};
