import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from '../features/Auth/authReducer';
import audioReducer from '../features/Audio/storage/audioReducer';
import supportReducer from '../features/Support/storage/supportReducer';
import advertisingReducer from '../features/Advertising/storage/advertisingReducer';
import usersReducer from '../features/Users/storage/usersReducer';
import pagesContentReducer from '../features/PagesContent/storage/pagesContentReducer';
import financesReducer from '../features/Finances/storage/financesReducer';
import { APP } from './appActionTypes';

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: '',
  successSnack: '',
  successSnackText: '',
  customBackUrl: ''
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP.LOADING:
      return { ...state, loading: action.payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case APP.CUSTOM_BACK:
      return { ...state, customBackUrl: action.payload.data };
    default:
      return state;
  }
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
    audio: audioReducer,
    support: supportReducer,
    advertising: advertisingReducer,
    users: usersReducer,
    pagesContent: pagesContentReducer,
    finances: financesReducer
  });

export default rootReducer;
