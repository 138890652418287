import { useParams } from 'react-router-dom';
import styles from './Feed.module.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFeed } from '../storage/usersActions';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RenderPost, PostModal, DeleteDialog } from './';
dayjs.extend(utc)

const Feed = () => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { feed } = useSelector(({ users }) => ({ feed: users.userFeed.results }))

    useEffect(() => {
        dispatch(getUserFeed(userId))
    }, [userId])

    return <div className={styles.feed}>
        {feed.map((el, idx) => {
            if (!!el) return < RenderPost {...{ el, idx }} key={idx} />
        })}
        <PostModal />
        <DeleteDialog />
    </div>
}

export default Feed