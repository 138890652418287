import React, { useEffect, useState } from 'react';
import { ButtonMUI, InputMUI, SelectComponent, TabItem, Table, Tabs, DialogMUI, TooltipMUI } from '../../shared';
import styles from './Advertising.module.scss';
import additionalStyles from '../Audio/Audio.module.scss';
import { classList, formatFileSize, objectToQueryString, prettyScroll, formatAlbumTime } from '../../helpers/functions';
import { ReactComponent as AudioSettings } from '../../assets/icons/audio_setting.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'
import { ReactComponent as OpenLinkIcon } from '../../assets/icons/open_link.svg'
import { ReactComponent as Verified } from '../../assets/icons/verified.svg'
import { ReactComponent as PartiallyBlocked } from '../../assets/icons/partiallyBlocked.svg'
import { ReactComponent as Blocked } from '../../assets/icons/block.svg'
import { ReactComponent as Active } from '../../assets/icons/active.svg'
import { ReactComponent as BlockAction } from '../../assets/icons/block_action.svg'
import { ReactComponent as UnblockAction } from '../../assets/icons/unblock_action.svg'
import { ReactComponent as DeleteAction } from '../../assets/icons/delete_action.svg'
import { ReactComponent as SendFail } from '../../assets/icons/send-failed.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit_ico.svg'
import profile from '../../assets/icons/profile.svg'
import { advertisingSettings, deleteAdvertising, getAdvertising } from './storage/advertisingActions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
import { ClickAwayListener, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Advertising = () => {
  const dispatch = useDispatch()
  const { advertising_loading, advertising, tracks_count } = useSelector(({ advertising }) => advertising)
  const [isFirst, setIsFirst] = useState(true)
  const [tab, setTab] = useState('tracks')
  const [parameters, setParameters] = useState(initialParameters)
  const [contextMenu, setContextMenu] = useState(false)
  const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
  const [btnLoad, setBtnLoad] = useState(false)
  const [settingDialog, setSettingDialog] = useState(false)

  const paramsSetter = (key, value) => {
    setParameters((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    dispatch(advertisingSettings('GET'))
  }, [])

  useEffect(() => {
    if (!dialogStatus.status) setTimeout(() => setDialogStatus(prev => ({ ...prev, type: null, target: null })), 250)
  }, [dialogStatus.status])

  const contextMenuActions = (action, id) => {
    if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
    else if ('open') setContextMenu(prev => prev === id ? false : id)
  }

  const openDialog = type => {
    setDialogStatus({ status: true, type: type, target: advertising?.results.find(el => el.id === contextMenu) })
    contextMenuActions('close', contextMenu)
  }

  const submitDialog = () => {
    setBtnLoad(true)
    dispatch(deleteAdvertising(dialogStatus.target.id)).then(({ type }) => {
      if (type.includes('SUCCESS')) setDialogStatus(prev => ({ ...prev, status: false }))
      setBtnLoad(false)
    })
  }

  const submitSettings = () => {
    setBtnLoad(true)
    dispatch(advertisingSettings('PUT', tracks_count)).then(({ type }) => {
      if (type.includes('SUCCESS')) setSettingDialog(false)
      setBtnLoad(false)
    })
  }

  const columns = [
    {
      name: 'Advertiser',
      value: 'name',
      width: 30,
      renderKey: ['name', 'id', 'has_broken_tracks'],
      render: ({ name, id, has_broken_tracks }) => <Link to={`/main/advertising/${id}`} className={styles.nameWrapper}>
        <div className={styles.info}>
          <p>{name}</p>
          {!!has_broken_tracks && <TooltipMUI arrow className={styles.tooltip} title={'Error processing audio'}>
            <SendFail />
          </TooltipMUI>}
        </div>
      </Link>
    },
    {
      name: 'Email',
      value: 'email',
      width: 30,
      renderKey: 'email',
      render: email => {
        return <div className={'cellText'}>{!!email ? email : '-'}</div>
      }
    },
    {
      name: 'Phone number',
      value: null,
      width: 30,
      renderKey: 'phone_number',
      render: phone_number => {
        return <div className={'cellText'}>{!!phone_number ? phone_number : '-'}</div>
      }
    },
    {
      name: '',
      value: null,
      width: 10,
      renderKey: 'id',
      render: id => <div className={styles.moreActionsWrapper}>
        <ClickAwayListener onClickAway={() => contextMenuActions('close', id)}>
          <div className={styles.moreActions}>
            <button className={classList(styles.moreActions__btn, { [styles.active]: contextMenu === id })} onClick={() => contextMenuActions('open', id)}>
              <span />
              <span />
              <span />
            </button>
            {contextMenu === id && <div className={styles.moreActions__container}>
              <Link
                className={classList(styles.actionButtons)}
                to={`/main/advertising/${id}/edit`}
              >
                <EditIcon />Edit
              </Link>
              <button
                className={classList(styles.actionButtons, styles.deleteAction)}
                onClick={() => openDialog('delete')}
              >
                <DeleteAction />Delete
              </button>
            </div>}
          </div>
        </ClickAwayListener>
      </div>
    }
  ]

  return (
    <main className={`${styles.advertising} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.advertisingInner} container`}>
        <h1>Advertising</h1>
        <div className={`inner-wrap ${styles['inner-wrap']}`}>
          <div className={styles.filters}>
            <InputMUI
              className={styles.search}
              id='search'
              type='text'
              placeholder='Search by advertiser...'
              startAdornment={<SearchIcon />}
              size='small'
              name={`advertising_search`}
              value={parameters.search}
              onChange={({ target: { value } }) => paramsSetter('search', value)}
            />
            <ButtonMUI
              variant='text'
              onClick={() => setSettingDialog(true)}
            >
              <AudioSettings /> Settings
            </ButtonMUI>
            <ButtonMUI
              href={`/main/advertising/new`}
              size={'small'}
              className={styles.addNewButton}
            >
              <span className={styles.sign}>+</span> New advertiser
            </ButtonMUI>
          </div>
          <Table
            action={getAdvertising}
            params={parameters}
            setParams={setParameters}
            columns={columns}
            rows={advertising?.results}
            rowsCount={advertising?.count}
            loading={advertising_loading}
          />
        </div>
      </div>
      <DeleteModal
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
        btnLoad={btnLoad}
        submitDialog={submitDialog}
      />
      <DialogMUI
        open={settingDialog}
        onClose={() => setSettingDialog(false)}
        maxWidth={'xs'}
      >
        <>
          <h2 className={additionalStyles.dialogTitle}>Advertising settings</h2>
          <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
            <p className={additionalStyles.desc}>
              Specify the number of tracks after which advertising audio should play for non-subscription users
            </p>
            <div className={classList(additionalStyles.inputLabel, styles.inputLabel)}>
              <p className={additionalStyles.inputLabel__title}>Play ads after every</p>
              <div className={classList(additionalStyles.inputLabel__wrapper, styles.inputLabel__wrapper)}>
                <InputMUI
                  type={'number'}
                  endAdornment={<span className={styles.endAdornment}>tracks</span>}
                  value={tracks_count}
                  onChange={e => dispatch(advertisingSettings('SET', e.target.value))}
                />
              </div>
            </div>
            <div className={additionalStyles.separator} />
            <div className={additionalStyles.button_wrapper}>
              <ButtonMUI
                variant='contained'
                size="small"
                className={additionalStyles.dialogButtons}
                onClick={submitSettings}
                loading={btnLoad}
                disabled={btnLoad}
              >
                Save
              </ButtonMUI>
              <ButtonMUI
                variant='contained'
                size="small"
                className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
                onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
              >
                Cancel
              </ButtonMUI>
            </div>
          </div>
        </>
      </DialogMUI>
    </main>
  );
};

export const DeleteModal = ({ dialogStatus, setDialogStatus, btnLoad, submitDialog }) => <DialogMUI
  open={dialogStatus.status}
  onClose={() => setDialogStatus(prev => ({ ...prev, status: false }))}
  maxWidth={'xs'}
>
  <>
    <h2 className={additionalStyles.dialogTitle}>Delete advertiser</h2>
    <div className={classList(additionalStyles.dialogWrapper, additionalStyles.delete)}>
      <p className={additionalStyles.desc}>
        Are you sure you want to delete{' '}<span>{dialogStatus?.target?.name}</span>? This action cannot be undone.
      </p>
      <div className={additionalStyles.separator} />
      <div className={additionalStyles.button_wrapper}>
        <ButtonMUI
          variant='contained'
          size="small"
          className={additionalStyles.dialogButtons}
          onClick={submitDialog}
          loading={btnLoad}
          disabled={btnLoad || !dialogStatus.status}
        >
          Delete
        </ButtonMUI>
        <ButtonMUI
          variant='contained'
          size="small"
          className={classList(additionalStyles.dialogButtons, additionalStyles.cancel)}
          onClick={() => setDialogStatus(prev => ({ ...prev, status: false }))}
        >
          Cancel
        </ButtonMUI>
      </div>
    </div>
  </>
</DialogMUI>

const initialParameters = {
  page_size: 10,
  ordering: 'name', // name | -name | email | -email
  search: '',
}

export default Advertising;
