import styles from './Feed.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Autoplay, Navigation } from 'swiper';
import 'swiper/css/bundle';
import arrowIcon from '../../../assets/icons/nextSlide_icon.svg'

const ImagesSlider = ({ images, imageIdx }) => {
    SwiperCore.use([A11y, Autoplay, Navigation]);
    return (
        <Swiper
            navigation={{ prevEl: '.image_swiper__prev', nextEl: '.image_swiper__next' }}
            watchOverflow
            className={styles.image_swiper}
            initialSlide={imageIdx}
        >
            {images.map(({ image }, idx) => (
                <SwiperSlide key={idx} className={styles.slide}>
                    <div className={styles.post__image}>
                        <img src={image} alt='post_img' />
                        <div />
                        <img src={image} alt='post_img' />
                    </div>
                </SwiperSlide>
            ))}
            <div className={styles.image_swiper__navigation}>
                <button className={`${styles.image_swiper__prev} image_swiper__prev`} aria-label='Prev slide'>
                    <img src={arrowIcon} alt='arrowIcon' />
                </button>
                <button className={`${styles.image_swiper__next} image_swiper__next`} aria-label='Next slide'>
                    <img src={arrowIcon} alt='arrowIcon' />
                </button>
            </div>
        </Swiper>
    );
};

export default ImagesSlider