import React, { useEffect, useState } from 'react';
import styles from './Finances.module.scss';
import { prettyScroll } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import { getFinancesIndicators, getFinances, getTotal } from './storage/financesActions';
import PaymentBlock from './PaymentBlock';
import FinancesTable from './FinancesTable';

const Finances = () => {
  const dispatch = useDispatch()
  const [parameters, setParameters] = useState(initialParameters)

  useEffect(() => {
    dispatch(getFinancesIndicators())
  }, [])

  return (
    <main className={`${styles.finances} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.financesInner} container`}>
        <h1>Finances</h1>
        <div className={`inner-wrap ${styles['inner-wrap']}`}>
          <p className={styles.smallHeading}>Income distribution</p>
          <PaymentBlock />
          <FinancesTable
            parameters={parameters}
            setParameters={setParameters}
            initialParameters={initialParameters}
            action={getFinances}
            type={'general'}
            additionalAction={() => dispatch(getTotal())}
          />
        </div>
      </div>
    </main>
  );
};

export const initialParameters = {
  page_size: 10,
  ordering: 'name', // name | -name | amount | -amount
  search: '',
}

export default Finances;
