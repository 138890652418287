import { takeEvery } from 'redux-saga/effects';
import { openErrorSnack, openSuccessSnack } from '../../../app/rootSaga';

const prettySnackMessage = (e) => {
  const { name, period } = e.payload.config.snackData;
  return openSuccessSnack(`Top ${period} songs for ${name} was successfully saved`)
}

export function* handlePageContentSnacks() {
  yield takeEvery('SET_COUNTRIES_INNER_SUCCESS', prettySnackMessage);
  yield takeEvery('SET_GENRES_INNER_SUCCESS', prettySnackMessage);
  yield takeEvery('CREATE_GENRE_SUCCESS', ({ payload: { data: { name } } }) => openSuccessSnack(`Genre "${name}" was successfully created`));
  yield takeEvery('CREATE_GENRE_FAIL', ({ error: { response: { data } } }) => openErrorSnack(Object.values(data).flat().join(';\n')));
}