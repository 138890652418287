import { USERS } from './usersActionTypes';

export function getUsers(url) {
    return {
        type: USERS.GET_USERS,
        payload: {
            client: 'default',
            request: {
                url: `admin/users/list${!!url && url.length > 0 ? url : ''}`,
                method: 'GET',
            },
        },
    };
}

export function getUserProfile(id) {
    return {
        type: USERS.GET_USER_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `admin/users/${id}/profile`,
                method: 'GET',
            },
        },
    };
}

export function getUserStatistic(id) {
    return {
        type: USERS.GET_USER_STATISTIC,
        payload: {
            client: 'default',
            request: {
                url: `admin/users/${id}/statistics`,
                method: 'GET',
            },
        },
    };
}

export function getUserFeed(id, cursor) {
    return {
        type: USERS.GET_USER_FEED,
        payload: {
            client: 'default',
            request: {
                url: `admin/newsfeed/users/${id}/posts${!!cursor ? `?cursor=${cursor}` : ''}`,
                method: 'GET',
                isPaginate: !!cursor
            },
        },
    };
}

export function userActions(action, id, blockReason) {
    const returnableObj = (url, data = undefined) => ({
        type: USERS[`${action.toUpperCase()}_USER`],
        payload: {
            client: 'default',
            request: {
                url: `admin/users/${id}/${url}`,
                method: action === 'delete' ? action.toUpperCase() : 'PUT',
                data: data,
                userID: id,
                userAction: action
            },
        },
    })
    if (action.includes('verify')) {
        return returnableObj('verified', {
            is_verified: action.toLowerCase() === 'verify'
        })
    } else if (action.includes('block')) {
        return returnableObj('blocked', {
            is_blocked: action.toLowerCase() === 'block',
            blocked_text: blockReason
        })
    } else return returnableObj('delete')
}

export const setPostModal = (id, imageIndex) => ({
    type: USERS.SET_POST_MODAL,
    payload: { id, imageIndex }
})

export const getPost = id => ({
    type: USERS.GET_POST,
    payload: {
        client: 'default',
        request: {
            url: `admin/newsfeed/posts/${id}`,
            method: 'GET',
        },
    },
})

export const deletePostModal = id => ({
    type: USERS.DELETE_POST_MODAL,
    payload: { id }
})

export const deletePost = id => ({
    type: USERS.DELETE_POST,
    payload: {
        client: 'default',
        request: {
            url: `admin/newsfeed/posts/${id}`,
            id,
            method: 'DELETE'
        },
    },
})

export const getPostComments = (id, cursor) => ({
    type: USERS.GET_POST_COMMENTS,
    payload: {
        client: 'default',
        request: {
            url: `admin/newsfeed/posts/${id}/comments${!!cursor ? `?cursor=${cursor}` : ''}`,
            isPaginate: !!cursor,
            method: 'GET',
        },
    },
})

export const deleteCommentAction = (id, indexes) => ({
    type: USERS.DELETE_POST_COMMENTS,
    payload: {
        client: 'default',
        request: {
            url: `admin/newsfeed/comments/${id}`,
            indexes,
            method: 'DELETE'
        },
    },
})