import { useParams, Link, useHistory } from 'react-router-dom';
import templateStyles from '../Audio/Track/Track.module.scss';
import styles from './Advertising.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { classList, prettyScroll } from '../../helpers/functions';
import StatisticSidebar from './StatisticSidebar';
import DetailsMaper from '../Audio/DetailsMaper';
import { deleteAdvertising, getAdvertiser, getAdvertiserCampaigns } from './storage/advertisingActions';
import { ClickAwayListener } from '@material-ui/core';
import { DeleteModal } from './Advertising';
import { ReactComponent as DeleteAction } from '../../assets/icons/delete_action.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit_ico.svg'
import { ReactComponent as Plus } from '../../assets/icons/adver_plus.svg'
import { ReactComponent as SendFail } from '../../assets/icons/send-failed.svg'
import { ReactComponent as ActiveCampaign } from '../../assets/icons/active_campaign.svg'
import { ReactComponent as ArchivedCampaign } from '../../assets/icons/archived_campaign.svg'
import { ReactComponent as ArchiveActionButton } from '../../assets/icons/archive_action.svg'
import { ReactComponent as RestoreActionButton } from '../../assets/icons/restore_action.svg'
import nonAvatar from '../../assets/images/adver_non_avatar.svg'
import { ButtonMUI, TooltipMUI } from '../../shared';
import { mainPath } from '../../routes/paths';
import Campaigns from './Campaigns';

const AdvertisingInner = () => {
    const dispatch = useDispatch();
    const { advertiserId } = useParams();
    const history = useHistory();
    const { editAdvertiser } = useSelector(({ advertising }) => advertising)
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        dispatch(getAdvertiser(advertiserId))
    }, [advertiserId])

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if (action === 'open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: editAdvertiser })
        contextMenuActions('close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(deleteAdvertising(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
                history.push(mainPath.advertising.path)
            }
        })
    }

    return <main className={`${templateStyles.track} container`}>
        <div className={`${templateStyles.trackInner} page-wrap`} ref={prettyScroll}>
            <div className={styles.advertisingInnerHeading}>
                <div className={styles.advertisingInnerHeading__generalInfo}>
                    <h1>{editAdvertiser.name}</h1>
                    <span>{editAdvertiser.email} • {editAdvertiser.phone_number}</span>
                </div>
                <div className={styles.moreActionsWrapper}>
                    <ClickAwayListener onClickAway={() => contextMenuActions('close', advertiserId)}>
                        <div className={styles.moreActions}>
                            <button
                                className={classList(styles.moreActions__btn, { [styles.active]: contextMenu.toString() === advertiserId })}
                                onClick={() => contextMenuActions('open', advertiserId)}
                            >
                                <span />
                                <span />
                                <span />
                            </button>
                            {contextMenu.toString() === advertiserId && <div className={styles.moreActions__container}>
                                <Link
                                    className={classList(styles.actionButtons)}
                                    to={`/main/advertising/${advertiserId}/inner-edit`}
                                >
                                    <EditIcon />Edit
                                </Link>
                                <button
                                    className={classList(styles.actionButtons, styles.deleteAction)}
                                    onClick={() => openDialog('delete')}
                                >
                                    <DeleteAction />Delete
                                </button>
                            </div>}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
            <DetailsMaper arr={createDetails(editAdvertiser)} />
            <Campaigns />
        </div>
        <StatisticSidebar advertiserId={advertiserId} />
        <DeleteModal
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            btnLoad={btnLoad}
            submitDialog={submitDialog}
        />
    </main>
}

const createDetails = target => (JSON.stringify(target) === '{}' ? [] : [
    {
        title: 'Bank details',
        value: target.bank_details,
        size: 'xl',
    },
    {
        title: 'Legal details',
        value: target.legal_details,
        size: 'xl',
    }
])

export default AdvertisingInner