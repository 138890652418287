import { ADVERTISING } from './advertisingActionTypes';
import { editAdvertiser } from './advertisingActions';
export const initialFields = {
    name: '',
    email: '',
    phone_number: '',
    bank_details: '',
    legal_details: ''
}

export const initialCampaignsFields = {
    name: '',
    start_date: null,
    end_date: null,
    agreement_date: null,
    image: null,
    description: '',
    budget: '',
    age: null,
}

const INITIAL_STATE = {
    advertising: {
        count: 0,
        results: []
    },
    advertising_loading: true,
    editAdvertiser: initialFields,
    editAdvertiser_loading: true,
    tracks_count: 0,
    advertiserStatistic: {
        listen_count: 0,
        listen_time: 0
    },
    advertiserCampaigns: {},
    editCampaign: initialCampaignsFields,
    editCampaign_loading: true,
    campaignStatistic: {
        listen_count: 0,
        listen_time: 0
    },
    campaignTracks: { count: 0, results: [] }
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADVERTISING.GET_ADVERTISING:
            return { ...state, advertising_loading: true };
        case ADVERTISING.GET_ADVERTISING_SUCCESS:
            return {
                ...state,
                advertising: action.payload.data,
                advertising_loading: false
            };

        case ADVERTISING.DELETE_ADVERTISING_SUCCESS:
            return {
                ...state,
                advertising: {
                    ...state.advertising,
                    results: [...state.advertising.results.filter(el => el.id !== action.payload.config.id)]
                },
            };
        case ADVERTISING.GET_ADVERTISER:
            return {
                ...state,
                editAdvertiser_loading: true
            };
        case ADVERTISING.GET_ADVERTISER_SUCCESS:
            return {
                ...state,
                editAdvertiser: action.payload.data,
                editAdvertiser_loading: false
            };
        case ADVERTISING.GET_ADVERTISER_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                advertiserCampaigns: action.payload.data
            };
        case ADVERTISING.DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                advertiserCampaigns: {
                    ...state.advertiserCampaigns,
                    results: state.advertiserCampaigns.results.filter(el => el.id !== action.payload.config.id)
                }
            };
        case ADVERTISING.ARCHIVE_CAMPAIGN_SUCCESS:
            console.log(state.editAdvertiser?.id, action.payload.config.id)
            return {
                ...state,
                advertiserCampaigns: {
                    ...state.advertiserCampaigns,
                    results: state.advertiserCampaigns.results.map(el => el.id === action.payload.config.id ? { ...el, is_archived: true } : el)
                },
                editCampaign: state.editCampaign?.id === action.payload.config.id ? { ...state.editCampaign, is_archived: true } : state.editCampaign
            };
        case ADVERTISING.GET_ADVERTISER_STATISTIC_SUCCESS:
            return {
                ...state,
                advertiserStatistic: action.payload.data,
            };
        case ADVERTISING.GET_CAMPAIGN_STATISTIC_SUCCESS:
            return {
                ...state,
                campaignStatistic: action.payload.data,
            };
        case ADVERTISING.GET_CAMPAIGN_TRACKS_SUCCESS:
            return {
                ...state,
                campaignTracks: action.payload.data,
            };
        case ADVERTISING.UPLOAD_CAMPAIGN_TRACK_SUCCESS:
            let file = action.meta.previousAction.payload.request.data.get('file'),
                { name, size } = file,
                id = action.payload.data.id;

            return {
                ...state,
                campaignTracks: {
                    ...state.campaignTracks,
                    count: state.campaignTracks.count + 1,
                    results: [
                        ...state.campaignTracks.results,
                        {
                            file_size: size,
                            name: name,
                            id: id,
                            status: 'submitted'
                        }
                    ]
                },
            };
        case ADVERTISING.UPDATE_CAMPAIGN_TRACK:
            state.campaignTracks.results.splice(
                state.campaignTracks.results.findIndex(el => el.id === action.payload.data.id),
                1,
                { ...state.campaignTracks.results.find(el => el.id === action.payload.data.id), ...action.payload.data }
            )

            return { ...state };
        case ADVERTISING.UPDATE_CAMPAIGN_TRACK_ON_DB:
            state.campaignTracks.results.splice(
                state.campaignTracks.results.findIndex(el => el.id === action.payload.request.localData.id),
                1,
                { ...state.campaignTracks.results.find(el => el.id === action.payload.request.localData.id), ...action.payload.request.localData }
            )

            return { ...state };
        case ADVERTISING.DELETE_CAMPAIGN_TRACK_SUCCESS:
            state.campaignTracks.results.splice(state.campaignTracks.results.findIndex(el => el.id === action.payload.config.id), 1) //delete

            return { ...state };
        case ADVERTISING.EDIT_ADVERTISER:
            return {
                ...state,
                editAdvertiser: { ...state.editAdvertiser, ...action.payload.data },
            };
        case ADVERTISING.EDIT_CAMPAIGN:
        case ADVERTISING.GET_CAMPAIGN_SUCCESS:
        case ADVERTISING.PUT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                editCampaign: { ...state.editCampaign, ...action.payload.data },
                editCampaign_loading: false
            };

        case ADVERTISING.ADVERTISING_SETTINGS_SUCCESS:
        case ADVERTISING.ADVERTISING_SETTINGS_PUT_SUCCESS:
            return {
                ...state,
                tracks_count: action.payload.data.tracks_count,
            };

        default:
            return state;
    }
}