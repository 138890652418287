import { useState } from 'react';
import styles from './Heading.module.scss';
import parentStyles from '../Audio.module.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMUI } from '../../../shared'
import { renderAlbumInfo, renderArtists } from '../Audio';
import { ReactComponent as OpenLinkIcon } from '../../../assets/icons/open_link.svg'
import { ReactComponent as BlockAction } from '../../../assets/icons/block_action.svg'
import { ReactComponent as UnblockAction } from '../../../assets/icons/unblock_action.svg'
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import { ReactComponent as BigBlock } from '../../../assets/icons/big_block.svg'
import { ReactComponent as BackArrow } from '../../../assets/icons/header_back_arrow.svg';
import noTrackImg from '../../../assets/images/nonAvatars/track.svg'
import noAlbumImg from '../../../assets/images/nonAvatars/album.svg'
import { classList } from '../../../helpers/functions';
import { ClickAwayListener } from '@material-ui/core';
import ActionDialog from '../ActionDialog';
import { CLIENT_URL } from '../../../config';

const Heading = ({ trackId, type }) => {
    const dispatch = useDispatch();
    const target = useSelector(({ audio }) => audio[`${type}Info`])
    const [viewMore, setViewMore] = useState('first')
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: target })
        contextMenuActions('close', contextMenu)
    }

    useEffect(() => {
        if (viewMore === 'first') {
            setViewMore(true)
        } else {
            if (!!document.querySelector(`.${styles.albumDesc}`) && document.querySelector(`.${styles.textWrap} > p`).offsetHeight > document.querySelector(`.${styles.textWrap}`).offsetHeight) {
                setViewMore(true)
            } else setViewMore(false)
        }
    }, [target])

    return <div className={styles.heading}>
        <div
            className={classList(styles.imageWrapper, { [styles.withPrice]: !!target.price })}
            data-price={target.price}
        >
            <img src={type === 'track' ? target.image || noTrackImg : target.image || noAlbumImg} alt={target.name} />
        </div>
        <div className={styles.shortInfo}>
            <h1>{target.name}</h1>
            <div className={styles.lowerBlock}>
                <div className={styles.artistBlock}>
                    {renderArtists(target[`artist${type === 'track' ? 's' : ''}`], true)}
                    {renderAlbumInfo(target, `${type}s`)}
                </div>
                <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
                    {type === 'track'
                        ? <a href={`${CLIENT_URL}/main/track/${target.artists?.find(el => el.is_main)?.username?.replace('@', '')}/${target.slug}`} target='_blank'><OpenLinkIcon /></a>
                        : <a href={`${CLIENT_URL}/main/albums/${target.artist?.username?.replace('@', '')}/${target.slug}`} target='_blank'><OpenLinkIcon /></a>
                    }
                    {target.is_blocked || target.is_partially_blocked
                        ? <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons)}
                            onClick={() => openDialog('unblock')}
                        >
                            Unblock {target.is_partially_blocked && 'all'}
                        </ButtonMUI>
                        : <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons, styles.block)}
                            onClick={() => openDialog('block')}
                        >
                            Block
                        </ButtonMUI>}
                    <ClickAwayListener onClickAway={() => contextMenuActions('close', trackId)}>
                        <div className={parentStyles.moreActions}>
                            <button className={classList(parentStyles.moreActions__btn, { [parentStyles.active]: contextMenu === trackId })} onClick={() => contextMenuActions('open', trackId)}>
                                <span />
                                <span />
                                <span />
                            </button>
                            {contextMenu === trackId && <div className={parentStyles.moreActions__container}>
                                {target.is_partially_blocked
                                    ? <>
                                        {/* <button
                                            className={classList(parentStyles.actionButtons)}
                                            onClick={() => openDialog('unblock')}
                                        >
                                            <UnblockAction />Unblock all tracks
                                        </button> */}
                                        <button
                                            className={classList(parentStyles.actionButtons)}
                                            onClick={() => openDialog('block')}
                                        >
                                            <BlockAction />Block all tracks
                                        </button>
                                    </>
                                    : null
                                }
                                <button
                                    className={classList(parentStyles.actionButtons, parentStyles.deleteAction)}
                                    onClick={() => openDialog('delete')}
                                >
                                    <DeleteAction />Delete
                                </button>
                            </div>}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </div>
        {type === 'album' && target.text !== '' && <div className={styles.albumDesc}>
            <div className={classList(styles.textWrap, { [styles.visible]: !viewMore })}>
                <p>{target.text}</p>
            </div>
            {viewMore && <ButtonMUI
                variant='text'
                onClick={() => setViewMore(false)}
            >
                Show more<BackArrow />
            </ButtonMUI>}
        </div>}
        {target.is_blocked && target.blocked_text && <div className={styles.blockReason}>
            <BigBlock />
            <div>
                <p>This {type} has been blocked for the following reason:</p>
                <span>{target.blocked_text}</span>
            </div>
        </div>}
        <ActionDialog
            type={type + 's'}
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            isInner
        />
    </div>
}

export default Heading