import React, { useEffect, useState } from 'react';
import styles from './Finances.module.scss';
import { prettyScroll } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentHistoryInner, getPaymentHistoryInnerTotal } from './storage/financesActions';
import { useParams, useHistory } from 'react-router-dom';
import FinancesTable from './FinancesTable';
import { initialParameters } from './Finances';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const PaymentHistoryInner = () => {
  const dispatch = useDispatch()
  const { historyID } = useParams()
  const { total } = useSelector(({ finances }) => finances)
  const [parameters, setParameters] = useState(initialParameters)

  return (
    <main className={`${styles.finances} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.financesInner} container`}>
        <h1>Payment for: {total?.created_date ? dayjs(total?.created_date).format('DD MMM, YYYY') : '-'}</h1>
        <div className={`inner-wrap ${styles['inner-wrap']}`}>
          <FinancesTable
            parameters={parameters}
            setParameters={setParameters}
            initialParameters={initialParameters}
            action={({ queryString }) => getPaymentHistoryInner(historyID, queryString)}
            type={'historyInner'}
            additionalAction={() => dispatch(getPaymentHistoryInnerTotal(historyID))}
          />
        </div>
      </div>
    </main>
  );
};

export default PaymentHistoryInner;
