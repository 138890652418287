import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import './DialogMUI.scss';

import { ReactComponent as CloseIcon } from './icons/close.svg';
import { propTypes } from 'react-addons-css-transition-group';
import { classList, prettyScroll } from '../../helpers/functions';

let timer = null;

const DialogMUI = ({ open, onClose, className = '', pepperClass = '', contentClass = '', children, fullScreen = false, isSideBar = false, visible = false, setVisible, setOpen, title = false, withScrollAction = false, onScrollEndEvent, ...otherProps }) => {
  useEffect(() => {
    if (isSideBar && open) {
      timer = setTimeout(() => { setVisible(true); clearTimeout(timer) }, 150)
    }
  }, [open])
  useEffect(() => {
    if (isSideBar && open && !visible) {
      timer = setTimeout(() => { setOpen(false); clearTimeout(timer) }, 500)
    }
  }, [visible])
  return (
    <Dialog
      open={visible || open}
      onClose={() => isSideBar ? setVisible(false) : onClose()}
      className={className}
      classes={{
        root: `dialog-mui${isSideBar ? ' side-bar' : ''}${visible ? ' visible' : ''}`,
        paper: classList(`dialog-mui__paper`, pepperClass),
      }}
      fullScreen={fullScreen}
      {...otherProps}
    >
      <div className={classList(contentClass, 'dialog-mui__content', { 'with-title': !!title })} ref={e => prettyScroll(e, false, !!onScrollEndEvent ? onScrollEndEvent : false)}>
        {!!title ? <h2 className='dialog-mui__dialog-title'>{title}</h2> : null}
        <button onClick={() => isSideBar ? setVisible(false) : onClose()} className='dialog-mui__close-button' aria-label='Close dialog button'>
          <CloseIcon className='dialog-mui__close-icon' />
        </button>
        {children}
      </div>
    </Dialog>
  );
};

DialogMUI.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default DialogMUI;
