import { classList, objectToQueryString } from '../../../helpers/functions';
import { PAGES_CONTENT } from './pagesContentActionTypes';

export function getContent(type, url) {
    return {
        type: PAGES_CONTENT[`GET_${type.toUpperCase()}`],
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/${type}${!!url && url.length > 0 ? url : ''}`,
                method: 'GET',
            },
        },
    };
}

export function getContentInner(type, id, period, localIsAuto) {
    return {
        type: PAGES_CONTENT[`GET_${type.toUpperCase()}_INNER`],
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/${type}/${id}/${period}${localIsAuto !== null && localIsAuto !== undefined ? `?auto=${localIsAuto}` : ''}`,
                method: 'GET',
            },
        },
    };
}

export function copyContent(type, id, period) {
    return {
        type: PAGES_CONTENT.COPY_INNER_CONTENT,
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/${type}/${id}/${period}`,
                method: 'GET',
            },
        },
    };
}

export function createGenre(data) {
    return {
        type: PAGES_CONTENT.CREATE_GENRE,
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/genres`,
                method: 'POST',
                data
            },
        },
    };
}

export function setContentInner(type, id, period, localIsAuto, ids = [], name) {
    return {
        type: PAGES_CONTENT[`SET_${type.toUpperCase()}_INNER`],
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/${type}/${id}/${period}/change`,
                method: 'PUT',
                data: {
                    auto: localIsAuto,
                    ids: ids
                },
                snackData: {
                    period, name
                }
            },
        },
    };
}

export function getAvailableTracks(props) {
    return {
        type: PAGES_CONTENT.GET_AVAILABLE_TRACKS,
        payload: {
            client: 'default',
            isPaginate: !!props?.cursor,
            request: {
                url: `/admin/search/tracks/available${objectToQueryString(props, true)}`,
                method: 'GET',
            },
        },
    };
}

export function getAvailablePacks(type, id, period) {
    return {
        type: PAGES_CONTENT.GET_AVAILABLE_PACKS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/charts/${type}/${id}/duplicate?period=${period}`,
                method: 'GET',
            },
        },
    };
}

export function setCountryAuto(code) {
    return {
        type: PAGES_CONTENT.SET_COUNTRY_AUTOMATIC,
        payload: {
            client: 'default',
            code,
            request: {
                url: `/admin/charts/countries/${code}/change`,
                method: 'PUT',
            },
        },
    };
}

export function setGenresAuto(id) {
    return {
        type: PAGES_CONTENT.SET_GENRES_AUTOMATIC,
        payload: {
            client: 'default',
            id,
            request: {
                url: `/admin/charts/genres/${id}/change`,
                method: 'PUT',
            },
        },
    };
}

export function reorderTracks(source, destination) {
    return {
        type: PAGES_CONTENT.REORDER_TRACKS,
        payload: {
            data: {
                from: source,
                to: destination
            }
        },
    };
}

export function setTracks(arr) {
    return {
        type: PAGES_CONTENT.SET_TRACKS,
        payload: {
            data: arr
        },
    };
}