import styles from './StatisticSidebar.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Plays } from '../../../assets/icons/plays.svg'
import { ReactComponent as Mentions } from '../../../assets/icons/user_statistic_mentions.svg'
import { ReactComponent as Upload } from '../../../assets/icons/user_upload_statistic.svg'
import { ReactComponent as Balance } from '../../../assets/icons/user_statistic_balance.svg'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { classList, formatFileSize, formatNumber } from '../../../helpers/functions';
import { getUserStatistic } from '../storage/usersActions';
dayjs.extend(utc)

const StatisticSidebar = ({ userId }) => {
    const dispatch = useDispatch();
    const { userStatistic } = useSelector(({ users }) => users)

    useEffect(() => {
        dispatch(getUserStatistic(userId))
    }, [userId])

    return <div className={styles.sidebar}>
        <p className={styles.cellHeading}>Finances</p>
        <div className={classList(styles.cell, styles.balance)}>
            <div><Balance /><span>Balance</span></div>
            <div><p>${formatNumber(userStatistic.balance)}</p></div>
            <span>Total earned: ${formatNumber(userStatistic.total_earned)}</span>
        </div>
        <p className={classList(styles.cellHeading, styles.withMargin)}>Statistic</p>
        <div className={styles.cell}>
            <div><Upload /><span>Uploads</span></div>
            <div><p>{userStatistic.tracks_count} file{userStatistic.tracks_count !== 1 && 's'}</p></div>
            <span>Total size: {formatFileSize(userStatistic.total_tracks_file_size)}</span>
        </div>
        <div className={classList(styles.cell, styles.half)}>
            <div><Plays /><span>Plays</span></div>
            <div><p>{formatNumber(userStatistic.played_track_count, 0)}</p></div>
        </div>
        <div className={classList(styles.cell, styles.half)}>
            <div><Mentions /><span>Mentions</span></div>
            <div><p>{formatNumber(userStatistic.mention_count, 0)}</p></div>
        </div>
    </div>
}

export default StatisticSidebar