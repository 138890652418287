import React from 'react'
import { ButtonMUI, DialogMUI } from '../../../shared'
import styles from './UploadStyles.module.scss'
import { ReactComponent as Success } from '../../../assets/icons/success.svg'
import { ReactComponent as Failed } from '../../../assets/icons/failed.svg'

const UploadingProcessDialog = ({ dialogIsOpen, closeDialog, counts, files, errorsTrack, isComplete, isUploadIdx }) => {
    const uploadPercent = (isUploadIdx / counts.mustUploadedCount) * 100;

    return (
        <DialogMUI
            open={dialogIsOpen}
            onClose={closeDialog}
            maxWidth={'xs'}
        >
            <h3 className='dialog-mui__title'>
                Uploading...
            </h3>

            <div className={styles['progress-info']}>
                {isComplete && <Success />}
                <p className={`dialog-mui__des ${styles.des}`}>
                    <span className='grey'>{isComplete ? counts.uploadedCount : isUploadIdx}/{counts.mustUploadedCount}{isComplete ? '' : ':'} </span>
                    {isComplete ? <span className='grey'>of your files have been successfully uploaded</span> :
                        <div className={styles['file-name__wrapper']}>
                            <span className={`${styles['file-name']} dots-end`}>{files?.[isUploadIdx]?.name.replace(/\.(mp3|flac)$/, '')}</span>
                            <span className='grey'>{files?.[isUploadIdx]?.type === 'audio/flac' ? '.flac' : '.mp3'}</span>
                        </div>}
                </p>
            </div>

            {!isComplete && <div className={styles['upload-progress']}>
                <div style={{ '--width-blue-line': `${uploadPercent}%` }} className={styles['upload-progress__line']} />
                <span className={styles['upload-progress__percent']}>{Math.floor(uploadPercent)}%</span>
            </div>}

            {errorsTrack.length > 0 && <div className={`${styles['progress-info']} ${styles.failed}`}>
                <Failed />
                <p className='dialog-mui__des'>
                    {errorsTrack.length}/{counts.mustUploadedCount} <span className='grey'>failed to upload:</span>
                </p>
                <ul>
                    {errorsTrack.map((el, idx) =>
                        <li className={styles['tooltip-container']} key={idx}>
                            <div className={styles['file-name__wrapper']}>
                                <span className={`${styles['file-name']} dots-end`}>{el?.track?.name?.replace(/\.(mp3|flac)$/, '')}</span>
                                <span className={`${styles.grey}`}>{el?.track?.type === 'audio/flac' ? '.flac' : '.mp3'}</span>
                            </div>
                            <span className={styles.tooltip}>
                                {el?.res?.error?.response?.data && Object.values(el.res.error.response.data)[0]}
                            </span>
                        </li>)}
                </ul>
            </div>}

            <ButtonMUI
                size='small'
                color={isComplete ? 'default' : 'primary'}
                onClick={closeDialog}
            >
                {isComplete ? "Continue" : "Cancel"}
            </ButtonMUI>
        </DialogMUI >
    )
}

export default UploadingProcessDialog