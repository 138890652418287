import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

dayjs.extend(utc)

export const joinQueries = (arr) => `${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`;

export function getOption(label) {
  return (
    <div className={`status ${label}`}>
      <div>
        {label !== 'All networks' && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Scroll Restoration
export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// Convert string to slug
export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  let to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
}

export const generateId = () => Math.floor(Math.random() * Date.now());

export const randomInteger = (max) => Math.floor(Math.random() * (max + 1));

export const randomRgbColor = () => {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return `rgb(${r}, ${g}, ${b})`;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const dateForQuery = (date) => encodeURIComponent(date?.toISOString().replace('.000Z', ''));

export const objectToQueryString = (queryParams, isNoEncode = false) => {
  return queryParams
    ? Object.entries(queryParams).reduce((queryString, [key, value]) => {
      const symbol = queryString.length === 0 ? '?' : '&';
      queryString +=
        (value || value === 0) && (typeof value === 'string' || typeof value === 'number')
          ? `${symbol}${key}=${isNoEncode ? value : encodeURIComponent(value)}`
          : value !== null && value !== undefined && value !== NaN && typeof value === 'object' && value.hasOwnProperty('value') && value.value !== null && value.value !== undefined && value.value !== NaN
            ? `${symbol}${key}=${value.value}`
            : Array.isArray(value) && value.length > 0
              ? `${symbol}${key}=${value.join(',')}`
              : ''
      return queryString;
    }, '')
    : '';
};

export const isNegative = (num) => Math.sign(num) === -1;

export const capitalize = (string) => string && string.charAt(0).toUpperCase() + string.slice(1);

export const classList = (...classes) => classes.map(el => typeof el === 'object'
  ? Object.entries(el).filter(([_, value]) => !!value).map(([key, _]) => key).join(' ')
  : el)
  .filter((el) => !!el).join(' ')

export const scrollToElement = (ref) => ref && ref.current.scrollIntoView({ behavior: 'smooth' });

//Returns true if it is a DOM node
function isNode(o) {
  return (
    typeof Node === "object" ? o instanceof Node :
      o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName === "string"
  );
}

//Returns true if it is a DOM element    
function isElement(o) {
  return (
    typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
      o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
  );
}


/* export class PrettyScroll {
  constructor(target, exception, scrollToBottomTrigger) {
    //if ((isElement(target) || isNode(target)) && !!window.getEventListeners(target)) {
    //  target.removeEventListener(
    //    'scroll',
    //   window.getEventListeners(target).scroll[0].listener
    //  )
    //} 
    this.target = target;
    this.exception = exception;
    this.scrollToBottomTrigger = scrollToBottomTrigger;
    this.timer = null
    if (isElement(this.target) || isNode(this.target)) {
      this.target.classList.add('pretty-scroll');
      if (!!this.exception && this.target.offsetHeight >= this.target.scrollHeight) {
        this.target.classList.remove('pretty-scroll');
        // console.log(this.target.offsetHeight > this.target.scrollHeight)
      }
    }
    this.listener = (e) => {
      this.target.classList.add('scroll');
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.target.classList.remove('scroll');
      }, 1000);
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) this.scrollToBottomTrigger()
    }

    this.setListener = () => {
      console.log(this.target)
      //this.prevListener = this.listener
      if (isElement(this.target) || isNode(this.target)) {
        this.target.addEventListener('scroll', this.listener);
      }
    }
  }
} */
let prevListener = {};
export const prettyScroll = (target, exception, scrollToBottomTrigger) => {
  if (!!target) {
    target.removeEventListener('scroll', prevListener[target.getAttribute('class').replace(' scroll', '')])
    delete prevListener[target.getAttribute('class').replace(' scroll', '')]
    let timer = null;
    target.classList.add('pretty-scroll');
    if (!!exception && target.offsetHeight >= target.scrollHeight) {
      target.classList.remove('pretty-scroll');
      // console.log(target.offsetHeight > target.scrollHeight)
    }
    const listener = (e) => {
      target.classList.add('scroll');
      clearTimeout(timer);
      timer = setTimeout(() => {
        target.classList.remove('scroll');
      }, 1000);
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight && !!scrollToBottomTrigger) scrollToBottomTrigger()
    }
    prevListener = { ...prevListener, [target.getAttribute('class').replace(' scroll', '')]: listener }
    target.addEventListener('scroll', listener);
  }
};

export const formatFileSize = function (bytes) {
  const sufixes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return bytes === 0 ? '0 B' : `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const formatAlbumTime = (time, withSeconds = false) => {
  let timeArray = dayjs(time).utc().format().split('T')[1].split('+')[0].split(':').slice(0, withSeconds || time < 60000 ? undefined : -1),
    timeTypeArray = ['h', 'm', 's']
  return time < 1000 ? '< 1s' : timeArray.map((el, idx) => el === '00' ? false : `${dayjs(time).utc().format(`${timeTypeArray[idx]}[${timeTypeArray[idx]}]`)}`).filter(el => !!el).join(' ')
}

export const indexWalk = (arr, indexes, property) => {
  let i = 0,
    tempArr = [...arr],
    returnValue = []

  for (i; i < indexes.length; i++) {
    returnValue.push(tempArr[indexes[i]])
    tempArr = tempArr[indexes[i]][property]
  }

  if (indexes.length > 1) {
    returnValue = returnValue.slice(-2)
    returnValue[0][property].splice([indexes[indexes.length - 1]], 1)
  } else {
    arr.splice(indexes[0], 1)
  }

  return arr
}

/* export const formatNumber = (value) =>
  Number(value)
    .toLocaleString('fr', {
      maximumFractionDigits: 2
    })
    .replace(',', '.'); */

export const formatNumber = (num, fixed = 2) => {
  let [first, second] = Number(num).toFixed(fixed).split('.');
  return `${Number(first).toLocaleString('en-EN')}${fixed === 0 ? '' : `.${second}`}`
}


export const configureSentry = ({ dsn }) => {
  let environment;
  if (process.env.SERVER_TYPE === 'dev') environment = 'development';
  if (process.env.SERVER_TYPE === 'stage') environment = 'stage';

  if (process.env.REACT_APP_IS_LOCAL_SERVER === 'true') {
    return;
  }

  return Sentry.init({
    dsn,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
    environment,
    integrations:
      [
        new Integrations.BrowserTracing(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      ],
    release: 'tunetome-admin-frontend@2.0.0',
  });
};