import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './CampaignHeading.module.scss';
import parentStyles from '../../Audio/Audio.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMUI } from '../../../shared'
import { ReactComponent as AgreementIcon } from '../../../assets/icons/agreement_date_icon.svg'
import { ReactComponent as BudgetIcon } from '../../../assets/icons/budget_icon.svg'
import { ReactComponent as AudienceIcon } from '../../../assets/icons/audience_icon.svg'
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_ico.svg'
import { classList, formatNumber } from '../../../helpers/functions';
import { ClickAwayListener } from '@material-ui/core';
import nonAvatar from '../../../assets/images/adver_non_avatar.svg'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DeleteModal } from '../Campaigns';
import { campaignAction, campaignArchive } from '../storage/advertisingActions';
import { ageOptions } from '../../Audio/Audio';
dayjs.extend(utc)

const CampaignHeading = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { advertiserId, campaignId } = useParams();
    const target = useSelector(({ advertising }) => advertising.editCampaign)
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: target })
        contextMenuActions('close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(dialogStatus.type.toLowerCase() === 'delete' ? campaignAction('DELETE', dialogStatus.target.id) : campaignArchive(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
                history.push(`/main/advertising/${advertiserId}`)
            }
        })
    }

    return <div className={styles.heading}>
        <div
            className={classList(styles.imageWrapper, { [styles.withPrice]: !!target.price })}
            data-price={target.price}
        >
            <img src={target.image || nonAvatar} alt={target.name} />
        </div>
        <div className={styles.shortInfo}>
            <div>
                <h1>{target.name}</h1>
                <div className={styles.artistBlock}>
                    {dayjs(target.start_date).format('DD/MM/YYYY')} - {dayjs(target.end_date).format('DD/MM/YYYY')}
                </div>
            </div>
            <div className={styles.lowerBlock}>
                <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
                    {target.is_archived
                        ? <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons, styles.block)}
                            onClick={() => history.push(`/main/advertising/${advertiserId}/campaign/${campaignId}/restore`)}
                        >
                            Restore
                        </ButtonMUI>
                        : <ButtonMUI
                            size='small'
                            className={classList(parentStyles.actionButtons, styles.block)}
                            onClick={() => openDialog('archive')}
                        >
                            Archive
                        </ButtonMUI>}
                    <ClickAwayListener onClickAway={() => contextMenuActions('close', target.id)}>
                        <div className={parentStyles.moreActions}>
                            <button className={classList(parentStyles.moreActions__btn, { [parentStyles.active]: contextMenu === target.id })} onClick={() => contextMenuActions('open', target.id)}>
                                <span />
                                <span />
                                <span />
                            </button>
                            {contextMenu === target.id && <div className={parentStyles.moreActions__container}>
                                <button
                                    className={classList(parentStyles.actionButtons)}
                                    onClick={() => history.push(`/main/advertising/${advertiserId}/campaign/${campaignId}/edit`)}
                                >
                                    <EditIcon />Edit
                                </button>
                                <button
                                    className={classList(parentStyles.actionButtons, parentStyles.deleteAction)}
                                    onClick={() => openDialog('delete')}
                                >
                                    <DeleteAction />Delete
                                </button>
                            </div>}
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </div>
        <div className={styles.albumDesc}>
            <div className={classList(styles.textWrap)}>
                <p>{target.description}</p>
            </div>
        </div>
        <div className={styles.smallStatistic}>
            <div>
                <AgreementIcon />
                <span>Agreement date:</span>
                <p>{dayjs(target.agreement_date).format('DD MMMM YYYY')}</p>
            </div>
            <div>
                <BudgetIcon />
                <span>Budget:</span>
                <p>${formatNumber(target.budget)}</p>
            </div>
            <div>
                <AudienceIcon />
                <span>Audience age:</span>
                <p>{ageOptions.find(el => el.id === target.age)?.label}</p>
            </div>
        </div>
        <DeleteModal
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            btnLoad={btnLoad}
            submitDialog={submitDialog}
        />
    </div>
}

export default CampaignHeading