import React from 'react';
import PropTypes from 'prop-types';
import styles from './SimpleErrorMessage.module.scss';
import { classList } from '../../helpers/functions';
import { ReactComponent as ErrorIcon } from './icons/error.svg';

const SimpleErrorMessage = ({ classes = '', messages = [] }) => (
  <div className={classList(styles.errorText, classes)}><ErrorIcon /> {messages.join('; ')}</div>
);

SimpleErrorMessage.propTypes = {
  classes: PropTypes.string,
  messages: PropTypes.array,
};

export default SimpleErrorMessage;
