import {useParams} from 'react-router-dom';
import {prettyScroll} from '../../../helpers/functions';
import styles from './Track.module.scss';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAudioInfo} from '../storage/audioActions';
import StatisticSidebar from '../StatisticSidebar';
import Heading from '../Heading';
import DetailsMaper from '../DetailsMaper';
import dayjs from 'dayjs';
import {ageOptions} from '../Audio';

const type = 'track';

const Track = () => {
  const dispatch = useDispatch();
  const {trackId} = useParams();
  const target = useSelector(({audio}) => audio[`${type}Info`]);

  useEffect(() => {
    dispatch(getAudioInfo(type, trackId));
  }, [trackId]);

  return (
    <main className={`${styles.track} container`}>
      <div className={`${styles.trackInner} page-wrap`} ref={prettyScroll}>
        <Heading type={type} trackId={trackId} />
        <DetailsMaper title={'Track details'} arr={createDetails(target)} />
        <DetailsMaper title={'Lyrics'} arr={createLyrics(target)} />
      </div>
      <StatisticSidebar type={type} trackId={trackId} />
    </main>
  );
};

const createLyrics = (target) =>
  JSON.stringify(target) === '{}'
    ? []
    : [
        {
          value: target.text,
          size: 'xl'
        }
      ];

const createDetails = (target) =>
  JSON.stringify(target) === '{}'
    ? []
    : [
        [
          {
            title: 'Main genre',
            value: target.genre.name,
            size: 'sm'
          },
          {
            title: 'Custom genres',
            value: target.custom_genres.length > 0 && target.custom_genres.map((el) => el.name).join(', '),
            size: 'lg'
          }
        ],
        {
          title: 'Release date',
          value: dayjs(target.release_date).format('DD/MM/YYYY'),
          size: 'sm'
        },
        {
          title: 'Producer(s)',
          value: target.producer,
          size: 'xl'
        },
        {
          title: 'Location',
          value: target.location?.address,
          size: 'xl'
        },
        {
          title: 'Tags',
          value: target.tags.join(', '),
          size: 'xl'
        },
        {
          title: 'Emotions',
          value: target.emotions.map((el, idx) => (
            <p>
              <em-emoji set='apple' native={el.emoji} />
              {el.name}
              {idx + 1 < target.emotions.length && ','}
            </p>
          )),
          size: 'xl'
        },
        {
          title: 'Minimum age',
          value: ageOptions.find((el) => el.id === target.age)?.label,
          size: 'md'
        },
        {
          title: 'Track ISRC',
          value: target.isrc,
          size: 'sm'
        }
      ];

export default Track;
