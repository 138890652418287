import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styles from './Advertising.module.scss'
import additionalStyles from '../Audio/Audio.module.scss';
import { classList, prettyScroll } from '../../helpers/functions';
import { ButtonMUI, InputMUI } from '../../shared';
import { getAdvertiser, editAdvertiserAction, advertiserAction } from './storage/advertisingActions';
import { CircularProgress } from '@material-ui/core';
import { customBack } from '../../app/appActions';

const AdvertiserEdit = () => {
    const { advertiserId } = useParams()
    const isEdit = !!advertiserId
    const dispatch = useDispatch()
    const history = useHistory()
    const { editAdvertiser, editAdvertiser_loading } = useSelector(({ advertising }) => advertising)
    const [btnLoad, setBtnLoad] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (isEdit) dispatch(getAdvertiser(advertiserId))
        if (history.location.pathname.includes('inner-edit')) dispatch(customBack(`/main/advertising/${advertiserId}`))
    }, [])

    const submitForm = () => {
        setBtnLoad(true)
        dispatch(advertiserAction(editAdvertiser, advertiserId)).then(({ type, error }) => {
            if (type.includes('_SUCCESS')) history.push('/main/advertising')
            else {
                setErrors(prev => ({ ...prev, ...error.response.data }))
                setBtnLoad(false)
            }
        })
    }

    const fieldsConfig = [
        {
            name: 'Name',
            key: 'name',
            width: 'full',
            placeholder: 'Enter the advertiser name',
        },
        {
            name: 'Bank details',
            key: 'bank_details',
            width: 'full',
            placeholder: 'Enter the bank details',
            type: 'textarea'
        },
        {
            name: 'Legal details',
            key: 'legal_details',
            width: 'full',
            placeholder: 'Enter the legal details',
            type: 'textarea'
        },
        {
            name: 'Email',
            key: 'email',
            placeholder: 'Enter the advertiser email',
            width: 'half'
        },
        {
            name: 'Phone number',
            key: 'phone_number',
            placeholder: 'Enter the advertiser phone number',
            width: 'half'
        },
    ]

    return <main className={classList(styles.advertising, styles.advertisingEdit, 'page-wrap')} ref={prettyScroll}>
        <div className={classList(styles.advertisingInner, 'container')}>
            <h1>{isEdit ? 'Edit' : 'New'} advertiser</h1>
            <div className={styles.sepatrator} />
            <div className={classList('inner-wrap', styles['inner-wrap'], { [styles.loading]: isEdit && editAdvertiser_loading })}>
                {isEdit && editAdvertiser_loading
                    ? <CircularProgress />
                    : fieldsConfig.map(({ name, key, width, placeholder, type }, idx) => <div key={idx} className={classList(additionalStyles.inputLabel, styles.inputLabel, styles[width])}>
                        <p className={additionalStyles.inputLabel__title}>{name}</p>
                        <div className={classList(additionalStyles.inputLabel__wrapper, styles.inputLabel__wrapper)}>
                            <InputMUI
                                id={key}
                                type={key === 'email' ? key : 'text'}
                                placeholder={placeholder}
                                value={editAdvertiser[key]}
                                onChange={({ target: { value } }) => {
                                    setErrors(prev => ({ ...prev, [key]: '' }))
                                    dispatch(editAdvertiserAction({ [key]: value }))
                                }}
                                multiline={type === 'textarea'}
                                minRows={type === 'textarea' ? 5 : 1}
                                error={errors[key] && errors[key].join('; ')}//{editAdvertiser[key].length < 1 && `"${name}" may not be blank`}
                            />
                        </div>
                    </div>)
                }
            </div>
            <div className={styles.sepatrator} />
            <ButtonMUI
                onClick={submitForm}
                loading={btnLoad}
                disabled={btnLoad || Object.values(errors).some(el => !!el)/*  || Object.values(editAdvertiser).some(el => el.length < 1) */}
            >
                {isEdit ? 'Save changes' : 'Create'}
            </ButtonMUI>
        </div>
    </main>
}

export default AdvertiserEdit