import React, { useEffect } from 'react';
import check from '../../assets/icons/check_select.svg';
import checkBlue from '../../assets/icons/check-blue_select.svg';
import searchIcon from '../../assets/icons/search-icon.svg';
import add from './icons/add_icon.svg';
import addedIcon from './icons/added_icon.svg';
import addadValueIcon from './icons/addadValueIcon.svg';
import { ReactComponent as ClearInput } from './icons/close.svg';
import gallery from '../../assets/icons/gallery.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search_icon.svg'

import './CustomMultiSelect.scss';
import { useState } from 'react';
import { useDebounce } from '../../helpers/hooks';
import InputMUI from '../InputMUI';
import CheckboxMUI from '../CheckboxMUI';

const MultiSelectItem = ({ onClick, icon, code, label, selected, id, tagSelect = false, emoji, isList }) => {
  const isSelected = selected.find((el) => el.id === id);

  const getImg = () => {
    if (icon) {
      return <img className='custom-multiselect__item-img' src={icon} alt='icon' />;
    } else if (emoji) {
      return <span className='custom-multiselect__item-emoji'><em-emoji set='apple' native={emoji} /></span>;
    } else if (!icon && tagSelect) {
      return null;
    } else {
      return <img className='custom-multiselect__item-img' src={gallery} alt='icon' />;
    }
  };
  return (
    <button
      className={`custom-multiselect__item${isSelected && tagSelect ? ' active-tag' : ''}${isList ? ' list-view' : ''}`}
      type='button'
      onClick={onClick}
    >
      {isList && (
        <CheckboxMUI
          checked={!!isSelected}
        />
      )}
      <div className='custom-multiselect__item-label'>
        {getImg()}
        <p>
          {label} {code && <span className='light'>{code}</span>}
        </p>
      </div>
      {!tagSelect ? (
        <span className={`custom-multiselect__icon-wrapper${isSelected ? ' active' : ''}`}>
          <img className='custom-multiselect__icon' src={check} alt='check' />
        </span>
      ) : null}
    </button>
  );
};

const SingleSelectItem = ({ onClick, label, value, selected }) => (
  <button
    className={`custom-multiselect__single-item${value === selected.value ? ' selected' : ''}`}
    type='button'
    onClick={onClick}
  >
    <p>{label}</p>
    {value === selected.value && <img src={checkBlue} alt='check' />}
  </button>
);

const MenuList = ({
  values,
  listRef,
  shadowRef,
  maxSected,
  selectItem,
  selectSingleItem,
  options,
  placeholderSearch,
  typeSelect,
  tagSelect,
  maxText,
  withSearch,
  addBlock,
  getList,
  nextList,
  selectWithoutId,
  setter,
  isList
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [added, setAdded] = useState(false);
  const isSelected = typeSelect === 'multi' && values.find((el) => el.label === searchValue);
  const debounced = useDebounce(searchValue);

  const doRequest = (nextPage = true) => {
    getList(searchValue, nextPage);
  };

  useEffect(() => {
    getList && doRequest(false);
  }, [debounced]);

  return (
    <div className={`custom-multiselect__dropdown${tagSelect ? ' tagSelect' : ''}${isList ? ' list' : ''}`}>
      {withSearch && (
        <div className={'custom-multiselect__top'}>
          <InputMUI
            className='custom-multiselect__search-field'
            id='search'
            type='text'
            placeholder={placeholderSearch}
            startAdornment={<SearchIcon />}
            size='small'
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
            endAdornment={searchValue.length > 0
              ? <button className='clear_search_input' onClick={() => setSearchValue('')}>
                <ClearInput />
              </button>
              : false
            }
          />
          {maxSected === values.length && typeSelect === 'multi' && maxText && (
            <p className='custom-multiselect__max-text'>{maxText}</p>
          )}
        </div>
      )}

      <div id='scrollableDiv' ref={listRef} className={`custom-multiselect__dropdown-list`}>
        {/* <InfiniteScroll
          dataLength={options?.length}
          hasMore={!!nextList}
          next={getList ? doRequest : null}
          scrollableTarget='scrollableDiv'
        > */}
        {/* <span ref={shadowRef} className='custom-multiselect__dropdown-shadow' /> */}
        {searchValue && addBlock && (
          <div className='custom-multiselect__add-block'>
            <button
              onClick={() => (selectWithoutId(addadValueIcon, searchValue), setAdded(true))}
              type='button'
              className='custom-multiselect__add-btn'
            >
              <img src={!isSelected ? add : addedIcon} alt='add' />
            </button>
            <span>{!added ? 'Add' : 'You’ve already added'}</span> "{searchValue}"
          </div>
        )}
        {values.length > 0 && isList && <>
          <button onClick={() => setter([])} className='custom-multiselect__clear_all'>Clear selected items</button>
          {values.map(({ label, icon, code, id, value, emoji }, idx) => {
            return typeSelect === 'multi' ? (
              <MultiSelectItem
                key={idx}
                type='button'
                onClick={(e) => selectItem(icon ? icon : gallery, id, label, emoji, e)}
                label={label}
                icon={icon}
                code={code}
                selected={values}
                id={id}
                tagSelect={tagSelect}
                emoji={emoji}
                isList={isList}
              />
            ) : (
              <SingleSelectItem
                onClick={() => selectSingleItem({ label, value })}
                key={idx}
                label={label}
                selected={values}
                value={value}
                isList={isList}
              />
            );
          })}
          <hr />
        </>}
        {options.length > 0 &&
          (searchValue.length > 0
            ? options
              .filter(el => el.label.toUpperCase().includes(searchValue.toUpperCase()))
              .filter(el => !isList || !values.some(elem => elem.value === el.value))
            : options
              .filter(el => !isList || !values.some(elem => elem.value === el.value))
          ).map(({ label, icon, code, id, value, emoji }, idx) => {
            return typeSelect === 'multi' ? (
              <MultiSelectItem
                key={idx}
                type='button'
                onClick={(e) => selectItem(icon ? icon : gallery, id, label, emoji, e)}
                label={label}
                icon={icon}
                code={code}
                selected={values}
                id={id}
                tagSelect={tagSelect}
                emoji={emoji}
                isList={isList}
              />
            ) : (
              <SingleSelectItem
                onClick={() => selectSingleItem({ label, value })}
                key={idx}
                label={label}
                selected={values}
                value={value}
                isList={isList}
              />
            );
          })}
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
};

export default MenuList;
