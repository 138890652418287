import { FINANCES } from './financesActionTypes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

const INITIAL_STATE = {
    indicators: {
        can_calculate: false,
        calculating: false,
        date: null,
        is_paid: false,
    },
    indicatorsLoading: true,
    total: null,
    totalLoading: false,
    financesTable: null,
    tableLoading: false,
    /* historyLoading: false,
    history: null,
    historyInner: null, */
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FINANCES.GET_FINANCES_INDICATORS:
            return { ...state, indicatorsLoading: true };
        case FINANCES.GET_FINANCES_INDICATORS_SUCCESS:
            return {
                ...state,
                indicators: action.payload.data,
                indicatorsLoading: false
            };
        case FINANCES.GET_TOTAL:
            return {
                ...state,
                totalLoading: true
            };
        case FINANCES.GET_TOTAL_SUCCESS:
            return {
                ...state,
                total: action.payload.data,
                totalLoading: false
            };
        case FINANCES.SET_CALCULATING_SUCCESS:
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    calculating: true,
                    can_calculate: false
                }
            };
        case FINANCES.GET_FINANCES_TABLE:
            return {
                ...state,
                tableLoading: true
            };
        case FINANCES.GET_FINANCES_TABLE_SUCCESS:
            return {
                ...state,
                financesTable: action.payload.data,
                tableLoading: false
            };
        case FINANCES.SET_AMOUNT_SUCCESS:
            let target = state.financesTable.results.find(el => el.artist.id === action.payload.config.id);

            state.total.amount = state.total.amount - target.amount + action.payload.data.amount;
            target.amount = action.payload.data.amount;
            return { ...state };
        case FINANCES.RESET_CHANGES_SUCCESS:
            return {
                ...state,
                total: {
                    ...state.total,
                    amount: state.total.init_amount
                },
                financesTable: {
                    ...state.financesTable,
                    results: [
                        ...state.financesTable.results.map(el => ({ ...el, amount: el.init_amount }))
                    ]
                }
            };
        case FINANCES.RESET_CALCULATION_SUCCESS:
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    can_calculate: true,
                    calculating: false,
                    is_paid: false,
                }
            };
        case FINANCES.MAKE_PAYMENT_SUCCESS:
            return {
                ...state,
                indicators: {
                    ...state.indicators,
                    can_calculate: false,
                    calculating: false,
                    is_paid: true,
                    date: dayjs().add(1, 'month').date(1)
                }
            };
        case FINANCES.GET_PAYMENT_HISTORY:
        case FINANCES.GET_PAYMENT_HISTORY_INNER:
            return {
                ...state,
                tableLoading: true
            };
        case FINANCES.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                financesTable: action.payload.data,
                total: { plans: action.payload.data.plans },
                tableLoading: false
            };
        case FINANCES.GET_PAYMENT_HISTORY_INNER_SUCCESS:
            return {
                ...state,
                financesTable: action.payload.data,
                tableLoading: false
            };
        case FINANCES.GET_PAYMENT_HISTORY_INNER_TOTAL:
            return {
                ...state,
                totalLoading: true
            };
        case FINANCES.GET_PAYMENT_HISTORY_INNER_TOTAL_SUCCESS:
            return {
                ...state,
                total: action.payload.data,
                totalLoading: false
            };
        /* case FINANCES.GET_PAYMENT_HISTORY:
        case FINANCES.GET_PAYMENT_HISTORY_INNER:
            return {
                ...state,
                historyLoading: true
            };
        case FINANCES.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload.data,
                historyLoading: false
            };
        case FINANCES.GET_PAYMENT_HISTORY_INNER_SUCCESS:
            return {
                ...state,
                historyInner: action.payload.data,
                historyLoading: false
            }; */
        default:
            return state;
    }
}
