import { useParams, Link, useHistory } from 'react-router-dom';
import templateStyles from '../Audio/Track/Track.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classList, prettyScroll } from '../../helpers/functions';
import StatisticSidebar from './StatisticSidebar';
import { campaignAction, deleteAdvertising } from './storage/advertisingActions';
import { DeleteModal } from './Advertising';
import { mainPath } from '../../routes/paths';
import CampaignHeading from './CampaignHeading';
import { customBack } from '../../app/appActions';
import CampaignAudio from './CampaignAudio';

const CampaignInner = () => {
    const dispatch = useDispatch();
    const { advertiserId, campaignId } = useParams();
    const history = useHistory();
    const { editAdvertiser } = useSelector(({ advertising }) => advertising)
    const [contextMenu, setContextMenu] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ status: false, type: null, target: null })
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        dispatch(campaignAction('GET', campaignId))
    }, [campaignId])

    useEffect(() => {
        dispatch(customBack(`/main/advertising/${advertiserId}`))
    }, [advertiserId])

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if (action === 'open') setContextMenu(prev => prev === id ? false : id)
    }

    const openDialog = type => {
        setDialogStatus({ status: true, type: type, target: editAdvertiser })
        contextMenuActions('close', contextMenu)
    }

    const submitDialog = () => {
        setBtnLoad(true)
        dispatch(deleteAdvertising(dialogStatus.target.id)).then(({ type }) => {
            setBtnLoad(false)
            if (type.includes('SUCCESS')) {
                setDialogStatus(prev => ({ ...prev, status: false }))
                history.push(mainPath.advertising.path)
            }
        })
    }

    return <main className={`${templateStyles.track} container`}>
        <div className={`${templateStyles.trackInner} page-wrap`} ref={prettyScroll}>
            <CampaignHeading />
            <CampaignAudio />
        </div>
        <StatisticSidebar advertiserId={campaignId} isCampaign />
        <DeleteModal
            dialogStatus={dialogStatus}
            setDialogStatus={setDialogStatus}
            btnLoad={btnLoad}
            submitDialog={submitDialog}
        />
    </main>
}

export default CampaignInner