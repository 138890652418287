import React, { useEffect } from 'react';
import { Link, matchPath, useHistory, useParams } from 'react-router-dom';
import { authPath, mainPath } from '../../routes/paths';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as BackArrow } from '../../assets/icons/header_back_arrow.svg';
import styles from './Header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { postSignOut } from '../../features/Auth/authActions';
import { ButtonMUI } from '../../shared';

const Header = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customBackUrl } = useSelector(({ app }) => app)
  const currentPath = Object.values(mainPath).find(({ path }) => matchPath(history.location.pathname, { path: path, exact: true }))

  const logout = () => {
    dispatch(postSignOut()).then(() => {
      localStorage.removeItem('token');
      history.push(authPath.signIn.path);
    })
  };

  const linkBuilder = () => {
    if (currentPath.parent.path.includes(':') && customBackUrl) return customBackUrl
    else return currentPath.parent.path
  }

  return (
    <header className={styles.root}>
      <div className={`${styles.container} container`}>
        {!!currentPath?.parent && (
          <Link className={styles.globalBackAction} to={linkBuilder()}>
            <BackArrow /><span>Back to {currentPath.parent.name}</span>
          </Link>
        )}
        <div className={`${styles.user} my-auto`}>
          <span className={styles.userMail}>email@email.com</span>
        </div>
        <button className={`${styles.logout} my-auto`} type='button' aria-label='Logout button' onClick={logout}>
          <LogoutIcon className={styles.icon} />
        </button>
      </div>
    </header>
  );
};

export default Header;
