import styles from './StatisticSidebar.module.scss';
import parentStyles from '../Audio.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCommentAction, getAudioComments, getAudioStatistic } from '../storage/audioActions'
import { ButtonMUI, DialogMUI } from '../../../shared'

import { ReactComponent as Plays } from '../../../assets/icons/plays.svg'
import { ReactComponent as Emotions } from '../../../assets/icons/emotions.svg'
import { ReactComponent as Publications } from '../../../assets/icons/publications.svg'
import { ReactComponent as Comments } from '../../../assets/icons/comments.svg'
import profile from '../../../assets/icons/profile.svg'

import { ReactComponent as BackArrow } from '../../../assets/icons/header_back_arrow.svg';
import { ReactComponent as DeleteAction } from '../../../assets/icons/delete_action.svg'
import { renderAlbumInfo } from '../Audio';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ClickAwayListener } from '@material-ui/core';
import { classList, indexWalk } from '../../../helpers/functions';
dayjs.extend(utc)

const StatisticSidebar = ({ trackId, type }) => {
    const dispatch = useDispatch();
    const statistic = useSelector(({ audio }) => audio[`${type}Statistic`])
    const target = useSelector(({ audio }) => audio[`${type}Info`])
    const comments = useSelector(({ audio }) => audio[`${type}Comments`])
    const commentsCursor = useSelector(({ audio }) => !!audio[`${type}Comments`].next ? audio[`${type}Comments`].next.split('?cursor=')[1] : audio[`${type}Comments`].next)
    const [openComments, setOpenComments] = useState(false)


    useEffect(() => {
        dispatch(getAudioStatistic(type, trackId))
    }, [trackId])

    const renderFields = () => {
        let arr = [
            {
                title: 'Plays',
                icon: <Plays />,
                value: statistic.played_track_count,
            },
            {
                title: 'Emotions',
                icon: <Emotions />,
                value: statistic.emotions_count,
            },
            {
                title: 'Publications',
                icon: <Publications />,
                value: statistic.posts_count,
            },
            {
                title: 'Comments',
                icon: <Comments />,
                value: statistic.comments_count,
            }
        ]

        return arr.map(({ title, icon, value }, idx) => <div key={idx} className={styles.cell}>
            <div>{icon}<span>{title}</span></div>
            <div><p>{value}</p>{title === 'Comments' && statistic.comments_count > 0 && <ButtonMUI onClick={() => setOpenComments(true)} variant={'text'}>View<BackArrow /></ButtonMUI>}</div>
        </div>)
    }

    return <div className={styles.trackSidebar}>
        {renderFields()}
        <DialogMUI
            open={openComments}
            onClose={() => setOpenComments(false)}
            maxWidth={'md'}
            contentClass={styles.commentsDialogWrapper}
        >
            <div className={styles.commentsDialog}>
                <div className={styles.heading}>
                    <img src={target.image} alt={target.name} />
                    <div className={styles.audioInfo}>
                        <p>{target.name}</p>
                        {renderAlbumInfo(target, `${type}s`)}
                    </div>
                </div>
                <CommentsComponent
                    comments={comments.results}
                    commentsCount={statistic.comments_count}
                    getAction={() => getAudioComments(type, trackId, commentsCursor)}
                    deleteAction={(id, indexes) => deleteCommentAction(id, indexes, type)}
                />
            </div>
        </DialogMUI>
    </div>
}

export const CommentsComponent = ({ comments = [], commentsCount = 0, getAction, deleteAction }) => {
    const dispatch = useDispatch()
    const [expandedComments, setExpandedComments] = useState([])
    const [contextMenu, setContextMenu] = useState(false)

    useEffect(() => {
        dispatch(getAction())
    }, [])

    const contextMenuActions = (action, id) => {
        if (action === 'close') setContextMenu(prev => prev === id ? false : prev)
        else if ('open') setContextMenu(prev => prev === id ? false : id)
    }

    const deleteComment = (id, indexes, parent_id = null) => {
        dispatch(deleteAction(id, indexes)).then(res => {
            if (res.type.includes('_SUCCESS') || res.status.includes('_SUCCESS')) {
                //if (!!parent_id) toggleExpand(parent_id)
                contextMenuActions('close', id)
            }
        })
    }

    const toggleExpand = (id) => {
        let set = new Set(expandedComments)

        if (set.has(id)) set.delete(id)
        else set.add(id)

        setExpandedComments([...set])
    }

    const renderComments = (list, depth = 0, indexes = []) => list.map((el, idx) => <Fragment key={el.id}>
        <div
            className={styles.commentsList__item}
            style={{ width: `calc(100% - ((12px + 36px) * ${depth}))` }}
            onMouseLeave={() => contextMenuActions('close', el.id)}
        >
            <img src={el.author.avatar || profile} alt={el.author.full_name} />
            <div className={styles.commentsList__item_content}>
                <p>{el.author.full_name}<span>{dayjs(el.created_date).utc().format('DD.MM.YYYY HH:mm')}</span></p>
                <p className={styles.text}>{el.text}</p>
                {el.children.length > 0 && <button className={styles.expandTrigger} onClick={() => toggleExpand(el.id)}>
                    {expandedComments.includes(el.id) ? 'Hide replies' : <>View {el.children.length} more replies</>}
                </button>}
            </div>
            <div className={classList(styles.moreActionsWrapper, parentStyles.moreActionsWrapper)}>
                <ClickAwayListener onClickAway={() => contextMenuActions('close', el.id)}>
                    <div className={parentStyles.moreActions}>
                        <button
                            className={classList(parentStyles.moreActions__btn, { [parentStyles.active]: contextMenu === el.id })}
                            onClick={() => contextMenuActions('open', el.id)}
                        >
                            <span />
                            <span />
                            <span />
                        </button>
                        {contextMenu === el.id && <div className={parentStyles.moreActions__container}>
                            <button
                                className={classList(parentStyles.actionButtons, parentStyles.deleteAction)}
                                onClick={() => deleteComment(el.id, [...indexes, idx], el.parent_id)}
                            >
                                <DeleteAction />Delete
                            </button>
                        </div>}
                    </div>
                </ClickAwayListener>
            </div>
        </div>
        {el.children.length > 0 && expandedComments.includes(el.id) && renderComments(el.children, depth + 1, [...indexes, idx])}
    </Fragment>)

    return <>
        <h3>{commentsCount} comment{commentsCount > 1 && 's'}</h3>
        <div className={styles.commentsList}>
            {renderComments(comments || [])}
        </div>
    </>
}

export default StatisticSidebar