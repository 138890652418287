import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { rootMainPath, rootAuthPath, mainPath } from './paths';
import Dashboard from '../features/Dashboard';
import NotFound from '../shared/NotFound';
import { ComingSoon } from '../shared';

const MainRoutes = () => {
  if (!localStorage.token) return <Redirect to={rootAuthPath} />;

  return (
    <Switch>
      <Redirect from={rootMainPath} exact to={mainPath.dashboard.path} />
      {Object.values(mainPath).map(({ name, path, component, comingSoon, isNavItem, exact }, idx) => (
        <Route key={idx} path={path} exact={isNavItem || exact} component={comingSoon ? () => <ComingSoon name={name} /> : component} />
      ))}
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default MainRoutes;
