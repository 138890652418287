import { useDispatch, useSelector } from "react-redux";
import { DialogMUI } from "../../../shared"
import { useEffect } from "react";
import { deleteCommentAction, getPost, getPostComments, setPostModal } from "../storage/usersActions";
import { RenderPost, ImagesSlider } from "./";

import styles from './Feed.module.scss'
import { CommentsComponent } from "../../Audio/StatisticSidebar/StatisticSidebar";

const PostModal = () => {
    const dispatch = useDispatch();
    const { postModalID, postModalImageIndex, postInner, postInnerLoading, postComments } = useSelector(({ users }) => users)
    const commentsCursor = useSelector(({ users }) => (!!users.postComments.next ? users.postComments.next.split('?cursor=')[1] : users.postComments.next))

    useEffect(() => {
        if (!!postModalID) {
            dispatch(getPost(postModalID))
        }
    }, [postModalID])

    const closeModal = () => dispatch(setPostModal())

    const commentsEnd = () => {
        if (!!commentsCursor) dispatch(getPostComments(postModalID, commentsCursor))
    }

    return <DialogMUI
        open={!!postModalID && !postInnerLoading}
        onClose={closeModal}
        contentClass={styles.modalWrapper}
        pepperClass={styles.modalWrapperPepper}
        onScrollEndEvent={commentsEnd}
    >
        <>
            {!!postInner.images && postInner.images.length > 0 && (
                <ImagesSlider images={postInner.images} imageIdx={postModalImageIndex} />
            )}
            <div className={styles.modalWrapperPost}>
                <RenderPost el={postInner} isInner />
                <div className={styles.modalWrapperComments}>
                    <CommentsComponent
                        getAction={() => getPostComments(postModalID)}
                        comments={postComments.results}
                        commentsCount={postInner.comments_count}
                        deleteAction={(id, indexes) => deleteCommentAction(id, indexes)}
                    />
                </div>
            </div>
        </>
    </DialogMUI >
}

export default PostModal;