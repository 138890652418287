export const SUPPORT = {
    GET_CLAIMS: 'GET_CLAIMS',
    GET_CLAIMS_SUCCESS: 'GET_CLAIMS_SUCCESS',
    GET_CLAIMS_FAIL: 'GET_CLAIMS_FAIL',

    GET_FEEDBACKS: 'GET_FEEDBACKS',
    GET_FEEDBACKS_SUCCESS: 'GET_FEEDBACKS_SUCCESS',
    GET_FEEDBACKS_FAIL: 'GET_FEEDBACKS_FAIL',

    GET_PLAYLIST_TRACKS: 'GET_PLAYLIST_TRACKS',
    GET_PLAYLIST_TRACKS_SUCCESS: 'GET_PLAYLIST_TRACKS_SUCCESS',
    GET_PLAYLIST_TRACKS_FAIL: 'GET_PLAYLIST_TRACKS_FAIL',

    SET_CLAIM_STATUS: 'SET_CLAIM_STATUS',
    SET_CLAIM_STATUS_SUCCESS: 'SET_CLAIM_STATUS_SUCCESS',
    SET_CLAIM_STATUS_FAIL: 'SET_CLAIM_STATUS_FAIL',

    CLOSE_PLAYLIST_MODAL: 'CLOSE_PLAYLIST_MODAL'
};
