import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { classList } from '../../helpers/functions'
import styles from './PagesContent.module.scss'

import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg'
import { ReactComponent as TuneIcon } from '../../assets/icons/music.svg'
import { ReactComponent as ResetIcon } from '../../assets/icons/audio-reset.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/audio-wave.svg'
import { ReactComponent as DragNDropIcon } from '../../assets/icons/drag_n_drop.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import noTrackImg from '../../assets/images/nonAvatars/track.svg'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonMUI } from '../../shared';
import { AddTracksModal, ClearModal, CopyModal } from './Modals'
import { getAvailablePacks, getAvailableTracks, reorderTracks, setTracks } from './storage/pagesContentActions'
import { useParams } from 'react-router-dom'

const TrackList = ({ selectedTracks, setSelectedTracks }) => {
    const dispatch = useDispatch()
    const { pageType, pageID, pagePeriod } = useParams()
    const { innerContent } = useSelector(({ pagesContent }) => pagesContent)
    const { tracks = [], auto_list = true } = innerContent
    const [addModal, setAddModal] = useState(false)
    const [copyModal, setCopyModal] = useState(false)
    const [clearModal, setClearModal] = useState(false)
    const [btnLoad, setBtnLoad] = useState(false)
    const [copyLoad, setCopyLoad] = useState(false)

    const onDragEnd = result => {
        const { destination, source } = result;
        dispatch(reorderTracks(source?.index, destination?.index))
    }

    const openAddModal = () => {
        setBtnLoad(true)
        dispatch(getAvailableTracks())
            .then(res => {
                if (res.type.includes('_SUCCESS')) {
                    setAddModal(true)
                    setBtnLoad(false)
                }
            })
    }

    const openCopyModal = () => {
        setCopyLoad(true)
        dispatch(getAvailablePacks(pageType, pageID, pagePeriod))
            .then(res => {
                if (res.type.includes('_SUCCESS')) {
                    setCopyModal(true)
                    setCopyLoad(false)
                }
            })
    }

    return (
        <div className={classList('inner-wrap', styles['inner-wrap'], styles.tracksWrapper)}>
            <div className={styles.tracksHeadingWrapper}>
                <p className={styles.tracksHeading}>Tracks <span>{tracks.length}</span></p>
                {!auto_list && <div className={styles.tracksHeadingButtons}>
                    {tracks.length > 0
                        ? <>
                            {tracks.length < 50 && <ButtonMUI
                                variant='text'
                                className={styles.copyBtn}
                                loading={btnLoad}
                                onClick={openAddModal}
                            >
                                <PlusIcon /> Add tracks
                            </ButtonMUI>}
                            <ButtonMUI
                                variant='text'
                                className={styles.copyBtn}
                                onClick={() => setClearModal(true)}
                            >
                                <ResetIcon /> Clear all
                            </ButtonMUI>
                        </>
                        : <ButtonMUI
                            variant='text'
                            className={styles.copyBtn}
                            loading={copyLoad}
                            onClick={openCopyModal}
                        >
                            <CopyIcon /> Duplicate list
                        </ButtonMUI>
                    }
                </div>}
            </div>
            {auto_list
                ? <ListComponent list={tracks} openAddModal={openAddModal} btnLoad={btnLoad} />
                : <DragDropContext onDragEnd={onDragEnd}>
                    <ListComponent list={tracks} openAddModal={openAddModal} btnLoad={btnLoad} />
                </DragDropContext>
            }
            {!auto_list && <>
                <AddTracksModal
                    open={addModal}
                    onClose={setAddModal}
                    selectedTracks={selectedTracks}
                    setSelectedTracks={setSelectedTracks}
                />
                <ClearModal
                    open={clearModal}
                    onClose={() => setClearModal(false)}
                    clear={() => {
                        setSelectedTracks([])
                        dispatch(setTracks([]))
                        setClearModal(false)
                    }}
                />
                <CopyModal
                    open={copyModal}
                    onClose={() => setCopyModal(false)}
                    setSelectedTracks={setSelectedTracks}
                />
            </>}
        </div>
    )
}

const ListComponent = ({ list, openAddModal, btnLoad }) => {
    const { auto_list = true } = useSelector(({ pagesContent }) => pagesContent.innerContent)

    const returnableFunc = (props) => (
        <div
            className={classList(styles.tracksList, { [styles.tracksListEmpty]: !auto_list && list.length < 1 })}
            {...props}
        >
            {!auto_list && list.length < 1 && (<>
                <div className={styles.tracksListEmptyImage}>
                    <TuneIcon />
                </div>
                <div className={styles.tracksListEmptyMessage}>
                    <p>Nothing added yet</p>
                    <span><span>Add tracks</span> here to form<br />an appropriate rating</span>
                </div>
            </>)}
            {list.map((el, idx) => <ListItem item={el} index={idx + 1} key={el.id} />)}
            {props?.provided?.placeholder}
        </div>
    )

    if (auto_list) {
        return returnableFunc()
    } else {
        if (list.length < 1) {
            return <ButtonMUI
                variant='text'
                className={styles.tracksListEmptyWrapper}
                onClick={openAddModal}
                loading={btnLoad}
            >
                {returnableFunc()}
            </ButtonMUI>
        } else {
            return <Droppable droppableId={'0'}>
                {provided => returnableFunc({
                    ref: provided.innerRef,
                    provided: provided,
                    ...provided.droppableProps
                })}
            </Droppable >
        }
    }
}

const ListItem = ({ item, index }) => {
    const dispatch = useDispatch()
    const { auto_list = true } = useSelector(({ pagesContent }) => pagesContent.innerContent)

    const returnableFunc = (props) => (
        <div
            className={styles.tracksItem}
            {...props}
        >
            <div className={styles.tracksItemLeftSide}>
                {!auto_list && <DragNDropIcon style={{ marginRight: '4px' }} />}
                <div className={styles.tracksItemIndex}>{index}</div>
                <img src={item.image || noTrackImg} alt={item.name} />
                <div className={classList(styles.tracksItemInfo, { [styles.manual]: !auto_list })}>
                    <p>{item.name}</p>
                    <span>{item.artists.find(el => el.is_main).name ? item.artists.find(el => el.is_main).name : item.artists.find(el => el.is_main).username}{' • '}{dayjs(item.length).format().slice(14, 19)}</span>
                </div>
            </div>
            <div className={styles.tracksItemRightSide}>
                <div className={styles.tracksItemAlbumName}>{item?.album?.name}</div>
                {!auto_list && <DeleteIcon onClick={() => dispatch(reorderTracks(index, 'delete'))} />}
            </div>
        </div>
    )

    if (auto_list) {
        return returnableFunc()
    } else {
        return <Draggable draggableId={`${item.id}`} index={index}>
            {provided => returnableFunc({
                ref: provided.innerRef,
                ...provided.draggableProps,
                ...provided.dragHandleProps
            })}
        </Draggable >
    }
}

export default TrackList