import React, { useEffect, useState } from 'react';
import styles from './Finances.module.scss';
import { prettyScroll } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import { getPaymentHistory } from './storage/financesActions';
import FinancesTable from './FinancesTable';

const PaymentHistory = () => {
  const [parameters, setParameters] = useState(initialParameters)

  return (
    <main className={`${styles.finances} page-wrap`} ref={prettyScroll}>
      <div className={`${styles.financesInner} container`}>
        <h1>Payment history</h1>
        <div className={`inner-wrap ${styles['inner-wrap']} ${styles.history}`}>
          <FinancesTable
            parameters={parameters}
            setParameters={setParameters}
            initialParameters={initialParameters}
            action={getPaymentHistory}
            type={'historyList'}
          />
        </div>
      </div>
    </main>
  );
};

export const initialParameters = {
  page_size: 10,
  ordering: '-created_date',
  search: '',
}

export default PaymentHistory;
