import { Fragment } from 'react'
import { classList } from '../../helpers/functions'
import styles from './Audio.module.scss'

const DetailsMaper = ({ title, arr = [], type = 'details', children, classes = '' }) => <div className={classList(styles.detailsWrapper, styles[type], classes)}>
    {!!title && <h3>{title}</h3>}
    {type === 'details'
        ? arr.map((el, idx) => <div key={idx} className={classList(styles.detailsWrapper__item)}>
            {Array.isArray(el)
                ? el.map((elem, index) => fieldRender(elem, index))
                : fieldRender(el, idx)}
        </div>)
        : type === 'tracks'
            ? arr
            : type === 'campaigns'
                ? children
                : null
    }
</div>

const fieldRender = ({ title, value, size }, idx) => <div key={idx} className={classList(styles[size])}>
    {!!title && <span>{title}</span>}
    <p>{value
        ? Array.isArray(value)
            ? value.length > 0
                ? value
                : '-'
            : value
        : '-'}</p>
</div>

export default DetailsMaper