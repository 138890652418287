import React from 'react';
import SideBg from '../../layout/SideBg';
import AuthRoutes from '../../routes/AuthRoutes';
import styles from './AuthContainer.module.scss';
import { ReactComponent as Logo } from '../../assets/icons/Logo.svg'

const AuthContainer = () => {
  return (
    <main className={styles.root}>
      <div className={styles.content}>
        <Logo />
        <AuthRoutes />
        <Logo className={styles.hidden} />
      </div>
    </main>
  );
};

export default AuthContainer;
